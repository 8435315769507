import React, { createContext, useContext, useState } from "react";
import "../css/Accordion.css";
import { useToggle } from "../hooks/useToggle";

// Create context api
const AccordionContext = createContext();
const { Provider } = AccordionContext;

const Accordion = (props) => {
  const { title, content } = props;
  const { status: expand, toggleStatus: toggleExpand } = useToggle();

  const value = {
    expand,
    toggleExpand,
  };

  return (
    <Provider value={value}>
      <div className="accordion">
        <AccordionHeader>{title}</AccordionHeader>
        <AccordionContent>{content}</AccordionContent>
      </div>
    </Provider>
  );
};

// Header
const AccordionHeader = ({ children }) => {
  const { expand, toggleExpand } = useContext(AccordionContext);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleButtonClick = () => {
    toggleExpand();
    setIsButtonActive(!isButtonActive);
  };

  return (
    <button
      className={`accordionButton ${isButtonActive ? "active" : ""}`}
      onClick={handleButtonClick}
    >
      {children}
      <AccordionIcon />
    </button>
  );
};

// Content
const AccordionContent = ({ children }) => {
  const { expand } = useContext(AccordionContext);

  return (
    <div className={`content ${expand ? "active" : ""}`}>
      {expand && children}
    </div>
  );
};

// Icon
const AccordionIcon = () => {
  const { expand } = useContext(AccordionContext);
  return <span className={`accordionIcon ${expand ? "rotate" : ""}`}>+</span>;
};

export default Accordion;
