import React from "react";
import { Link } from "react-router-dom";
import "../css/Contact.css";
import { useScrollReveal } from "../hooks/useScrollReveal";

function Contact() {
  useScrollReveal({ selector: "#contact" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="contact">
      <div className="container">
        <header>
          <h1 className="heading">Kontakt</h1>
        </header>
        <section className="contactDetails">
          <div className="wrapper">
            <div className="wrapperCol textDoubleMargin whiteBgc">
              <h2>Zadzwoń do nas</h2>
              <p>Z telefonów w Polsce i&nbsp;za&nbsp;granicą:</p>
              <a
                className="phoneBig gold"
                href="tel:+48799 655 999"
                data-external-url="1"
              >
                799 655 999
              </a>
              <p>Infolinia czynna:</p>
              <p>od poniedziałku do piątku, w&nbsp;godz. 09:00-17:00</p>
              <p>Opłata za połączenie według taryfy operatora.</p>
              <p>Infolinia w języku polskim.</p>
            </div>
            <div className="wrapperCol textDoubleMargin whiteBgc">
              <h2>Napisz do nas</h2>
              <p>Masz pytanie? Potrzebujesz pomocy?</p>
              <br></br>
              <div className="buttonContainer">
                <Link to="/kontakt/formularz-kontaktowy">
                  <button className="btnGold">Wypełnij formularz</button>
                </Link>
              </div>
              <p>
                Jeżeli chcesz skontaktować się z&nbsp;nami mailowo wyślij
                wiadomość na adres:
              </p>
              <br></br>
              <a className="gold" href="mailto:infoserwis@gnbbank.pl">
                infoserwis@gnbbank.pl
              </a>
            </div>
          </div>
        </section>
        <section>
          <div className="textDoubleMargin whiteBgc">
            <h2>Zawnioskuj o&nbsp;restrukturyzację zadłużenia</h2>
            <p>
              Jeśli masz problem z&nbsp;terminową spłatą swojego kredytu, możesz
              zwrócić się do nas z&nbsp;wnioskiem o&nbsp;zastosowanie ulgi
              w&nbsp;spłacie. Wspólnie możemy znaleźć rozwiązanie i&nbsp;ustalić
              możliwe warunki spłaty, dostosowane do twojej aktualnej sytuacji
              finansowej.
            </p>
            <div className="buttonContainer">
              <Link to="/restrukturyzacja-zadluzenia">
                <button className="btnGold">Sprawdź szczegóły</button>
              </Link>
            </div>
          </div>
        </section>
      </div>

      <section id="address" className="imageSection">
        <div className="container">
          <div className="imageBackground">
            <div className="headerBg">
              <h2 className="white">Dane teleadresowe</h2>
              <h3 className="white">SWIFT: POLUPLPR</h3>
              <h3 className="white addressHeadquaters">
                Getin Noble Bank S.A. w&nbsp;upadłości
              </h3>
              <h3 className="white">
                Adres korespondencyjny:<br></br>
                ul.&nbsp;Działkowa&nbsp;8
              </h3>
              <h3 className="white">41-506&nbsp;Chorzów</h3>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section id="complaint">
          <div className="textDoubleMargin whiteBgc">
            <h2>Złóż reklamację</h2>
            <div className="text">
              <p>
                Jeśli chcesz złożyć reklamację, możesz to zrobić wypełniając
                formularz online.
              </p>
            </div>

            <Link className="gold" to="/kontakt/zloz-reklamacje">
              Wypełnij formularz
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Contact;
