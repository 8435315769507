import React from "react";
import { Link } from "react-router-dom";
import "../css/InformationBmr.css";
import Accordion from "../components/Accordion";
import { useScrollReveal } from "../hooks/useScrollReveal";

import BmrFaq from "./BmrFaq";

import libor01 from "../doc/bmr_docs/01_FCA-komunikat_FCA_w_zakresie_stosowania_syntetycznego_LIBOR.pdf";
import libor02 from "../doc/bmr_docs/02_FCA-komunikat_FCA_w_zakresie_metodologii_obliczania_syntetycznego_LIBOR.pdf";
import libor03 from "../doc/bmr_docs/03_komunikat-KNF-w-zakresie-zaprzestania-opracowywania-wybranych-wskaźników-referencyjnych-z-rodziny-LIBOR.pdf";
import libor04 from "../doc/bmr_docs/04_FCA-komunikat_FCA_w_zakresie_syntetycznego_LIBOR.pdf";
import libor05 from "../doc/bmr_docs/05_dokument-konsultacyjny-FCA-w-zakresie-metodologii-syntetycznego-LIBOR.pdf";
import libor06 from "../doc/bmr_docs/06_dokument-konsultacyjny-BoJ-w-zakresie-kontaktów-legacy-odwołujących-się-do-LIBOR-JPY.pdf";

function InformationBmr() {
  const liborIndicator = (
    <>
      <div className="text">
        <p>
          Przypominamy, że w&nbsp;dniu{" "}
          <span className="bold">5 marca 2021 r.</span> brytyjski organ nadzoru
          Financial Conduct Authority (dalej: „FCA”) ogłosił, że z&nbsp;dniem{" "}
          <span className="bold">31 grudnia 2021 r LIBOR JPY 3M</span> –
          wskaźnik stosowany w&nbsp;umowach –utraci reprezentatywność dla
          właściwego rynku bazowego, a&nbsp;co za tym idzie przestanie on
          obowiązywać w&nbsp;obecnej formie.
        </p>
        <p>
          Utrata reprezentatywności przez{" "}
          <span className="bold">LIBOR JPY 3M</span> spowodowała, że{" "}
          <span className="bold">FCA</span> skorzystała z&nbsp;uprawnień
          nadzorczych względem <span className="bold">IBA</span>, na skutek
          czego <span className="bold">IBA</span> zmieniła metodę opracowywania{" "}
          <span className="bold">LIBOR JPY 3M</span> .
        </p>
        <p>
          W&nbsp;rezultacie od 1 stycznia 2022 r.{" "}
          <span className="bold">LIBOR JPY 3M</span> będzie publikowany&nbsp;
          zgodnie z&nbsp;nową, zmodyfikowaną metodą, która jest określana jako
          metoda „syntetyczna”.
        </p>
      </div>

      <div className="text">
        <h3>
          Jaki to ma wpływ na umowy zawierające odniesienie do LIBOR JPY 3M po
          31 grudnia 2021&nbsp;r.?
        </h3>

        <p>
          Począwszy od <span className="bold">1 stycznia 2022 roku</span> do
          określenia oprocentowania &nbsp;w umowach kredytu odnoszących się do
          LIBOR&nbsp; JPY 3M będzie stosowany wskaźnik{" "}
          <span className="bold">syntetyczny</span>{" "}
          <span className="bold">LIBOR JPY 3M</span>.
        </p>
        <p>
          Wskaźnik syntetyczny zastąpi wszystkie odniesienia do{" "}
          <span className="bold">LIBOR JPY 3M</span> stosowanego &nbsp;w umowach
          kredytu w&nbsp;dotychczasowej formie. Będzie on stosowany tak długo,
          jak długo będzie publikowany,{" "}
          <span className="bold">czyli co najmniej do końca 2022 r. </span>
          Na dzień sporządzenia tej informacji nie jest przesądzone, czy FCA
          zdecyduje się na wydłużenie obowiązku publikacji syntetycznego LIBOR
          JPY 3M po <span className="bold">31 grudnia 2022</span>&nbsp;
          <span className="bold">r.</span>
        </p>
      </div>

      <div className="text">
        <h3>Dlaczego stosujemy syntetyczny wskaźnik LIBOR JPY?</h3>

        <p>
          Zgodnie z&nbsp;komunikatem <span className="bold">FCA</span>,
          zastosowanie syntetycznego wskaźnika{" "}
          <span className="bold">LIBOR JPY</span> jest możliwe również
          w&nbsp;umowach z&nbsp;konsumentami i&nbsp;pozwoli na niezakłócone
          umów, co najmniej do końca 2022 r.
          <p>
            <span className="bold">
              Dlaczego stosujemy syntetyczny wskaźnik LIBOR JPY?
            </span>
          </p>
        </p>
        <p>
          Syntetyczny wskaźnik LIBOR JPY{" "}
          <span className="bold">nie jest nowym wskaźnikiem</span>, tylko jego
          syntetyczną formą opracowaną według nowej metody opracowanej przez{" "}
          <span className="bold">IBA</span>.
        </p>
        <p>
          <span className="bold">
            Dlatego od 1 stycznia 2022 r. syntetyczny wskaźnik LIBOR JPY 3M
            będzie mógł być stosowany w&nbsp;umowach, w&nbsp;których do tej pory
            funkcjonował LIBOR JPY 3M, bez konieczności zawierania aneksu do
            umowy.
          </span>
        </p>
        <p>
          Zgodnie ze komunikatem FCA, stosowanie syntetycznego LIBOR JPY 3M
          pozwoli na płynne i&nbsp;uporządkowane odejście od wskaźnika LIBOR JPY
          3M bez ryzyka destabilizacji rynku.<br></br>
          <a
            href="https://ec.europa.eu/info/law/better-regulation/have-your-say/initiatives/13318-LIBOR-dla-jena-japonskiego-wyznaczenie-ustawowego-zamiennika_pl"
            className="gold"
          >
            Informacja o&nbsp;pracach KE w&nbsp;zakresie wyznaczenia z&nbsp;mocy
            prawa wskaźnika alternatywnego dla LIBOR JPY
          </a>
        </p>
      </div>

      <p>
        Więcej informacji &nbsp;o &nbsp;możliwości stosowania syntetycznego
        LIBOR JPY oraz metodologii jego obliczania (komunikaty i&nbsp;stanowiska
        FCA) znajduje się poniżej:
      </p>
      <ul>
        <li>
          <a href={libor01} className="gold">
            Komunikat FCA w&nbsp;zakresie stosowania syntetycznego LIBOR
            (16/11/2021)
          </a>
        </li>
        <li>
          <a href={libor02} className="gold">
            Komunikat FCA w&nbsp;zakresie metodologii obliczania syntetycznego
            LIBOR (29/09/2021)
          </a>
        </li>
        <li>
          <a href={libor03} className="gold">
            Komunikat KNF w&nbsp;zakresie zaprzestania opracowywania wybranych
            wskaźników referencyjnych z&nbsp;rodziny LIBOR (26/11/2021)
          </a>
        </li>
        <li>
          <a href={libor04} className="gold">
            Komunikat FCA w&nbsp;zakresie syntetycznego LIBOR (24/06/2021)
          </a>
        </li>
        <li>
          <a href={libor05} className="gold">
            Dokument konsultacyjny FCA w&nbsp;zakresie metodologii syntetycznego
            LIBOR (06/2021)
          </a>
        </li>
        <li>
          <a href={libor06} className="gold">
            Dokument konsultacyjny BoJ w&nbsp;zakresie kontaktów legacy
            odwołujących się do LIBOR JPY (09/2021)
          </a>
        </li>
      </ul>
    </>
  );

  const EUordinance = (
    <>
      <div className="text">
        <ul className="dottedList">
          <li>
            22.10.2021 r. w&nbsp;Dzienniku Urzędowym Unii Europejskiej
            zostało&nbsp;
            <span className="bold">
              opublikowane Rozporządzenie Wykonawcze Komisji (UE) 2021/1847
              z&nbsp;dnia 14&nbsp;października 2021&nbsp;r. w&nbsp;sprawie
              wyznaczenia ustawowego zamiennika dla niektórych terminów
              zapadalności stopy LIBOR dla franka szwajcarskiego (CHF LIBOR
            </span>
            ),&nbsp;dotyczące wyznaczenia zamienników dla stopy LIBOR dla franka
            szwajcarskiego (CHF LIBOR) (dalej: „Rozporządzenie wykonawcze KE”).
          </li>
          <li>
            Treść Rozporządzenia, również w&nbsp;polskiej wersji językowej,
            dostępna jest pod linkiem:{" "}
            <a
              href="https://eur-lex.europa.eu/legal-content/PL/TXT/HTML/?uri=CELEX:32021R1847&amp;qid=1634886827968&amp;from=PL"
              className="gold"
            >
              L_2021374PL.01000101.xml (europa.eu)
            </a>
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>
          Jakie zmiany wprowadza Rozporządzenie wykonawcze KE i&nbsp;co one
          oznaczają dla klientów i&nbsp;banku?
        </h3>
        <ul className="dottedList">
          <li>
            Na mocy Rozporządzenia wykonawczego KE&nbsp;wskaźniki LIBOR dla
            franka szwajcarskiego (LIBOR CHF) zostaną zastąpione &nbsp;stopą
            składaną SARON (odpowiednią dla stosowanych w&nbsp;umowie terminów
            wskaźnika LIBOR CHF – tabela poniżej).
          </li>
          <li>
            Zamiana LIBOR&nbsp;CHF&nbsp;na&nbsp;stopę składaną&nbsp;SARON
            nastąpi&nbsp;z mocy prawa. Będzie miała zastosowanie do wszystkich
            odniesień (terminów) do dotychczasowego wskaźnika referencyjnego
            LIBOR CHF stosowanego w&nbsp;umowach i&nbsp;instrumentach
            finansowych na terenie UE, które nie zawierają klauzul awaryjnych,
            a&nbsp;jeżeli zawierają klauzule tego rodzaju, to klauzule te nie są
            odpowiednie.
          </li>
          <li>
            Wprowadzenie zamiennika dla LIBOR&nbsp;CHF&nbsp; oznacza również, że
            umowy o&nbsp;kredyt hipoteczny indeksowany do CHF mogą być nadal
            wykonywane, zatem gwarantuje on pewność prawną zarówno dla
            posiadaczy kredytów hipotecznych, jak i&nbsp;dla banku.
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>Od kiedy będzie stosowane Rozporządzenie wykonawcze KE?</h3>
        <ul className="dottedList">
          <li>
            Rozporządzenie wchodzi w&nbsp;życie&nbsp; 11 listopada 2021 r.,
            natomiast&nbsp;będzie stosowane &nbsp;od dnia 1&nbsp;stycznia 2022
            r.
          </li>
        </ul>
        <h3>
          Czy zamiana wskaźnika LIBOR CHF na stopę składaną SARON będzie się
          &nbsp;wiązała &nbsp;z kosztami dla klientów lub koniecznością podjęcia
          dodatkowych czynności?
        </h3>
        <ul className="dottedList">
          <li>
            Zamiana LIBOR&nbsp;CHF&nbsp;na&nbsp;stopę składaną&nbsp;SARON
            nie&nbsp;będzie się wiązała z&nbsp;ponoszeniem kosztów przez
            klientów
          </li>
          <li>
            Sama zmiana LIBOR CHF na stopę składaną SARON nie będzie też
            wymagała od klientów podejmowana działań w&nbsp;tym zakresie.
          </li>
          <li>
            Warto jednak pamiętać, że rozporządzenie BMR&nbsp;nakłada na banki
            szereg innych wymogów, których realizacja może wymagać dodatkowych
            interakcji z&nbsp;klientami. &nbsp; &nbsp;
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>Jak zmieni się oprocentowanie kredytów w&nbsp;Twojej umowie?</h3>
        <ul className="dottedList">
          <li>
            Między dotychczas stosowanym &nbsp;wskaźnikiem &nbsp;LIBOR CHF
            a&nbsp;stopą składaną SARON istnieje różnica w&nbsp;wartości.
          </li>
          <li>
            Nie mamy możliwości określenia, jakie wartości przyjmie
            w&nbsp;przyszłości stopa składana SARON, a&nbsp;co za tym idzie jaki
            będzie kierunek oraz skala wpływu na wysokość oprocentowania
            kredytów.
          </li>
          <li>
            Aby uwzględnić tę różnicę i&nbsp;ograniczyć do minimum ekonomiczne
            skutki wprowadzenia zamiennika,&nbsp; zgodnie z&nbsp;Rozporządzeniem
            wykonawczym KE, do stopy składanej SARON będzie dodany stały spread
            korygujący w&nbsp;odniesieniu do każdego z&nbsp;zastępowanych przez
            &nbsp;nią terminów wskaźnika LIBOR CHF.
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>Informacje o&nbsp;wskaźniku SARON</h3>
        <ul className="dotted">
          <li>
            Grupa robocza przy Szwajcarskim Banku Narodowym zarekomendowała
            stopę SARON (Swiss Average Rate Overnight) jako wskaźnik
            alternatywny dla LIBOR CHF oraz jako wskaźnik wolny od ryzyka dla
            wszystkich produktów i&nbsp;kontraktów we franku szwajcarskim.&nbsp;
          </li>
          <li>
            SARON &nbsp;jest wskaźnikiem referencyjnym stopy procentowej dla
            franka szwajcarskiego.
          </li>
          <li>
            Administratorem wskaźnika referencyjnego stopy procentowej SARON
            oraz SARON Compound Rate (stopa składana SARON) jest SIX Financial
            Information AG z&nbsp;siedzibą w&nbsp;Zurichu.
          </li>
          <li>
            Dane kontaktowe znajdują się na stronie internetowej:&nbsp;
            <a href="http://www.six-group.com" className="gold">
              www.six-group.com
            </a>
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>
          Gdzie możesz znaleźć&nbsp; dodatkowe informacje o&nbsp;Rozporządzeniu
          wykonawczym KE?
        </h3>
        <ul className="dottedList">
          <li>
            Informacje, w&nbsp;postaci pytań i&nbsp;odpowiedzi &nbsp;dotyczących
            Rozporządzenia wykonawczego KE przygotowała na swoich stronach
            również Komisja Nadzoru Finansowego.
          </li>
          <li>
            Są one dostępne pod linkiem:&nbsp;
            <a
              href="https://www.knf.gov.pl/knf/pl/komponenty/img/QA_dot_zamiennika_LIBOR_CHF.pdf"
              className="gold"
            >
              https://www.knf.gov.pl/knf/pl/komponenty/img/QA_dot_zamiennika_LIBOR_CHF.pdf
            </a>
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>
          Zasady zamiany poszczególnych terminów wskaźnika LIBOR CHF na stopę
          składaną SARON
        </h3>
        <ul className="dottedList">
          <li>
            Zasady zamiany poszczególnych terminów LIBOR CHF na stopę składaną
            SARON zostały opisane w&nbsp;Rozporządzeniu wykonawczym KE.
          </li>
        </ul>
        <p>
          Zamienniki dla stopy CHF LIBOR wyznacza się zgodnie z&nbsp;poniższą
          tabelą:
        </p>
      </div>

      <div id="EUordinanceTable">
        <div className="gridParent">
          <div className="i1">LIBOR</div>
          <div className="i2">
            TERMIN ZAPADALNOŚCI<br></br>(w miesiącach, M)
          </div>
          <div className="i3">Zamiennik</div>
          <div className="i4">Wartość spreadu korygującego (%)</div>
        </div>

        <div className="gridParent">
          <div className="i5">CHF</div>
          <div className="i6">1 M</div>
          <div className="i7">
            1-miesięczna stopa składana<br></br>
            SARON<br></br>
            (SAR1MC)<br></br>
            ISIN CH0477123886
          </div>
          <div className="i8">-0,0571</div>
        </div>

        <div className="gridParent">
          <div className="i9">CHF</div>
          <div className="i10">3 M</div>
          <div className="i11">
            3-miesięczna stopa składana<br></br>
            SARON<br></br>
            (SAR3MC)<br></br>
            ISIN CH0477123902
          </div>
          <div className="i12">0,0031</div>
        </div>

        <div className="gridParent">
          <div className="i13">CHF</div>
          <div className="i14">6 M</div>
          <div className="i15">
            3-miesięczna stopa składana<br></br>
            SARON<br></br>
            (SAR3MC)<br></br>
            ISIN CH0477123902
          </div>
          <div className="i16">0,0741</div>
        </div>

        <div className="gridParent">
          <div className="i17">CHF</div>
          <div className="i18">12 M</div>
          <div className="i19">
            3-miesięczna stopa składana<br></br>
            SARON<br></br>
            (SAR3MC)<br></br>
            ISIN CH0477123902
          </div>
          <div className="i20">0,2048</div>
        </div>
      </div>
    </>
  );

  const remaining = (
    <>
      <div className="text">
        <ul>
          <li className="bottomLine">
            3 sierpnia 2021 r. Komisja Europejska opublikowała projekt
            rozporządzenia wykonawczego wyznaczającego zamiennik dla LIBOR CHF.
            Projekt rozporządzenia przewiduje wyznaczenie zamienników dla LIBOR
            CHF z&nbsp;terminami 1M, 3M, 6M oraz 12M.{" "}
            <a
              href="../../../../storage/file/core_files/2021/8/11/a851b91b84d2a56bcc4fab27cae37c0a/090166e5e0814f75.pdf"
              className="gold"
            >
              Dokument w&nbsp;wersji anglojęzycznej{" "}
            </a>
          </li>
          <li className="bottomLine">
            Komisja Europejska uruchomiła również publiczne konsultacje
            dotyczące projektu. Konsultacje trwają od 3 do 31 sierpnia 2021
            roku. Strona konsultacji na której znajdziesz zasady uczestnictwa
            w&nbsp;konsultacjach dostępna jest pod linkiem:{" "}
            <a
              href="https://ec.europa.eu/info/law/better-regulation/have-your-say/initiatives/13177-Designation-of-a-statutory-replacement-rate-for-CHF-LIBOR-Benchmarks-_pl"
              className="gold"
            >
              https://ec.europa.eu/info/law/better-regulation/have-your-say/initiatives/13177-Designation-of-a-statutory-replacement-rate-for-CHF-LIBOR-Benchmarks-_pl
            </a>
          </li>
          <li className="bottomLine">
            18 maja 2021 r. Komisja Europejska zakończyła konsultacje publiczne
            dotyczące wyznaczenia wskaźnika zamiennego dla LIBOR CHF.
            Podsumowanie z&nbsp;konsultacji w&nbsp;języku angielskim jest
            dostępne pod linkiem:{" "}
            <a
              href="https://ec.europa.eu/info/consultations/finance-2021-chf-libor-rate_en"
              className="gold"
            >
              https://ec.europa.eu/info/consultations/finance-2021-chf-libor-rate_en
            </a>
          </li>
          <li className="bottomLine">
            5 marca 2021 roku brytyjski organ nadzoru Financial Conduct
            Authority (FCA) ogłosił stanowisko o&nbsp;całkowitym zaprzestaniu
            opracowywania wskaźników referencyjnych z&nbsp;rodziny LIBOR:
          </li>
        </ul>
        <div id="remaningTable">
          <div className="gridParent">
            <div className="bold">Nazwa wskaźnika referencyjnego</div>
            <div className="bold">Termin</div>
            <div className="bold">Data zaprzestania opracowywania</div>
          </div>

          <div className="gridParent">
            <div>LIBOR CHF</div>
            <div>O/N, 1W, 1M, 2M, 3M, 6M, 12M</div>
            <div>31.12.2021 r.</div>
          </div>

          <div className="gridParent">
            <div>LIBOR EUR</div>
            <div>O/N, 1W, 1M, 2M, 3M, 6M, 12M</div>
            <div>31.12.2021 r.</div>
          </div>

          <div className="gridParent">
            <div>LIBOR GBP</div>
            <div>O/N, 1W, 2M, 12M</div>
            <div>31.12.2021 r.</div>
          </div>

          <div className="gridParent">
            <div>LIBOR JPY</div>
            <div>S/N, 1W, 2M, 12M</div>
            <div>31.12.2021 r.</div>
          </div>

          <div className="gridParent">
            <div>LIBOR USD</div>
            <div>1W, 2M</div>
            <div>31.12.2021 r.</div>
          </div>

          <div className="gridParent">
            <div>LIBOR USD</div>
            <div>O/N, 12M</div>
            <div>30.06.2023 r.</div>
          </div>
        </div>
      </div>

      <div className="text">
        <p>Zapraszamy do zapoznania się z&nbsp;komunikatem:</p>
        <ul className="dottedList">
          <li>
            <a
              href="https://www.getinbank.pl/storage/file/core_files/2021/4/26/db03989036726b8b43e7336486f94a8c/KOMUNIKAT_dla_klientów_GNB_zaprzestanie_LIBOR_12.04.21-C.pdf"
              className="gold"
            >
              komunikat Getin Noble Banku o&nbsp;LIBOR CHF
            </a>
          </li>
          <li>
            <a
              href="https://www.getinbank.pl/storage/file/core_files/2021/6/2/5703b12b16ae21072287fef4802c3856/KOMUNIKAT_dla_klientów_GNB_zaprzestanie_LIBOR-USD_.pdf"
              className="gold"
            >
              komunikat Getin Noble Banku o&nbsp;LIBOR USD
            </a>
          </li>
          <li>
            <a
              href="https://www.getinbank.pl/storage/file/core_files/2021/8/4/9d19f9f5ac0804686bda23d2eba5b19b/Komunikat-Getin-Noble-Bank-o-LIBOR-USD-1M.pdf"
              className="gold"
            >
              komunikat Getin Noble Bank o&nbsp;LIBOR USD 1M
            </a>
          </li>
          <li>
            <a
              href="https://www.getinbank.pl/storage/file/core_files/2021/6/2/eb101b2daad45247b7b7965adfff8601/KOMUNIKAT_dla_klientów_GNB_zaprzestanie_LIBOR-JPY_.pdf"
              className="gold"
            >
              komunikat Getin Noble Banku o&nbsp;LIBOR JPY
            </a>
          </li>
          <li>
            <a
              href="https://www.knf.gov.pl/komunikacja/komunikaty?articleId=72821&amp;p_id=18#1"
              className="gold"
            >
              komunikat Komisji Nadzoru Finansowego
            </a>
          </li>
          <li>
            <a
              href="https://www.fca.org.uk/publication/documents/future-cessation-loss-representativeness-libor-benchmarks.pdf"
              className="gold"
            >
              komunikat FCA (wersja angielska)
            </a>
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>
          Co oznacza zakończenie publikacji wskaźników referencyjnych
          z&nbsp;rodziny LIBOR?
        </h3>
        <p>
          Po zakończeniu publikacji wskaźniki z&nbsp;rodziny LIBOR nie będą
          mogły być dłużej wykorzystywane m.in. jako wskaźniki referencyjne do
          określenia wysokości oprocentowania i&nbsp;kwoty przypadającej do
          zapłaty wynikającej z&nbsp;umowy kredytu, pożyczki lub z&nbsp;tytułu
          innych instrumentów finansowych.
        </p>

        <div className="border rounded">
          <p>
            Komisja Europejska może wyznaczyć wskaźniki zastępcze, które będą
            zastępować dotychczasowe wskaźniki referencyjne. Komisja Europejska
            23 marca 2021 r. rozpoczęła konsultacje w&nbsp;sprawie wskaźnika
            zastępczego dla LIBOR CHF.
          </p>
        </div>
      </div>

      <div className="text">
        <h3>Co dalej?</h3>
        <p>
          W&nbsp;banku na bieżąco monitorujemy działania regulacyjne, które
          dotyczą&nbsp; sytuacji na rynku wskaźników referencyjnych, które są
          podejmowane przez właściwe organy krajowe, europejskie
          i&nbsp;pozaunijne. Dalsze informacje&nbsp; będziemy publikować na
          naszej stronie internetowej.
        </p>
      </div>
    </>
  );

  const whatAreIndicators = (
    <>
      <div className="text">
        <p>
          <span className="bold underline">Wskaźniki referencyjne</span> to
          wskaźniki stopy procentowej używane na rynkach finansowych.
        </p>
        <p>
          Wskaźnikami referencyjnymi są m.in.:{" "}
          <span className="bold">WIBOR</span>,{" "}
          <span className="bold">EURIBOR</span>,{" "}
          <span className="bold">LIBOR</span>.
        </p>
        <p>
          Na podstawie wskaźników referencyjnych banki mogą wyznaczać
          oprocentowanie kredytów, pożyczek, lokat, produktów strukturyzowanych,
          papierów dłużnych i&nbsp;innych instrumentów finansowych.
        </p>
        <p>
          Indeks przy nazwie wskaźnika np.: 1M, 3M, 6M, 1Y oznacza termin,
          którego dotyczy wskaźnik referencyjny (tzn. tenor), czyli 1M – jeden
          miesiąc, 3M – trzy miesiące, 6M – sześć miesięcy, 1Y – jeden rok.
        </p>
        <p>Wskaźniki opracowują niezależni administratorzy, którzy:</p>
        <ul className="dottedList">
          <li>
            określają i&nbsp;zarządzają mechanizmami wyznaczania wskaźnika,
          </li>
          <li>
            gromadzą, analizują i&nbsp;przetwarzają dane niezbędne do wyliczenia
            wskaźnika,
          </li>
          <li>
            wyznaczają wskaźnik (zgodnie z&nbsp;przyjętą przez siebie metodą
            i&nbsp;w&nbsp;oparciu o&nbsp;zebrane dane).
          </li>
        </ul>
      </div>

      <div className="text">
        <h3>Co to jest rozporządzenie BMR?</h3>
        <h3>WIBOR</h3>
        <p>
          stopa procentowa (wskaźnik referencyjny) stosowana do określenia
          oprocentowania m.in. kredytów lub pożyczek udzielonych w&nbsp;polskich
          złotych. Ustalana jest przez administratora - GPW Benchmark S.A.
          z&nbsp;siedzibą w&nbsp;Warszawie. Szczegóły dotyczące WIBOR
          i&nbsp;jego administratora&nbsp; znajdziesz na stronie:{" "}
          <a href="https://gpwbenchmark.pl" className="gold">
            https://gpwbenchmark.pl{" "}
          </a>
        </p>
        <h3>EURIBOR</h3>
        <p>
          stopa procentowa (wskaźnik referencyjny) stosowana do określenia
          oprocentowania m.in. kredytów lub pożyczek udzielonych w&nbsp;EURO.
          Wskaźnik jest opracowywany przez the European Money Markets Institute
          (EMMI). Szczegóły dotyczące EURIBOR znajdziesz na stronie:{" "}
          <a href="http://www.emmi-benchmarks.eu" className="gold">
            www.emmi-benchmarks.eu
          </a>
        </p>
        <h3>LIBOR</h3>
        <p>
          stopa procentowa (wskaźnik referencyjny) stosowana do określenia
          oprocentowania m.in. kredytów lub pożyczek udzielonych w&nbsp;walutach
          wymienialnych (np. we frankach szwajcarskich - CHF, w&nbsp;dolarach
          amerykańskich - USD, w&nbsp;jenach japońskich - JPY). <br></br>
          Wskaźnik jest opracowywany przez ICE Benchmark Administration (IBA).
          Szczegóły dotyczące LIBOR znajdziesz na stronie:&nbsp;
          <a href="https://www.theice.com/iba" className="gold">
            www.theice.com/iba
          </a>
        </p>
      </div>

      <div className="border flexbox rounded">
        <div className="square rounded">
          <p>!</p>
        </div>
        <p>
          5 marca 2021 r. brytyjski organ nadzoru Financial Conduct Authority
          (FCA) ogłosił stanowisko o&nbsp;całkowitym zaprzestaniu opracowywania
          określonych wskaźników referencyjnych z&nbsp;rodziny LIBOR.
        </p>
      </div>
    </>
  );

  const whatIsBmrOrdinance = (
    <>
      <div className="text">
        <div className="flexbox">
          <div className="square rounded">
            <p>?</p>
          </div>
          <div>
            <p>
              <h3>Rozporządzenie BMR</h3>
              Rozporządzenie Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/1011
              z&nbsp;dnia 8 czerwca 2016 r. w&nbsp;sprawie indeksów stosowanych
              jako wskaźniki referencyjne w&nbsp;instrumentach finansowych
              i&nbsp;umowach finansowych lub do pomiaru wyników funduszy
              inwestycyjnych i&nbsp;zmieniające dyrektywy 2008/48/WE
              i&nbsp;2014/17/UE oraz rozporządzenie (UE) nr 596/2014 (Dz.U. L
              171 z&nbsp;29.6.2016, s. 1 z&nbsp;późn. zm.).
            </p>
            <p>
              Treść dokumentu znajdziesz{" "}
              <a
                href="https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A02016R1011-20191210"
                className="gold"
              >
                tutaj
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="text">
        <p>
          Rozporządzenie BMR ma sprawić, że wskaźniki referencyjne będą bardziej
          wiarygodne, przejrzyste i&nbsp;rzetelne.
        </p>
        <h3>Rozporządzenie BMR określa:</h3>
        <ul className="dottedList">
          <li>
            Zasady działania i&nbsp;obowiązki:<br></br>
            <span className="bold">• administratorów</span> wskaźników
            referencyjnych <br></br>
            <span className="bold">
              • podmiotów, które przekazują administratorom dane
            </span>{" "}
            niezbędne do wyliczenia wskaźników referencyjnych oraz<br></br>
            <span className="bold">• podmiotów , które stosują</span> wskaźniki
            referencyjne
          </li>
          <li>
            Zasady prowadzenia rejestru administratorów i&nbsp;wskaźników
            referencyjnych prowadzonych przez ESMA<sup>1</sup>
          </li>
          <li>
            Nowy standard wyliczania i&nbsp;stosowania wskaźników
            referencyjnych.
          </li>
        </ul>
      </div>

      <div className="text">
        <div className="flexbox">
          <div className="square rounded">
            <p>?</p>
          </div>
          <div>
            <h3>EMSA</h3>
            <p>
              Europejski Urząd Nadzoru Giełd i&nbsp;Papierów Wartościowych
              (European Securities and Markets Authority)<br></br>
              <a href="https://www.esma.europa.eu/" className="gold">
                https://www.esma.europa.eu/
              </a>
            </p>
          </div>
        </div>
        <p>
          <span className="bold">Bank</span> jest podmiotem, który stosuje
          wskaźniki&nbsp;referencyjne.
        </p>
      </div>

      <div className="text">
        <h3>Co jeszcze warto wiedzieć o&nbsp;rozporządzeniu BMR?</h3>
        <p>
          <span className="bold underline">O&nbsp;czym należy pamiętać</span>
        </p>
        <ul className="dottedList">
          <li>
            Wartość wskaźników referencyjnych zmienia się w&nbsp;trakcie trwania
            umowy. Oznacza to np. że wysokość oprocentowania kredytu opartego
            o&nbsp;wskaźnik referencyjny zmienia się w&nbsp;zależności od jego
            aktualnej wysokości. W&nbsp;rezultacie zmiana wpływa na
            oprocentowanie kredytu i&nbsp;jego koszt, który może ulec zarówno
            obniżeniu jak i&nbsp;podwyższeniu.
          </li>
          <li>
            Organ nadzoru ma możliwość cofnięcia lub zawieszenia
            administratorowi zezwolenia na administrowanie danym wskaźnikiem, co
            może mieć wpływ na zasady&nbsp; stosowania wskaźnika.
          </li>
          <li>
            Administrator wskaźnika może również&nbsp; zmienić metodę&nbsp; jego
            wyznaczania wskaźnika lub&nbsp; podjąć decyzję o&nbsp;zaprzestaniu
            opracowywania i&nbsp;dalszej publikacji wskaźnika.
          </li>
        </ul>

        <div className="border rounded">
          <p>
            W&nbsp;związku z&nbsp;tym, ze stosowaniem wskaźnika referencyjnego
            wiążą się w&nbsp;szczególności następujące ryzyka:
          </p>
          <ul className="dottedList">
            <li>Ryzyko stopy procentowej</li>
            <li>Ryzyko istotnej zmiany wskaźnika referencyjnego</li>
            <li>
              Ryzyko czasowego lub trwałego zaprzestania opracowywania wskaźnika
              referencyjnego
            </li>
          </ul>
        </div>
      </div>
    </>
  );

  useScrollReveal({ selector: "#informationBmr" });
  useScrollReveal({
    selector: ".container, .imageBackground",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="informationBmr">
      <section className="imageSection">
        <div className="container">
          <div className="imageBackground">
            {" "}
            <header className="headerBg">
              <h1 className="heading">
                Informacje o&nbsp;wskaźnikach referencyjnych dotyczących stóp
                procentowych (BMR)
              </h1>
            </header>
          </div>
        </div>
      </section>

      <section id="indicatorsNews">
        <div className="container">
          <h2>Wskaźniki referencyjne – aktualności</h2>
          <ul className="accordionBox">
            <Accordion
              title="Informacje o wskaźniku LIBOR JPY"
              content={liborIndicator}
            />
            <Accordion
              title="Rozporządzenie wykonawcze Komisji Europejskiej"
              content={EUordinance}
            />
            <Accordion title="Pozostałe" content={remaining} />
          </ul>
        </div>
      </section>

      <section id="indicatorsNews">
        <div className="container">
          <h2>Wskaźniki referencyjne – najważniejsze informacje</h2>
          <ul className="accordionBox">
            <Accordion
              title="Czym są wskaźniki referencyjne dotyczące stóp procentowych?"
              content={whatAreIndicators}
            />
            <Accordion
              title="Co to jest rozporządzenie BMR?"
              content={whatIsBmrOrdinance}
            />
          </ul>
        </div>
      </section>

      <section id="bmrFaq">
        <div className="container">
          <h3>Najczęściej zadawane pytania i&nbsp;odpowiedzi do aneksów BMR</h3>
          <div className="buttonContainer">
            <Link to="/informacje-o-wskaznikach-referencyjnych/pytania-i-odpowiedzi-do-aneksow-bmr">
              <button className="btnGold">Sprawdź</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default InformationBmr;
