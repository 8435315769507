import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/ExchangeRates.css";
import { useScrollReveal } from "../hooks/useScrollReveal";

const ExchangeRates = () => {
  const [data, setData] = useState([]);
  const [idDate, setIdDate] = useState("dp1708623607077");

  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://gnbbank.pl/scrape");
        // const response = await axios.get(`${process.env.API_URL}/scrape`);
        setData(response.data.data);
        setIdDate(response.data.id);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const groupedData = [];
  for (let i = 0; i < data.length; i += 4) {
    groupedData.push(data.slice(i, i + 4));
  }

  useScrollReveal({ selector: "#exchangeRates" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="exchangeRates" className="getin">
      <div className="container">
        <h2>Kursy walut Getin Bank</h2>
        <div className="whiteBgc">
          <p>
            Poniższa tabela przedstawia kursy walut na dzień obecny ({day}.
            {month}.{year}). W&nbsp;celu zapoznania się z wartościami
            historycznymi zapraszamy do odwiedzenia strony:{" "}
            <a className="gold" href="https://www.getinbank.pl/kursy-walut">
              https://www.getinbank.pl/kursy-walut
            </a>{" "}
          </p>
          {/* <p>
            Kursy walut dla kredytów Getin Noble Bank S.A. znajdują się pod
            adresem{" "}
            <a className="gold" href="https://www.getinbank.pl/kursy-walut">
              https://www.getinbank.pl/kursy-walut
            </a>
          </p> */}
          <div className="currencyTab">
            {groupedData.map((group, index) => (
              <ul key={index} className="gridParent">
                {group.map((item, i) => (
                  <li key={i} className={`i${i + 1}`}>
                    {item}
                  </li>
                ))}
              </ul>
            ))}

            {/* {data ? (
              data.map((item, index) => (
                <li key={index} className={`i${index + 1}`}>
                  {item}
                </li>
              ))
            ) : (
              <p>Wczytywanie danych...</p>
            )} */}
          </div>
          <p>
            * Kurs Sprzedaży NBP jest dostępny pod linkiem{" "}
            <a className="gold" href="http://www.nbp.pl/kursy/kursyc.html">
              http://www.nbp.pl/kursy/kursyc.html
            </a>
          </p>
          <p>
            Spread stanowi różnicę pomiędzy kursem kupna i sprzedaży danej
            waluty ogłaszanymi przez Bank w Tabeli Kursowej.
          </p>
        </div>
      </div>
    </main>
  );
};

export default ExchangeRates;
