import React, { useState } from "react";
import "../css/Form.css";
import { Link } from "react-router-dom";
import { useScrollReveal } from "../hooks/useScrollReveal";

const subjects = [
  "Produkty ubezpieczeniowe",
  "Bankowość elektroniczna",
  "Produkty inwestycyjne",
  "Kredyty hipoteczne",
  "Kredyty firmowe",
  "Kredyty samochodowe",
  "Placówki własne",
  "Placówki franczyzowe",
  "Infolinia",
  "Windykacja",
  "Inny",
];

const Form = () => {
  const [form, setForm] = useState({
    subject: "Produkty ubezpieczeniowe",
    firstName: "",
    lastName: "",
    confirmationMethod: "e-mail",
    contactMethod: "e-mail",
    email: "",
    pesel: "",
    attachments: [],
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [showFullText, setShowFullText] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useScrollReveal({ selector: "#complaintForm" });
  useScrollReveal(".submittedMsg");
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  const handleInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setForm({
      ...form,
      attachments: [...e.target.files],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitted(false);

    const formData = new FormData();
    formData.append("subject", form.subject);
    formData.append("firstName", form.firstName);
    formData.append("lastName", form.lastName);
    formData.append("confirmationMethod", form.confirmationMethod);
    formData.append("contactMethod", form.contactMethod);
    formData.append("email", form.email);
    formData.append("pesel", form.pesel);
    formData.append("message", form.message);

    form.attachments.forEach((attachment) => {
      formData.append("attachments", attachment);
    });

    try {
      const response = await fetch("https://gnbbank.pl/send-email", {
        // const response = await fetch("http://localhost:4000/send-email", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        setErrors({
          form:
            errorData.error ||
            "Wystąpił nieznany błąd podczas wysyłania formularza. Spróbuj później.",
        });
      } else {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors({
        form: "Wystąpił nieznany błąd podczas wysyłania formularza. Spróbuj później.",
      });
    }
  };

  if (isSubmitted) {
    return (
      <main className="submittedMsg">
        <div className="container">
          <div className="text">
            <p>Dziękujemy za przesłaną wiadomość.</p>
            <p>
              Odpowiedzi mailowej udzielimy na adres zarejestrowany do kontaktu
              z&nbsp;Bankiem.
            </p>
          </div>
          <Link className="btnGold" to="/">
            Powrót do strony głównej
          </Link>
        </div>
      </main>
    );
  }

  return (
    <main id="complaintForm" className="mainForm">
      <section>
        <div className="container">
          <h1 className="heading">
            Złóż reklamację - klienci Getin Noble Bank S.A. w&nbsp;upadłości
          </h1>
          <form className="formWrapper whiteBgc" onSubmit={handleSubmit}>
            <label>
              Temat formularza
              <select
                name="subject"
                value={form.subject}
                onChange={handleInputChange}
                required
              >
                {subjects.map((subject, index) => (
                  <option key={index} value={subject}>
                    {subject}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Imię
              <input
                type="text"
                maxLength="100"
                name="firstName"
                placeholder="Wpisz imię"
                value={form.firstName}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Nazwisko
              <input
                type="text"
                maxLength="100"
                name="lastName"
                placeholder="Wpisz nazwisko"
                value={form.lastName}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Preferowana forma potwierdzenia złożenia reklamacji
              <select
                name="confirmationMethod"
                value={form.confirmationMethod}
                onChange={handleInputChange}
              >
                <option value="e-mail">e-mail</option>
              </select>
            </label>
            <label>
              Preferowana forma kontaktu
              <select
                name="contactMethod"
                value={form.contactMethod}
                onChange={handleInputChange}
              >
                <option value="e-mail">e-mail</option>
              </select>
            </label>
            <label>
              E-mail
              <input
                type="email"
                maxlength="100"
                name="email"
                placeholder="Wpisz adres e-mail"
                value={form.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            </label>
            <label>
              PESEL
              <input
                type="number"
                minLength="11"
                maxlength="11"
                name="pesel"
                placeholder="Wpisz numer pesel"
                value={form.pesel}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Załączniki (opcjonalnie)
              <input
                className="fileUpload"
                type="file"
                accept="image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document
                , application/pdf"
                name="attachments"
                placeholder="Wczytaj jeden lub więcej plików z dysku"
                multiple
                onChange={handleFileChange}
              />
              {errors.form ? (
                <p
                  style={{
                    fontSize: "1.5rem",
                    color: "red",
                    marginTop: "-1.5rem",
                  }}
                >
                  {errors.form}
                </p>
              ) : (
                <p style={{ fontSize: "1.5rem", marginTop: "-1.5rem" }}>
                  Akceptowany format plików to PNG, JPEG, PDF oraz DOC.
                  Maksymalny rozmiar to 5&nbsp;MB.
                </p>
              )}
            </label>
            <label className="message">
              Treść wiadomości
              <textarea
                name="message"
                maxlength="2000"
                placeholder="Wpisz treść wiadomości"
                value={form.message}
                onChange={handleInputChange}
                required
              />
            </label>

            <div className="wrapper">
              <div className="text">
                <button className="btnGold" type="submit">
                  Wyślij formularz
                </button>
                <div>
                  <p>Obowiązek informacyjny</p>
                  {showFullText && (
                    <p className="formMsgInfo">
                      Administratorem danych osobowych jest Getin Noble Bank
                      S.A. w&nbsp;upadłości z&nbsp;siedzibą w&nbsp;Warszawie
                      (00-843) ul. Rondo Ignacego Daszyńskiego 2 C (Bank). Dane
                      kontaktowe Banku: Getin Noble Bank S.A. w&nbsp;upadłości
                      ul. Rondo Ignacego Daszyńskiego 2 C, 00-843 Warszawa,
                      telefon: +48 799 655 999. Dane kontaktowe Inspektora
                      Ochrony Danych: administrator.iod@gnb.pl. Bank przetwarza
                      dane osobowe w&nbsp;celu wynikającym z&nbsp;prawnie
                      uzasadnionych interesów realizowanych przez Bank, tj.
                      w&nbsp;celach reklamacyjnych w&nbsp;związku ze złożeniem
                      formularza reklamacyjnego (art. 6 ust. 1 lit. f RODO -
                      Rozporządzenie Parlamentu Europejskiego i&nbsp;Rady (UE)
                      2016/679 z&nbsp;dnia 27 kwietnia 2016 r. w&nbsp;sprawie
                      ochrony osób fizycznych w&nbsp;związku
                      z&nbsp;przetwarzaniem danych osobowych
                      i&nbsp;w&nbsp;sprawie swobodnego przepływu takich danych
                      oraz uchylenia dyrektywy 95/46/WE), tj. w&nbsp;celach
                      reklamacyjnych w&nbsp;związku ze złożeniem formularza
                      reklamacyjnego. Przetwarzanie to odbywa się
                      w&nbsp;szczególności jako realizacja reklamacji,
                      dochodzenie roszczeń związanych z&nbsp;reklamacją oraz
                      obrona przed roszczeniami kierowanymi wobec Banku
                      w&nbsp;zakresie przyjętej reklamacji. W związku ze złożoną
                      skargą/reklamacją do Banku Twoje dane osobowe mogą być
                      przetwarzane nie dłużej niż 6 lat od dnia rozpoczęcia
                      przetwarzania w&nbsp;tym celu. Masz prawo do żądania od
                      Banku dostępu do swoich danych osobowych, ich
                      sprostowania, usunięcia lub ograniczenia przetwarzania,
                      jak również prawo do wniesienia sprzeciwu wobec
                      przetwarzania i&nbsp;prawo do przenoszenia danych. Masz
                      także prawo do wniesienia skargi do Prezesa Urzędu Ochrony
                      Danych Osobowych.
                    </p>
                  )}
                  <a
                    className="gold underline"
                    onClick={() => setShowFullText(!showFullText)}
                  >
                    {showFullText ? "Zwiń" : "Pokaż pełną treść"}
                  </a>
                </div>
              </div>
              <div className="text">
                <Link
                  style={{ display: "inline-block" }}
                  to="/centrum-dokumentow"
                  className="btnOutline"
                >
                  Przejdź do dokumentów
                </Link>
                <p>Tutaj znajdziesz wzory dokumentów do wysłania</p>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default Form;
