import React, { useState } from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const alertNews008 = (
    <li className="alertCard eight">
      <div className="cardText">
        <h3>Noble Funds TFI sprzedany</h3>
        <br></br>
        <p className="lead">
          <span className="bold">
            Syndyk masy upadłościowej Getin Noble Banku i&nbsp;Idea Banku
            sprzedał Noble Funds TFI. Pieniądze z&nbsp;transakcji trafią do
            wierzycieli upadłych banków, w&nbsp;tym GNB. Po uzyskaniu zgody
            Komisji Nadzoru Finansowego nowym właścicielem towarzystwa funduszy
            inwestycyjnych zostanie VeloBank.
          </span>
        </p>

        {!isExpanded && (
          <a
            className="readMore"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleToggle();
            }}
          >
            Czytaj więcej
          </a>
        )}
        {isExpanded && (
          <>
            <br></br>
            <p>
              Transakcja została zrealizowana w&nbsp;terminie, który Syndyk
              Marcin Kubiczek zapowiadał publicznie. Kluczowym warunkiem podczas
              negocjacji sprzedaży było oczekiwanie, że kupujący zaproponuje
              najwyższą cenę, a&nbsp;w&nbsp;momencie podpisania umowy nastąpi
              jej rozliczenie i&nbsp;pieniądze wpłyną na konto masy upadłości
              Getin Noble Banku.
            </p>
            <p>
              Zakończenie negocjacji i&nbsp;podpisanie umowy sprzedaży to dobra
              wiadomość dla wszystkich stron. TFI wymagał inwestora
              strategicznego, finalnego, a&nbsp;nie tymczasowego, jakim jest
              syndyk. Noble Funds kupuje stabilny bank, a&nbsp;środki
              z&nbsp;transakcji zostaną przeznaczone na spłatę wierzytelności
              w&nbsp;trakcie procesu upadłości Getin Noble Bank.
            </p>
            <br></br>
            <p>
              <span className="bold">
                O Noble Funds Towarzystwo Funduszy Inwestycyjnych
              </span>
            </p>
            <br></br>
            <p>
              Noble Funds Towarzystwo Funduszy Inwestycyjnych SA specjalizuje
              się w&nbsp;tworzeniu oraz zarządzaniu otwartymi i&nbsp;zamkniętymi
              funduszami inwestycyjnymi, świadczy usługi asset management
              polegające na profesjonalnym zarządzaniu indywidualnym portfelem
              papierów wartościowych oraz doradztwa inwestycyjnego.
            </p>
            <p>
              Towarzystwo działa na rynku od listopada 2006&nbsp;r. Na koniec
              2023&nbsp;r. Noble Funds TFI zarządzało aktywami klientów
              o&nbsp;wartości 2,74&nbsp;mld&nbsp;zł, co plasowało firmę
              w&nbsp;grupie TFI średniej wielkości funkcjonujących na polskim
              rynku. Przychody Noble Funds TFI w&nbsp;2023&nbsp;r. wyniosły
              57,6&nbsp;mln&nbsp;zł, a&nbsp;zatrudnienie 50 osób.
            </p>
            <p>
              W&nbsp;wyniku przeprowadzonego we wrześniu 2022&nbsp;r. procesu
              resolution Getin Noble Banku największym akcjonariuszem firmy
              został Getin Noble Bank&nbsp;S.A. w&nbsp;restrukturyzacji.
              Beneficjariuszem tej transakcji jest również masa upadłości Idea
              Banku.
            </p>
            <a
              className="readMore"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleToggle();
              }}
            >
              Zwiń
            </a>
          </>
        )}
      </div>
      <p className="cardDate">Opublikowano 28.10.2024&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews008} />
    </>
  );
}

export default AlertCardBankruptcy;
