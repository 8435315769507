import React from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const alertNews005 = (
    <li className="alertCard five">
      <div className="cardText">
        <p>
          Syndyk masy upadłości Getin Noble Bank S.A. w&nbsp;upadłości
          informuje, że wpłaty zryczałtowanych kosztów postępowania
          upadłościowego wynikających z&nbsp;dokonania zgłoszenia wierzytelności
          po terminie, o&nbsp;których mowa w&nbsp;art.&nbsp;235 ust.&nbsp;1
          ustawy Prawo upadłościowe należy dokonywać na rachunek bankowy
          o&nbsp;numerze: 72 8436 0003 0000 0026 7436 0007.
        </p>
        <p>
          <span className="bold underline">
            Celem usprawnienia procesu podaje się dane do wykorzystania przy
            wykonywaniu przelewu:
          </span>
        </p>
        <p>
          <span className="bold">ODBIORCA:</span> MARCIN KUBICZEK SYNDYK MASY
          UPADŁOŚCI GETIN NOBLE BANK&nbsp;S.A. W&nbsp;UPADŁOŚCI
        </p>
        <p>
          <span className="bold">NUMER KONTA ODBIORCY:</span> 72 8436 0003 0000
          0026 7436 0007
        </p>
        <p>
          <span className="bold">TYTUŁ:</span> ZRYCZAŁTOWANY KOSZT POSTĘPOWANIA
          WA1M/GUp-Zw/44/2023 [jeżeli przelewu dokonuje pełnomocnik wierzyciela
          w&nbsp;tym miejscu należy również wskazać dane wierzyciela]
        </p>
        <p>
          <span className="bold">KWOTA:</span> 1 119,34 PLN
        </p>
      </div>
      <p className="cardDate">Opublikowano w styczniu 2024&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews005} />
    </>
  );
}

export default AlertCardBankruptcy;
