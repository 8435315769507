import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/ExchangeRates.css";
import { useScrollReveal } from "../hooks/useScrollReveal";

const ExchangeRatesNobleBank = () => {
  const [data, setData] = useState([]);
  const [idDate, setIdDate] = useState("");
  const [category, setCategory] = useState("credits"); // Możliwe wartości: "credits", "mortgage-pre2010"

  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      let url;
      if (category === "credits") {
        url = "https://gnbbank.pl/scrape-credits";
      } else if (category === "mortgage-pre2010") {
        url = "https://gnbbank.pl/scrape-mortgage-pre2010";
      }
      try {
        const response = await axios.get(url);
        setData(response.data.data);
        setIdDate(response.data.id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [category]);

  const groupedDataCredits = [];
  for (let i = 0; i < data.length; i += 4) {
    groupedDataCredits.push(data.slice(i, i + 4));
  }

  const groupedDataMortgagePre2010 = [];
  for (let i = 0; i < data.length; i += 5) {
    groupedDataMortgagePre2010.push(data.slice(i, i + 5));
  }

  useScrollReveal({ selector: "#exchangeRates" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="exchangeRates" className="nobleCredits">
      <div className="container">
        <h2>Kursy walut Noble Bank</h2>
        <div className="whiteBgc">
          <p>
            Poniższa tabela przedstawia kursy walut na dzień obecny ({day}.
            {month}.{year}). W&nbsp;celu zapoznania się z wartościami
            historycznymi zapraszamy do odwiedzenia strony:{" "}
            <a
              className="gold"
              href="https://www.noblebank.pl/tabele-kursow-walut"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.noblebank.pl/tabele-kursow-walut
            </a>
          </p>
          <div className="switchTable">
            <a
              className={`${category === "credits" ? "active" : ""}`}
              onClick={() => setCategory("credits")}
              style={{ cursor: "pointer" }}
            >
              Kredyty
            </a>
            <a
              className={`${category === "mortgage-pre2010" ? "active" : ""}`}
              onClick={() => setCategory("mortgage-pre2010")}
              style={{ cursor: "pointer" }}
            >
              Kredyty&nbsp;hipoteczne przed&nbsp;4.01.2010&nbsp;r.
            </a>
          </div>

          <div
            className={`currencyTab ${category === "credits" ? "active" : ""}`}
          >
            {category === "credits" ? (
              <>
                <p>
                  Bankowa tabela kursów walut dla kredytów dewizowych oraz
                  indeksowanych kursem walut obcych.
                </p>
                {groupedDataCredits.map((group, index) => (
                  <ul key={index} className="gridParent">
                    {group.map((item, i) => (
                      <li key={i} className={`i${i + 1}`}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ))}
                <p style={{ marginTop: "5rem" }}>
                  * Kurs Sprzedaży NBP jest dostępny pod linkiem{" "}
                  <a
                    className="gold"
                    href="http://www.nbp.pl/kursy/kursyc.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.nbp.pl/kursy/kursyc.html
                  </a>
                </p>
                <p>
                  Spread stanowi różnicę pomiędzy kursem kupna i&nbsp;sprzedaży
                  danej waluty ogłaszanymi przez Bank w&nbsp;Tabeli Kursowej.
                </p>
              </>
            ) : (
              <>
                <p>
                  Tabela kursów walut dotycząca kredytów hipotecznych
                  udzielonych przez Noble Bank - pion bankowości prywatnej Getin
                  Noble Bank S.A. przed dniem 4&nbsp;stycznia 2010&nbsp;r.
                </p>
                {groupedDataMortgagePre2010.map((group, index) => (
                  <ul key={index} className="gridParent">
                    {group.map((item, i) => (
                      <li key={i} className={`i${i + 1}`}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ExchangeRatesNobleBank;
