import React from "react";
import { Link } from "react-router-dom";
import "../css/Footer.css";
import LogoWhite from "../img/gnblogo-white.png";

function Footer() {
  return (
    <footer>
      <div className="footerWrapper">
        <section className="footerCompanyDetails stCol">
          <div className="footerLogo">
            <img src={LogoWhite} alt="Logo"></img>
          </div>
          <ul className="footerDetailsList">
            <li className="footerDetail one">
              Getin Noble Bank Spółka Akcyjna w&nbsp;upadłości
            </li>

            {/* <li className="footerDetail">
              <i class="fa-solid fa-envelope"></i>
              <a href="mailto:getin@kubiczekm.com">getin@kubiczekm.com</a>
            </li> */}

            <li className="footerDetail">
              <i class="fa-solid fa-phone"></i>
              <a href="tel:+48799 655 999" data-external-url="1">
                +48 799 655 999
              </a>
            </li>

            <li className="footerDetail">
              <span>
                Infolinia czynna od poniedziałku do piątku,&nbsp;w&nbsp;godz.
                09:00-17:00.<br></br>Opłata za połączenie według taryfy
                operatora.<br></br>Infolinia w&nbsp;języku polskim.
              </span>
            </li>
          </ul>
        </section>

        <section className="footerCompanyDetails ndCol">
          <ul className="footerDetailsList">
            <li className="footerDetail">
              <Link to="/informacje-o-postepowaniu">
                Informacje o&nbsp;postępowaniu
              </Link>
            </li>

            <li className="footerDetail">
              <Link to="/centrum-dokumentow">Dokumenty</Link>
            </li>

            {/* <li className="footerDetail">
              <Link to="/FAQ">Pomoc</Link>
            </li> */}
            <li className="footerDetail">
              <Link to="/kontakt">Kontakt</Link>
            </li>
            <li className="footerDetail">
              <a className="banking" href="https://secure.gnbbank.pl">
                Bankowość internetowa
              </a>
            </li>
          </ul>
        </section>

        <section className="footerCompanyDetails rdCol">
          <p>
            Portal syndyka masy upadłości Getin Noble Bank&nbsp;S.A.
            w&nbsp;upadłości
          </p>
        </section>

        {/* <aside>
          <button className="btnGold btnGoUp">
            <a href="#hero"><i class="fa-solid fa-angle-up"></i></a>
          </button>
        </aside> */}
      </div>
      <div className="footerCopyright">
        © 2024 Getin Noble Bank Spółka Akcyjna w&nbsp;upadłości
      </div>
    </footer>
  );
}

export default Footer;
