import React from "react";
import "../css/Cookies.css";

function Cookies() {
  return (
    <main>
      <section id="cookies">
        <div className="container">
          <header>
            <h1 className="heading">
              Polityka Getin Noble Bank&nbsp;S.A. w&nbsp;upadłości dotycząca
              plików cookies
            </h1>
          </header>
          <div className="whiteBgc">
            <div className="cookiesText">
              <p>
                Niniejsza Polityka dotyczy plików cookies i&nbsp;odnosi się do
                stron internetowych („Serwis”), których operatorem jest Getin
                Noble Bank&nbsp;S.A. w&nbsp;upadłości z&nbsp;siedzibą
                w&nbsp;Warszawie przy&nbsp;Rondzie Ignacego
                Daszyńskiego&nbsp;2&nbsp;C (dalej „Bank”).
              </p>

              <ol className="numberList">
                <li>
                  Serwis nie zbiera w&nbsp;sposób automatyczny żadnych
                  informacji, z&nbsp;wyjątkiem informacji zawartych
                  w&nbsp;plikach cookies, z&nbsp;zastrzeżeniem pkt&nbsp;7.
                </li>
                <li>
                  Pliki cookies (tzw. ciasteczka) stanowią dane informatyczne,
                  w&nbsp;szczególności pliki tekstowe, które przechowywane są na
                  urządzeniu końcowym Użytkownika Serwisu i&nbsp;przeznaczone są
                  do korzystania ze stron internetowych Serwisu. Cookies
                  zawierają nazwę strony internetowej, z&nbsp;której pochodzą,
                  czas przechowywania ich na urządzeniu końcowym oraz unikalny
                  numer.
                </li>
                <li>Pliki cookies wykorzystywane są w celu:</li>
                <ul className="dottedList">
                  <li>
                    tworzenia statystyk, które pomagają zrozumieć, w&nbsp;jaki
                    sposób Użytkownicy Serwisu korzystają ze stron
                    internetowych, co umożliwia ulepszanie struktury
                    i&nbsp;zawartości tych stron;
                  </li>
                  <li>utrzymania sesji Użytkownika Serwisu</li>
                  <li>
                    identyfikowania źródła, z&nbsp;jakiego Użytkownik trafił na
                    stronę internetową Banku.
                  </li>
                </ul>
                <li>
                  Na stronach Serwisu mogą znajdować się odwołania do innych
                  serwisów internetowych, które stosują własne polityki
                  prywatności. Rekomendujemy zapoznanie się z&nbsp;każdą
                  z&nbsp;takich polityk, ponieważ Bank nie ponosi jakiejkolwiek
                  odpowiedzialności za zasady zachowania prywatności
                  obowiązujące w&nbsp;tych serwisach.
                </li>
                <li>
                  W&nbsp;ramach Serwisu stosowane są trzy zasadnicze rodzaje
                  plików cookies:
                </li>
                <ul className="dottedList">
                  <li>
                    sesyjne (session cookies)– są plikami tymczasowymi,
                    przechowywanymi na urządzeniu końcowym Użytkownika do czasu
                    wylogowania, opuszczenia strony internetowej lub wyłączenia
                    oprogramowania (przeglądarki internetowej);
                  </li>
                  <li>
                    stałe (persistent cookies)– pliki cookies, które
                    przechowywane są na urządzeniu końcowym Użytkownika przez
                    czas określony w&nbsp;parametrach plików cookies lub do
                    czasu ich usunięcia przez Użytkownika;
                  </li>
                  <li>
                    cookies podmiotów zewnętrznych (third parties cookies)– to
                    pliki pochodzące np. z&nbsp;serwerów reklamowych, serwerów
                    firm i&nbsp;dostawców usług współpracujących z właścicielem
                    danej strony internetowej. Te pliki pozwalają dostosowywać
                    reklamy– dzięki którym korzystanie ze stron internetowych
                    może być bezpłatne– do preferencji i&nbsp;zwyczajów ich
                    użytkowników. Pozwalają również ocenić skuteczność działań
                    reklamowych (np. dzięki zliczaniu, ile osób kliknęło
                    w&nbsp;daną reklamę i&nbsp;przeszło na stronę internetową
                    reklamodawcy).
                  </li>
                </ul>
                <li>
                  W ramach Serwisu stosowane są następujące rodzaje plików
                  cookies:
                </li>

                <ul className="dottedList">
                  <li>nazwa cookie, rodzaj, cel i&nbsp;zawartość</li>

                  <li>
                    _ga - stałe - to ciasteczko używane jest przez system
                    analityczny Google Analytics do przechowywania informacji
                    o&nbsp;ilości wyświetleń dla danego użytkownika.
                  </li>
                  <li>
                    _ga_M7XZ1452F0 - stałe - to ciasteczko jest używane przez
                    system analityczny Google Analytics do monitorowania
                    aktywności użytkowników na stronie internetowej gnbbank.pl.
                  </li>
                </ul>
                <li>
                  Zgodnie z&nbsp;praktyką przyjętą przez większość serwisów
                  internetowych Bank przechowuje zapytania HTTP kierowane do
                  naszego serwera. Oznacza to, że Bank zna publiczne adresy IP,
                  z&nbsp;których użytkownicy przeglądają treści informacyjne
                  Serwisu. Przeglądane zasoby identyfikowane są poprzez adresy
                  URL. Znamy również:
                </li>
                <ul className="dottedList">
                  <li>czas nadejścia zapytania,</li>
                  <li>czas wysłania odpowiedzi,</li>
                  <li>
                    nazwę stacji klienta– identyfikacja realizowana przez
                    protokół HTTP,
                  </li>
                  <li>
                    informacje o błędach, jakie wystąpiły przy realizacji
                    transakcji HTTP,
                  </li>
                  <li>
                    adres URL strony poprzednio odwiedzanej przez użytkownika
                    (referer link)– w&nbsp;wypadku gdy przejście na stronę Banku
                    nastąpiło przez odnośnik,
                  </li>
                  <li>informacje o&nbsp;przeglądarce użytkownika.</li>
                  <li>
                    Dane te nie są kojarzone z&nbsp;konkretnymi osobami
                    przeglądającymi strony Banku, z&nbsp;zastrzeżeniem,
                    o&nbsp;którym mowa w punkcie 9. W&nbsp;celu zapewnienia jak
                    najwyższej jakości serwisu okazjonalnie analizujemy pliki
                    z&nbsp;logami, aby określić, które strony odwiedzane są
                    najczęściej, jakie przeglądarki stron internetowych są
                    stosowane, czy struktura strony nie zawiera błędów itp.
                  </li>
                </ul>
                <li>
                  Pliki cookies na stronach Serwisu z&nbsp;reguły nie zbierają
                  danych osobowych. Wyjątkiem od zasad przedstawionych
                  w&nbsp;ww.&nbsp;punktach jest korzystanie z&nbsp;formularza
                  kontaktowego lub formularzy elektronicznych dotyczących
                  produktów finansowych Banku, tj.&nbsp;ich wypełnienie
                  i&nbsp;wysłanie. W&nbsp;takich przypadkach wszelkie dane
                  osobowe znajdujące się w&nbsp;ww.&nbsp;formularzach
                  wprowadzane są przez same osoby, których dane dotyczą,
                  i&nbsp;przetwarzane na podstawie przesłanek określonych
                  w&nbsp;odrębnych przepisach prawa, w&nbsp;szczególności
                  przepisach ustawy o&nbsp;ochronie danych osobowych
                  i&nbsp;ustawy Prawo bankowe.
                </li>
                <li>
                  Warunkiem działania plików cookies jest ich akceptacja
                  w&nbsp;przeglądarce oraz nieusuwanie ich. W&nbsp;wielu
                  przypadkach oprogramowanie służące do przeglądania stron
                  internetowych domyślnie dopuszcza przechowywanie plików
                  cookies na urządzeniu końcowym Użytkownika. Użytkownicy
                  Serwisu mogą dokonać w&nbsp;każdym czasie zmiany ustawień
                  dotyczących plików cookies. Ustawienia te mogą zostać
                  zmienione w&nbsp;szczególności w&nbsp;taki sposób, aby
                  blokować automatyczną obsługę plików cookies
                  w&nbsp;ustawieniach przeglądarki internetowej bądź informować
                  o&nbsp;ich każdorazowym zamieszczeniu na urządzeniu
                  Użytkownika Serwisu. Szczegółowe informacje o&nbsp;możliwości
                  i&nbsp;sposobach obsługi plików cookies dostępne są
                  w&nbsp;ustawieniach odpowiedniej przeglądarki internetowej.
                </li>
                <li>
                  Operator Serwisu informuje, że ograniczenia stosowania plików
                  cookies mogą wpłynąć na niektóre funkcjonalności dostępne na
                  stronach internetowych Serwisu.
                </li>
                <li>
                  W wypadku zmiany obowiązującej polityki do powyższych
                  postanowień wprowadzone zostaną odpowiednie modyfikacje.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Cookies;
