import { useState, useEffect, useRef } from "react";
import "../css/Quiz.css";

const questions = [
  {
    question:
      "Kredyt zabezpieczony hipotecznie został zaciągnięty na cel mieszkaniowy w celu zaspokojenia swoich potrzeb mieszkaniowych",
    choices: ["Tak", "Nie"],
    correctAnswer: "Tak",
  },
  {
    question:
      "Co najmniej jeden z kredytobiorców posiada status osoby bezrobotnej w dniu złożenia wniosku o wsparcie lub pożyczkę na spłatę zadłużenia",
    choices: ["Tak", "Nie"],
    correctAnswer: "Tak",
  },
  {
    question:
      "Utrata zatrudnienia przez co najmniej jednego z kredytobiorców nastąpiła w wyniku rozwiązania umowy o pracę za wypowiedzeniem przez kredytobiorcę lub rozwiązania umowy o pracę bez wypowiedzenia z winy pracownika w trybie art. 52 par. 1 ustawy z dnia 26 czerwca 1974r. – Kodeks pracy (Dz. U. z 2019r. poz. 1040 i 1043)",
    choices: ["Tak", "Nie"],
    correctAnswer: "Tak",
  },
  {
    question:
      "Miesięczne koszty obsługi kredytu mieszkaniowego przekraczają 40% dochodów osiąganych przez moje gospodarstwo domowe",
    choices: ["Tak", "Nie"],
    correctAnswer: "Tak",
  },
  {
    question:
      "Dochody mojego gospodarstwa domowego pomniejszone o miesięczne koszty obsługi kredytu mieszkaniowego nie przekracza:<br/>-	w przypadku gospodarstwa jednoosobowego - 1940 PLN, <br/>- w przypadku gospodarstwa wieloosobowego - iloczynu liczby osób w gospodarstwie domowym i kwoty 1500 PLN.",
    choices: ["Tak", "Nie"],
    correctAnswer: "Nie",
  },
  {
    question: "Czy umowa kredytu mieszkaniowego została wypowiedziana?",
    choices: ["Tak", "Nie"],
    correctAnswer: "Nie",
  },
  {
    question:
      "Co najmniej jednemu z kredytobiorców przysługuje świadczenie z tytułu utraty pracy wynikające z zawartej umowy ubezpieczenia spłaty kredytu, gwarantującej wypłatę świadczenia na wypadek utraty pracy",
    choices: ["Tak", "Nie"],
    correctAnswer: "Nie",
  },
  {
    question:
      "Jestem właścicielem innego lokalu mieszkalnego lub domu jednorodzinnego lub byłem nim w okresie 6 miesięcy przed złożeniem wniosku",
    choices: ["Tak", "Nie"],
    correctAnswer: "Nie",
  },
  {
    question:
      "Posiadam spółdzielcze  prawo do lokalu mieszkalnego lub domu jednorodzinnego w spółdzielni mieszkaniowej lub posiadałem takie prawo w okresie 6 miesięcy przed złożeniem wniosku",
    choices: ["Tak", "Nie"],
    correctAnswer: "Nie",
  },
  {
    question:
      "Posiadam roszczenie o przeniesienie na mnie prawa własności lokalu mieszkalnego, domu jednorodzinnego, spółdzielczego prawa do lokalu mieszkalnego lub domu jednorodzinnego w spółdzielni mieszkaniowej lub posiadałem takie roszczenie w okresie 6 miesięcy przed złożeniem wniosku",
    choices: ["Tak", "Nie"],
    correctAnswer: "Nie",
  },
];

const questionsNumbers = questions.map((item, i) => i);
const Quiz = () => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answer, setAnswer] = useState("");
  const [answers, setAnswers] = useState([]);
  const [results, setResults] = useState([]);
  const [result, setResult] = useState("");
  const [end, setEnd] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const { question, choices, correctAnswer } = questions[activeQuestion];

  let filteredQuestions = useRef(questionsNumbers);
  const radio = useRef([]);
  const numChecker = (arr, num) => arr.find((v) => v >= num);
  let resChecker = (arr) => arr.every((v) => v === true);

  const clickRadio = (item) => {
    setAnswer(item);
  };

  const onClickNext = () => {
    answer === correctAnswer
      ? setResults((item) => [...item, true])
      : setResults((item) => [...item, false]);

    setAnswers((prev) => [...prev, answer]);

    if (!answer) {
      setIsSelected(false);
      return;
    } else {
      setIsSelected(true);
    }

    if (activeQuestion === questions.length) return;

    setActiveQuestion((prev) => prev + 1);
    setAnswer("");
  };

  const onClickBack = () => {
    if (end === true) {
      setActiveQuestion(questions.length - 1);
      setEnd(false);
      setResults((items) => items.slice(0, -1));
      setAnswers((items) => items.slice(0, -1));
    } else {
      const closest = Math.max(
        ...filteredQuestions.current.filter((num) => num < activeQuestion)
      );
      if (closest < 0) return;
      setResults((items) => items.slice(0, -1));
      setAnswers((items) => items.slice(0, -1));
      setActiveQuestion(closest);
    }
  };

  const onClickResult = () => {
    answer === correctAnswer
      ? setResults((item) => [...item, true])
      : setResults((item) => [...item, false]);

    if (!answer) {
      setIsSelected(false);
      return;
    } else {
      setIsSelected(true);
    }

    setAnswers((prev) => [...prev, answer]);
    setEnd(true);
  };

  useEffect(() => {
    if (answers[1] === "Nie") {
      const hiddenQuestions = [2];
      filteredQuestions.current = filteredQuestions.current.filter(
        (item) => !hiddenQuestions.includes(item)
      );

      setActiveQuestion(numChecker(filteredQuestions.current, activeQuestion));
    }

    if (answers[2] === "Tak") {
      const hiddenQuestions = [3, 4];
      filteredQuestions.current = filteredQuestions.current.filter(
        (item) => !hiddenQuestions.includes(item)
      );

      setActiveQuestion(numChecker(filteredQuestions.current, activeQuestion));
    }

    if (answers[3] === "Tak") {
      const hiddenQuestions = [4];
      filteredQuestions.current = filteredQuestions.current.filter(
        (item) => !hiddenQuestions.includes(item)
      );

      setActiveQuestion(numChecker(filteredQuestions.current, activeQuestion));
    }

    setResult(resChecker(results));
    if (radio.current[0] !== null) {
      radio.current.forEach((item) => {
        item.checked = false;
      });
    }
  }, [answers, results, activeQuestion]);

  return (
    <>
      {end ? (
        result ? (
          <>
            <h3 className="textGreen">Weryfikacja pozytywna</h3>
            <p>
              Drogi Kliencie wszystko wskazuje na to, że jest szansa na
              uzyskania wsparcia/pożyczki na spłatę zadłużenia. Wniosek możesz
              złożyć we wskazanej placówce bankowej{" "}
              <a
                className="gold"
                href="https://www.getinbank.pl/bankowosc-osobista/placowki#/placowki"
                target="_blank"
              >
                https://www.getinbank.pl/bankowosc-osobista/placowki#/placowki
              </a>
            </p>
            <p>
              Powyższa weryfikacja ma charakter orientacyjny – ostateczna
              decyzja zostanie podjęta po złożeniu wniosku.
            </p>
          </>
        ) : (
          <>
            <h3 className="textRed">Weryfikacja negatywna</h3>
            <p>
              Drogi Kliencie na podstawie udzielonych informacji nie widzimy
              możliwości udzielenia wsparcia/pożyczki na spłatę zadłużenia.
            </p>
            <p>
              Powyższa weryfikacja ma charakter orientacyjny – ostateczna
              decyzja zostanie podjęta po złożeniu wniosku.
            </p>
          </>
        )
      ) : (
        <>
          <p>Pytanie {activeQuestion + 1}</p>
          <p className="bold">{question}</p>
          <ul className="list">
            {choices.map((item, index) => (
              <li className="listItem" key={item}>
                <label className="label">
                  <input
                    ref={(ref) => {
                      radio.current[index] = ref;
                    }}
                    className="inputQuiz"
                    type="radio"
                    onChange={() => {
                      clickRadio(item);
                      setIsSelected(true);
                    }}
                    name={`question${activeQuestion}`}
                  />
                  <span className={"inputText"}>{item}</span>
                </label>
              </li>
            ))}
          </ul>
          {isSelected || <p>Proszę zaznacz jedną opcję</p>}
        </>
      )}
      <div className="buttonWrapper">
        {activeQuestion < questions.length - 1 ? (
          <>
            {activeQuestion === 0 || (
              <button id="btnBack" className="btnOutline" onClick={onClickBack}>
                Powrót
              </button>
            )}
            <button className="btnGold buttonDark" onClick={onClickNext}>
              Dalej
            </button>
          </>
        ) : (
          end || (
            <>
              <button id="btnBack" className="btnOutline" onClick={onClickBack}>
                Powrót
              </button>
              <button className="btnGold" onClick={onClickResult}>
                Sprawdź wynik
              </button>
            </>
          )
        )}
      </div>
    </>
  );
};

export default Quiz;
