import React from "react";
import "../css/BorrowerSupportFund.css";
import { useScrollReveal } from "../hooks/useScrollReveal";
import borrowerSupportFloodDoc from "../doc/borrowerSupport_docs/wniosek-o-udzielenie-wsparcia-na-splate-zadluzenia-dla-kredytobiorcy-ktory-zaciagnal-kredyt-mieszkaniowy-i-zostal-poszkodowany-w-wyniku-powodzi.pdf";

function BorrowerSupportFund() {
  useScrollReveal({ selector: "#borrowerSupportFundFloodAct" });

  useScrollReveal({
    selector: ".whiteBgc, .imageBackground",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="borrowerSupportFundFloodAct">
      <div className="container">
        <section id="informationAboutSupport">
          <header>
            <h1 className="heading">
              Fundusz Wsparcia Kredytobiorców - Ustawa Powodziowa
            </h1>
          </header>
          <div className="textDoubleMargin whiteBgc">
            <div className="text">
              <h2>Informacja o wysokości wsparcia</h2>
              <p>
                Zgodnie z&nbsp;ustawą dnia 1&nbsp;października 2024&nbsp;r.
                o&nbsp;zmianie ustawy o&nbsp;szczególnych rozwiązaniach
                związanych z&nbsp;usuwaniem skutków powodzi oraz niektórych
                innych ustaw, jeśli jesteś poszkodowanym kredytobiorcą
                w&nbsp;rozumieniu ustawy z&nbsp;dnia 9&nbsp;października
                2015&nbsp;r. o&nbsp;wsparciu kredytobiorców, którzy zaciągnęli
                kredyt mieszkaniowy i&nbsp;znajdują się w&nbsp;trudnej sytuacji
                finansowej – masz możliwość wsparcia finansowego z&nbsp;Funduszu
                Wsparcia Kredytobiorców w&nbsp;wysokości równej{" "}
                <span className="bold">
                  12 miesięcznych rat kapitałowych i&nbsp;odsetkowych
                </span>{" "}
                kredytu mieszkaniowego jeśli:
              </p>
              <ul className="dottedList">
                <li>
                  Twój dom jednorodzinny lub lokal mieszkalny, zostały
                  uszkodzone lub zniszczone na&nbsp;skutek powodzi oraz
                </li>
                <li>
                  utraciłeś, chociażby czasowo, możliwość korzystania
                  z&nbsp;tego domu w&nbsp;całości lub w&nbsp;części lub lokalu
                </li>
              </ul>
            </div>
            <div className="text">
              <h3>Jak złożyć wniosek?</h3>
              <ol className="numberList">
                <li>
                  <a href={borrowerSupportFloodDoc} className="gold underline">
                    Wydrukuj, wypełnij i podpisz wniosek.
                  </a>
                </li>
                <li>
                  Zeskanuj lub zrób zdjęcie wypełnionego i&nbsp;podpisanego
                  wniosku.
                </li>
                <li>
                  Prześlij skan/zdjęcie na adres:{" "}
                  <a className="gold" href="mailto:kontakt@gnbbank.pl">
                    kontakt@gnbbank.pl
                  </a>
                </li>
              </ol>
            </div>
            <div className="text">
              <h3>Kiedy wsparcie może nie być przyznane</h3>
              <p>Nie otrzymasz wsparcia jeśli:</p>
              <ul className="dottedList">
                <li>
                  przed złożeniem wniosku o&nbsp;wsparcie wypowiedzieliśmy Ci
                  umowę kredytu hipotecznego,
                </li>
                <li>
                  jest prowadzona egzekucyjna na kredytowanej przez nas
                  nieruchomości.
                </li>
              </ul>
              <h3 style={{ marginTop: "5rem", marginBottom: "3rem" }}>
                Przeczytaj, jeśli korzystasz już z&nbsp;innych form wsparcia
                kredytobiorców
              </h3>
              <p>
                <span className="bold">Korzystasz ze wsparcia z FWK?</span>{" "}
                Zawarcie umowy o&nbsp;wsparcie powodziowe z&nbsp;Funduszu
                Wsparcia Kredytobiorców powoduje, że dotychczasowe wsparcie z
                tego Funduszu przestanie być wypłacane. Po zakończeniu wypłaty
                bezzwrotnego wsparcia powodziowego możesz wnioskować
                o&nbsp;standardowe wsparcie na podstawie art.&nbsp;3 ust.
                1&nbsp;Ustawy z&nbsp;9&nbsp;października 2015&nbsp;roku
                o&nbsp;wsparciu kredytobiorców, którzy zaciągnęli kredyt
                mieszkaniowy i&nbsp;znajdują się w trudnej sytuacji finansowej
                w&nbsp;niewykorzystanym zakresie.
              </p>
              <p style={{ marginTop: "4rem" }}>Podstawa prawna:</p>
              <p>
                Ustawa z&nbsp;dnia 1&nbsp;października 2024&nbsp;r.
                o&nbsp;zmianie ustawy o&nbsp;szczególnych rozwiązaniach
                związanych z&nbsp;usuwaniem skutków powodzi oraz niektórych
                innych ustaw. Rozporządzenie Rady Ministrów z&nbsp;dnia
                5&nbsp;października 2024&nbsp;r. zmieniające rozporządzenie
                w&nbsp;sprawie wykazu gmin, w&nbsp;których są stosowane
                szczególne rozwiązania związane z&nbsp;usuwaniem skutków powodzi
                z&nbsp;września 2024&nbsp;r., oraz rozwiązań stosowanych na ich
                terenie
              </p>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default BorrowerSupportFund;
