import React from "react";
import { useScrollReveal } from "../hooks/useScrollReveal";

function BmrFaq() {
  useScrollReveal({ selector: "#bmrFaq" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="bmrFaq">
      <div className="container">
        <header>
          <h1 className="heading">Pytania i&nbsp;odpowiedzi do aneksów BMR</h1>
        </header>
        <section>
          <div className="textDoubleMargin whiteBgc">
            <h2>Czego dotyczy aneks?</h2>
            <p>
              Rozporządzenie BMR nakłada na&nbsp;podmioty stosujące wskaźniki
              referencyjne (banki) obowiązek sporządzenia na piśmie rzetelnych
              planów awaryjnych, określających działania na wypadek istotnej
              zmiany bądź zaprzestania opracowywania wskaźnika referencyjnego
              oraz odzwierciedlenie tych planów w&nbsp;umowach z&nbsp;klientami.
              <br></br>Przygotowany aneks ma na celu realizację tego obowiązku.
              Co ważne aneks uwzględnia również stanowisko Komisji Nadzoru
              Finansowego z&nbsp;dnia 8 kwietnia 2021 r. w&nbsp;sprawie planów
              (tzw. planów awaryjnych), o&nbsp;których mowa w&nbsp;art. 28 ust.
              2 Rozporządzenia BMR, oraz w&nbsp;sprawie odzwierciedlenia planów
              w&nbsp;stosunkach umownych <br></br>z&nbsp;klientami skierowane do
              banków komercyjnych, w&nbsp;tym banków zrzeszających oraz do
              banków spółdzielczych.
            </p>
          </div>

          <div className="text textDoubleMargin whiteBgc">
            <h2>Czy muszę podpisać aneks?</h2>
            <p>
              Podpisanie aneksu jest dobrowolne, ale przez nas zalecane.
              Odzwierciedlenie planu awaryjnego w&nbsp;Twojej umowie&nbsp;
              pozwoli w&nbsp;przyszłości uniknąć wątpliwości, co do sposobu jej
              realizacji oraz zapewni jej niezakłócone wykonywanie,
              w&nbsp;przypadku&nbsp; istotnej zmiany wskaźnika referencyjnego
              lub wystąpienia sytuacji awaryjnej, przez którą rozumiemy
              wystąpienie jednego z&nbsp;następujących zdarzeń:
            </p>
            <ul className="dottedList">
              <li>
                ogłoszenie braku zezwolenia dla administratora wskaźnika - organ
                nadzoru nad administratorem poda do publicznej wiadomości, że
                stopa bazowa nie zostanie zarejestrowana lub nie będzie wydana
                decyzja o&nbsp;jej ekwiwalentności lub administrator nie
                otrzymał, nie otrzyma, lub cofnięto mu lub zawieszono
                zezwolenie&nbsp; lub rejestrację dla opracowywania stopy
                bazowej,
              </li>
              <li>
                ogłoszenie utraty reprezentatywności wskaźnika - podmiot
                wyznaczający poda do publicznej wiadomości, że stopa bazowa
                przestała lub przestanie być reprezentatywna dla właściwego dla
                niej rynku bazowego lub rzeczywistości ekonomicznej, którą stopa
                bazowa miała mierzyć i&nbsp;że brak jest możliwości do
                przywrócenia takiej reprezentatywności,
              </li>
              <li>
                czasowy brak publikacji wskaźnika - stopa bazowa nie zostanie
                opublikowana z&nbsp;powodów niezwiązanych ze zdarzeniem
                regulacyjnym,
              </li>
              <li>
                ogłoszenie zaprzestania opracowywania wskaźnika - podmiot
                wyznaczający poda do publicznej wiadomości, że administrator
                przestał lub przestanie na stałe opracowywać stopę bazową,
                a&nbsp;do czasu tego zaprzestania nie zostanie wyznaczony
                następca dla administratora, który miałby w&nbsp;dalszym ciągu
                opracowywać lub publikować stopę bazową.
              </li>
            </ul>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Dlaczego proponujemy Ci zawarcie aneksu?</h2>
            </header>
            <p>
              Gdy nastąpi jedno ze zdarzeń, które w&nbsp;aneksie zostało opisane
              jako zdarzenie regulacyjne (ogłoszenie braku zezwolenia,
              ogłoszenie zaprzestania opracowywania wskaźnika, lub ogłoszenie
              utraty reprezentatywności)&nbsp; będziesz wiedział jakie działania
              podejmiemy oraz&nbsp; w&nbsp;jaki sposób ustalimy wskaźnik
              alternatywny dla stosowanego w&nbsp;umowie wskaźnika,
              w&nbsp;stosunku do którego zaszło zdarzenie regulacyjne. Aneks
              określa również zasady oraz sposób&nbsp; w&nbsp;jaki poinformujemy
              Cię o&nbsp;zajściu zdarzenia regulacyjnego i&nbsp;konsekwencjach
              z&nbsp;tym związanych. Zawarcie aneksu pozwoli uniknąć wątpliwości
              co do sposobu realizacji umowy i&nbsp;zapewni jej dalsze
              niezakłócone wykonywanie w&nbsp;przyszłości.
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Co zmieni się w umowie, jeżeli zawrzesz aneks?</h2>
            </header>
            <p>Aneks wprowadzi w&nbsp;Twojej umowie:</p>
            <p>
              a.&nbsp;&nbsp; &nbsp;klauzulę awaryjną, czyli zasady postępowania,
              które zastosujemy w&nbsp;przypadku istotnej zmiany lub wystąpienia
              zdarzenia regulacyjnego&nbsp; dotyczącego&nbsp; wskaźnika
              referencyjnego stosowanego w&nbsp;Twojej umowie,
            </p>
            <p>
              b.&nbsp;&nbsp; &nbsp;sposób postępowania na wypadek czasowej
              niedostępności wskaźnika referencyjnego.
            </p>
            <p>
              Zasady te opisaliśmy w&nbsp;załączniku nr 1 do aneksu
              o&nbsp;nazwie „
              <span className="bold">
                Zasady postępowania banku na wypadek istotnej zmiany lub
                wystąpienia sytuacji awaryjnej dotyczącej wskaźnika
                referencyjnego
              </span>
              ”
            </p>
            <p>
              Ponad to w&nbsp;załączniku nr 2 - „Załącznik informacyjny BMR” -
              zamieściliśmy ogólne informacje o&nbsp;wskaźnikach referencyjnych
              i&nbsp;ich administratorach oraz ryzykach związanych ze
              stosowaniem wskaźników referencyjnych.
            </p>
            <p>
              Co istotne, do momentu wystąpienia istotnej zmiany, zdarzenia
              regulacyjnego lub czasowej niedostępności postanowienia aneksu nie
              będą miały wpływu na Twoją umowę. Aneks wprowadza zmiany na
              przyszłość. Oznacza to, że dopiero w&nbsp;razie wystąpienia jednej
              z&nbsp;opisanych w&nbsp;aneksie i&nbsp;niezależnych od banku
              sytuacji&nbsp; znajdą zastosowanie opisane w&nbsp;nim zasady
              postępowania.&nbsp; &nbsp;
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                Jak aneks wpłynie na wysokość rat/ miesięcznych zobowiązań?
              </h2>
            </header>
            <p>
              Samo zawarcie aneksu nie skutkuje zmianą wysokości rat. Aneks,
              który proponujemy określa sposób postępowania na wypadek, gdyby
              wskaźnik referencyjny stosowany w&nbsp;Twojej umowie zostałby
              objęty istotną zmianą, zdarzeniem regulacyjnym lub nie był czasowo
              publikowany.
            </p>
            <p>
              Jeśli wystąpią zdarzenia, które opisujemy w&nbsp;aneksie to
              wysokość rat/miesięcznych zobowiązań może, ale nie musi ulec
              zmianie. Zmiana może&nbsp; spowodować zarówno spadek wysokości
              rat, jak i&nbsp;ich wzrost. Skala wpływu może być jednak
              redukowana poprzez zastosowanie stosownej korekty zgodnie
              z&nbsp;zasadami opisanymi w&nbsp;aneksie tak, aby wysokość rat
              pozostała na zbliżonym poziomie.
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Ile kosztuje zawarcie aneksu?</h2>
            </header>
            <p>Zawarcie aneksu jest bezpłatne.</p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Kto musi podpisać aneks?</h2>
            </header>
            <p>Aneks muszą podpisać wszyscy kredytobiorcy.</p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                W&nbsp;jaki sposób możesz zamówić a następnie podpisać aneks?
              </h2>
            </header>
            <p>Aneks możesz podpisać w&nbsp;następujący sposób:</p>
            <ol className="numberList">
              <li>
                jeżeli otrzymałeś zaproszenie mejlem, to udaj się do
                najbliższego oddziału. Doradca przygotuje aneks do Twojej umowy
                i&nbsp;odpowie na ewentualne pytania. Aneks muszą podpisać
                wszyscy kredytobiorcy w&nbsp;obecności doradcy.
                <ul className="dottedList">
                  <li>
                    poprzez formularz dostępny pod adresem
                    www.gnbbank.pl/kontakt, lub
                  </li>
                  <li>
                    mailowo na adres{" "}
                    <a href="mailto:infoserwis@gnbbank.pl">
                      infoserwis@gnbbank.pl
                    </a>
                    , lub
                  </li>
                  <li>
                    telefonicznie pod nr infolinii{" "}
                    <a href="tel:+48799 655 999" data-external-url="1">
                      +48 799 655 999
                    </a>{" "}
                    (koszt połączenia zgodny z taryfą operatora)
                  </li>

                  <p className="underline">
                    Aneks wyślemy do Ciebie pocztą na adres korespondencyjny.
                  </p>
                  <br></br>
                </ul>
              </li>
              <li>
                Po otrzymaniu Aneksu podpisz Aneks wraz z&nbsp;załącznikami
                nr&nbsp;1 i&nbsp;2 w&nbsp;wyznaczonych do tego miejscach. Aneks
                (w&nbsp;obu egzemplarzach) muszą podpisać wszyscy kredytobiorcy.
              </li>
              <br></br>
              <li>
                Odeślij podpisany jeden egzemplarz aneksu i&nbsp;załączników na
                adres: Getin Noble Bank S.A. w&nbsp;upadłości, Rondo
                Daszyńskiego&nbsp;2c, 00-843 Warszawa.
              </li>
            </ol>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Co się stanie jak nie podpiszesz aneksu?</h2>
            </header>
            <p>
              Do momentu, kiedy wskaźnik stosowany w&nbsp;Twojej umowie nie
              zostanie objęty określonym w&nbsp;aneksie zdarzeniem regulacyjnym,
              to&nbsp; nic się nie zmieni. Brak zawarcia aneksu może natomiast
              spowodować wątpliwości co do sposobu realizacji umowy
              i&nbsp;utrudnić jej dalsze niezakłócone wykonywanie
              w&nbsp;sytuacji zajścia zdarzenia regulacyjnego - dlatego zalecamy
              jego zawarcie.
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Od kiedy aneks będzie obowiązywał?</h2>
            </header>
            <p>
              Aneks będzie obowiązywał od dnia jego podpisania go przez
              wszystkich kredytobiorców i&nbsp;bank.
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                Kim jest administrator i&nbsp;jaka jest rola administratorów
                wskaźników referencyjnych?
              </h2>
            </header>
            <p>
              Administrator to podmiot, który sprawuje kontrolę nad
              opracowywaniem i&nbsp;publikowaniem danego wskaźnika
              referencyjnego.
            </p>
            <p>
              Lista administratorów dostępna jest w&nbsp;rejestrze
              administratorów wskaźników referencyjnych prowadzonym przez
              European Securities and Markets Authorities (ESMA). Więcej
              informacji możesz znaleźć na stronie:
              <a
                href="https://registers.esma.europa.eu/publication/searchRegister?core=esma_registers_bench_entities"
                className="gold"
              >
                {" "}
                https://registers.esma.europa.eu/publication/searchRegister?core=esma_registers_bench_entities
              </a>
            </p>
            <p>
              Rolą administratorów jest sprawowanie kontroli nad opracowywaniem
              danego wskaźnika referencyjnego w&nbsp;tym:
            </p>
            <ol className="numberList">
              <li>
                określenie i&nbsp;zarządzanie mechanizmami wyznaczania
                wskaźnika, zgodnie z&nbsp;obowiązującymi przepisami prawa,
              </li>
              <li>
                gromadzenie, analiza i&nbsp;przetwarzanie danych odpowiednio
                wysokiej jakości niezbędne do wyliczenia wskaźnika,
              </li>
              <li>
                wyznaczanie i&nbsp;publikacja wskaźnika (zgodnie z&nbsp;przyjętą
                przez siebie metodą i&nbsp;w&nbsp;oparciu o&nbsp;zebrane dane),
              </li>
              <li>
                weryfikacja przyjętych mechanizmów dla wskaźników, pod kątem:{" "}
                <br></br>a)&nbsp;&nbsp; &nbsp;adekwatności tej metody do
                zmierzenia rynku bazowego, <br></br>b)&nbsp;&nbsp;
                &nbsp;dostosowania metody do zmieniającej się rzeczywistości
                ekonomicznej, &nbsp;<br></br>c)&nbsp;&nbsp; &nbsp;zgodności
                z&nbsp;przepisami prawa, <br></br>d)&nbsp;&nbsp; &nbsp;wymogami
                organów nadzoru.
              </li>
            </ol>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Czym jest istotna zmiana wskaźnika?</h2>
            </header>
            <p>
              Istotna zmiana wskaźnika oznacza zmianę w&nbsp;metodzie
              wyznaczania wskaźnika opisaną i&nbsp;zastosowaną przez
              administratora danego wskaźnika, która jest dokonywana zgodnie
              z&nbsp;postanowieniami rozporządzenia BMR.
            </p>
            <p>
              Administrator ma obowiązek dobierać metodę opracowywania wskaźnika
              referencyjnego, tak aby była ona zgodna z&nbsp;przepisami prawa
              (rozporządzeniem BMR). W&nbsp;związku z&nbsp;tym administrator ma
              także obowiązek dokonywać przeglądu przyjętej metody opracowywania
              wskaźnika referencyjnego np. w&nbsp;sytuacji zmiany otoczenia
              prawno - finansowego. W&nbsp;rezultacie metodologia opracowywania
              wskaźnika referencyjnego może podlegać zmianom.<br></br>
              Administrator powiadamia o&nbsp;procesie istotnej zmiany wskaźnika
              oraz prowadzi w&nbsp;tym zakresie konsultacje.
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Czym jest zaprzestanie opracowywania wskaźnika?</h2>
            </header>
            <p>
              Zaprzestanie opracowania wskaźnika oznacza trwałe zaprzestanie
              opracowywania wskaźnika będące wynikiem wystąpienia jednego lub
              kilku z&nbsp;następujących zdarzeń:
            </p>
            <p>
              a)&nbsp;&nbsp; &nbsp;publicznego oświadczenia administratora
              stwierdzającego, że wskaźnik przestał lub przestanie być
              opracowywany na stałe
            </p>
            <p>
              b)&nbsp;&nbsp; &nbsp;sytuacja, w&nbsp;której administrator
              wskaźnika nie otrzymał lub nie otrzyma zezwolenia lub rejestracji
              do obliczania danego wskaźnika,&nbsp; albo
            </p>
            <p>
              c)&nbsp;&nbsp; &nbsp;sytuacja, w&nbsp;której organ nadzoru cofnął
              lub zawiesił admiratorowi zezwolenie lub rejestrację w&nbsp;skutek
              czego dalsze korzystanie&nbsp; z&nbsp;danego wskaźnika&nbsp;
              w&nbsp;celu wykonywania umowy nie będzie możliwe. &nbsp;
            </p>
            <p>
              Wskaźniki referencyjne mogą przestać być publikowane lub
              opracowywane w&nbsp;całości lub w&nbsp;odniesieniu do konkretnego
              terminu (tenoru) np. 6M - 6 miesięczny lub konkretnej waluty np.
              frank szwajcarski (CHF).
            </p>
            <p>
              Konsekwencją zaprzestania przez administratora opracowywania
              wskaźnika referencyjnego może być brak możliwości wykorzystania
              przez bank wskaźnika referencyjnego do ustalenia wartości
              wzajemnych świadczeń wynikających z&nbsp;zawartej umowy kredytu.
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                Dlaczego stopa składana SARON została wskazana jako zamiennik
                dla LIBOR CHF?
              </h2>
            </header>
            <p>
              Komisja Europejska uznała, że zaprzestanie opracowywania wskaźnika
              LIBOR CHF bez jednoczesnego wyznaczenia dla niego ustawowego
              zamiennika mogłoby znacząco zakłócić lub zwiększyć ryzyko
              funkcjonowania rynków i&nbsp;systemów finansowych w&nbsp;Unii
              Europejskiej.<br></br>Wyznaczając zamiennik dla LIBOR CHF&nbsp;
              Komisja Europejska wzięła pod uwagę zarówno rekomendacje Krajowej
              Grupy Roboczej ds. stóp referencyjnych franka szwajcarskiego
              (National Working Group on Swiss Franc reference rates), która
              zaleciła oparcie rozwiązania ustawowego o&nbsp;stopę składaną
              SARON i&nbsp;zastosowanie korekty wyrównawczej w&nbsp;celu
              zniwelowania ewentualnych różnic wynikających z&nbsp;zastąpienia
              nim wskaźnika LIBOR CHF, jak i&nbsp;opinie pozyskane w&nbsp;ramach
              przeprowadzonych publicznych konsultacji. <br></br>
              W&nbsp;rezultacie Komisja Europejska uznała stopę składaną SARON
              za najbardziej odpowiedni zamiennik dla LIBOR CHF,
              a&nbsp;w&nbsp;celu ograniczenia skutków ekonomicznych zmiany
              wskaźników Komisja Europejska zdecydowała zastosować korektę
              wyrównawczą (spread korygujący).
            </p>
            <p>
              <span className="bold">
                Wprowadzenie zamiennika dla LIBOR CHF oznacza więc, że umowy
                o&nbsp;kredyt indeksowane do CHF nadal mogą być bez przeszkód
                wykonywane, z&nbsp;wykorzystaniem stopy składanej SARON oraz
                korekty wyrównawczej, co gwarantuje pewność prawną zarówno dla
                posiadaczy kredytów, jak i&nbsp;dla banku.
              </span>
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                W&nbsp;jakich okolicznościach powinienem podpisać i&nbsp;odesłać
                oświadczenie o&nbsp;odstąpieniu od aneksu?
              </h2>
            </header>
            <p>
              Odstąpienie od aneksu przysługuje w&nbsp;przypadkach,
              w&nbsp;których bank dopuszcza zawarcie aneksu na odległość tzn.
              drogą korespondencyjną.
            </p>
            <p>
              Jeżeli się rozmyśliłeś i&nbsp;chcesz się wycofać z&nbsp;wcześniej
              podjętej decyzji o&nbsp;zawarciu aneksu, musisz złożyć do banku
              oświadczenie o&nbsp;odstąpieniu. Wzór takiego oświadczenia
              otrzymałeś wraz z&nbsp;propozycją zawarcia aneksu. <br></br>
              Podpisane oświadczenie o&nbsp;odstąpieniu od aneksu należy
              przesłać do banku tylko w&nbsp;sytuacji, w&nbsp;której obie strony
              podpisały aneks czyli&nbsp; Ty i&nbsp;bank. Masz na to 14 dni od
              momentu jego zawarcia.
            </p>
            <p>
              Jeżeli jednak od samego początku nie decydujesz się na
              zawarcie&nbsp; aneksu, to nie musisz odsyłać do banku żadnego
              oświadczenia. Bez Twojego podpisu aneks nie wejdzie w&nbsp;życie.
            </p>
          </div>
        </section>
      </div>
    </main>
  );
}

export default BmrFaq;
