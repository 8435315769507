import React from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const alertNews001 = (
    <li className="alertCard one">
      <div className="cardText">
        <p>
          Szanowni Państwo,
          <br></br>
          <br></br>
          w&nbsp;ślad za komunikatem, który wyświetla się po zalogowaniu do
          portalu Krajowego Rejestru Zadłużonych informujemy, że ze względu na
          duże zainteresowanie zgłaszaniem wierzytelności wobec Getin Noble Bank
          &nbsp;S.A. w&nbsp;Warszawie występują problemy wydajnościowe.
          Aktualnie prowadzone są w&nbsp;portalu Krajowego Rejestru Zadłużonych
          prace mające na celu poprawienie wydajności Systemu. Zachęcamy do
          śledzenia komunikatów zamieszczanych w&nbsp;portalu Krajowego Rejestru
          Zadłużonych.
          <br></br>
          <br></br>
          Aktualizacja: syndyk masy upadłości jest w&nbsp;ciągłym kontakcie
          z&nbsp;administratorami portalu Krajowego Rejestru Zadłużonych, prace
          mające na celu poprawienie wydajności Systemu są w&nbsp;toku, widoczne
          efekty usprawnienia portalu mają być zauważalne w&nbsp;dniu
          17.08.2023&nbsp;&nbsp;r., w&nbsp;godzinach wieczornych; jednocześnie
          zastrzega się, że System działa wolniej niż zwykle, natomiast wciąż
          napływają do syndyka masy upadłości kolejne zgłoszenia wierzytelności.
        </p>
      </div>
      <p className="cardDate">Opublikowano 17.08.2023&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews001} />
    </>
  );
}

export default AlertCardBankruptcy;
