import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../css/RestructuringForm.css";

function RestructuringFormBasic() {
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  function handleCheckboxChange(e) {
    const hiddenInput = document.getElementById("changeTermsHiddenInput");
    hiddenInput.value = e.target.checked ? "Zaznaczono checkbox" : "";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    const fileInput = formRef.current.querySelector('input[type="file"]');
    if (fileInput && fileInput.files.length > 0) {
      formData.append("attachments", fileInput.files[0]);
    }

    try {
      const response = await fetch(
        "https://gnbbank.pl/send-restructuring-basic-email",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setErrors({
          form:
            errorData.error ||
            "Wystąpił nieznany błąd podczas wysyłania formularza. Spróbuj później.",
        });
      } else {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors({
        form: "Wystąpił nieznany błąd podczas wysyłania formularza. Spróbuj później.",
      });
    }
    const formValues = {};
    formData.forEach((value, key) => {
      formValues[key] = value;
    });
  };

  if (isSubmitted) {
    return (
      <main className="submittedMsg">
        <div className="container">
          <div className="text">
            <p>Dziękujemy za przesłaną wiadomość.</p>
            <p>
              Odpowiedzi mailowej udzielimy na adres zarejestrowany do kontaktu
              z&nbsp;Bankiem.
            </p>
          </div>
          <Link className="btnGold" to="/">
            Powrót do strony głównej
          </Link>
        </div>
      </main>
    );
  }

  return (
    <div className="mainForm">
      <div id="restructuringForm">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <legend>
            <h3 className="additionalMargin">
              Wniosek o&nbsp;restrukturyzację zadłużenia / zawarcie ugody
            </h3>
          </legend>

          <div className="row ">
            <div className="columns large-6 medium-12">
              <label>Numer umowy *</label>
              <input
                name="contractNumber"
                maxLength="100"
                placeholder="Wpisz pełny numer umowy kredytu"
                type="text"
                required
              />
            </div>

            <div className="form-checkboxes">
              <div className="columns large-12">
                <label>Zmiana warunków spłaty poprzez:</label>
                <ul type="checkbox" className="no-bullet checkbox">
                  <li>
                    <label className="checkbox-bold">
                      <input
                        type="checkbox"
                        name="changeTerms1"
                        value="Zaznaczono checkbox"
                        onChange={handleCheckboxChange}
                      ></input>
                      <span>Kapitalizację zaległości</span>
                    </label>
                    <input
                      type="hidden"
                      name="changeTerms1"
                      value=" " // Domyślna wartość, jeśli checkbox nie jest zaznaczony
                    ></input>
                  </li>
                  <li>
                    <label className="checkbox-bold">
                      <input
                        type="checkbox"
                        name="changeTerms2"
                        value="Zaznaczono checkbox"
                        onChange={handleCheckboxChange}
                      ></input>
                      <span>Zawarcie Ugody</span>
                    </label>
                    <input type="hidden" name="changeTerms2" value=""></input>
                  </li>
                  <li>
                    <label className="checkbox-bold">
                      <input
                        type="checkbox"
                        name="changeTerms3"
                        value="Zaznaczono checkbox"
                        onChange={handleCheckboxChange}
                      ></input>
                      <span>Wydłużenie okresu kredytowania</span>
                    </label>
                    <input type="hidden" name="changeTerms3" value=""></input>
                  </li>
                  <li>
                    <label className="checkbox-bold">
                      <input
                        type="checkbox"
                        name="changeTerms4"
                        value="Zaznaczono checkbox"
                        onChange={handleCheckboxChange}
                      ></input>
                      <span>Karencję w&nbsp;spłacie kapitału</span>
                    </label>
                    <input
                      type="hidden"
                      name="changeTerms4"
                      value=""
                      id="changeTermsHiddenInput"
                    ></input>
                  </li>
                </ul>
              </div>
            </div>

            <div className="columns large-12 medium-12">
              <label>
                Jeśli wybrałeś <i>Karencję w&nbsp;spłacie kapitału</i> i/lub{" "}
                <i>Wydłużenie okresu kredytowania</i>, wskaż w&nbsp;poniższym
                polu okres (w&nbsp;miesiącach), na jaki chcesz wnioskować.
              </label>
              <input name="requestedPeriod" maxLength="100" type="text" />
            </div>
          </div>
          <div className="form-checkboxes">
            <div className="columns large-12">
              <ul className="no-bullet checkbox">
                <li>
                  <label className="checkbox-bold">
                    <input
                      type="checkbox"
                      name="changeTerms5"
                      value="Zaznaczono checkbox"
                      onChange={handleCheckboxChange}
                    ></input>
                    <span>Ustalenie indywidualnego harmonogramu spłat</span>
                  </label>
                  <input type="hidden" name="changeTerms5" value=""></input>
                </li>
              </ul>
            </div>
          </div>

          <div className="columns large-12 medium-12">
            <label>
              Jeśli wybrałeś <i>Ustalenie indywidualnego harmonogramu spłat</i>,
              wskaż w poniższym polu okres (w miesiącach), na jaki chcesz
              wnioskować oraz sugerowaną wysokość raty.
            </label>
            <input
              name="repaymentScheduleDetails"
              maxLength="100"
              type="text"
            ></input>
          </div>

          <div className="form-checkboxes">
            <div className="columns large-12">
              <ul className="no-bullet checkbox">
                <li>
                  <label className="checkbox-bold">
                    <input
                      type="checkbox"
                      name="changeTerms6"
                      value="Zaznaczono checkbox"
                      onChange={handleCheckboxChange}
                    ></input>
                    <span>Zmianę w zakresie zabezpieczeń</span>
                  </label>
                  <input type="hidden" name="changeTerms6" value=""></input>
                </li>
              </ul>
            </div>
          </div>

          <div className="columns large-12 medium-12">
            <label>
              Jeśli wybrałeś <i>Zmianę w zakresie zabezpieczeń</i>, wskaż w
              poniższym polu, na czym ma ona polegać.
            </label>
            <input
              name="securityChangeDetails"
              maxLength="100"
              type="text"
            ></input>
          </div>

          <div className="form-checkboxes">
            <div className="columns large-12">
              <ul className="no-bullet checkbox">
                <li>
                  <label className="checkbox-bold">
                    <input
                      type="checkbox"
                      name="changeTerms7"
                      value="Zaznaczono checkbox"
                      onChange={handleCheckboxChange}
                    ></input>
                    <span>Inne</span>
                  </label>
                  <input type="hidden" name="changeTerms7" value=""></input>
                </li>
              </ul>
            </div>
          </div>

          <div className="columns large-12 medium-12">
            <label>
              Jeśli wybrałeś <i>Inne</i>, wskaż w poniższym polu szczegóły
              zmiany warunków, o które wnioskujesz.
            </label>
            <input
              name="otherChangeDetails"
              maxLength="100"
              type="text"
            ></input>
          </div>

          <div className="columns form-text">
            <label>
              Okoliczności, które spowodowały trudności w spłacie kredytu
            </label>
            <textarea
              name="circumstancesCausingDifficulties"
              maxLength="2000"
              placeholder="Uzupełnij"
              rows="2"
            ></textarea>
          </div>

          <div className="wrapper">
            <div className="columns large-6 medium-12">
              <label>Ilość kredytobiorców *</label>
              <input
                name="numberOfBorrowers"
                minLength="1"
                maxLength="2"
                placeholder="Wpisz pełną liczbę kredytobiorców"
                type="number"
                required
              ></input>
            </div>

            <div className="columns large-6 medium-12">
              <label>Ilość poręczycieli *</label>
              <input
                name="numberOfGuarantors"
                minLength="1"
                maxLength="2"
                placeholder="Wpisz pełną liczbę poręczycieli"
                type="number"
                required
              ></input>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">Dane głównego klienta</h3>
            </legend>
            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Nazwisko *</label>
                <input
                  name="surname"
                  minLength="2"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Imię *</label>
                <input
                  name="firstName"
                  minLength="2"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Pesel *</label>
                <input
                  name="pesel"
                  minLength="11"
                  maxLength="11"
                  placeholder="Wpisz"
                  type="number"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Seria i&nbsp;numer dowodu osobistego *</label>
                <input
                  name="idCardNumber"
                  minLength="8"
                  maxLength="10"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Adres zameldowania *</label>
                <input
                  name="registrationAddress"
                  maxLength="100"
                  type="text"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Adres korespondencyjny *</label>
                <input
                  name="correspondenceAddress"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Telefon kontaktowy *</label>
                <input
                  name="contactPhone"
                  minLength="9"
                  maxLength="9"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Adres email</label>
                <input
                  name="emailAddress"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="email"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Ilość osób w&nbsp;gospodarstwie domowym *</label>
                <input
                  name="householdMembers"
                  minLength="1"
                  maxLength="2"
                  placeholder="Wpisz"
                  type="number"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Ilość osób w&nbsp;gospodarstwie domowym na utrzymaniu
                  kredytobiorcy *
                </label>
                <input
                  name="dependents"
                  minLength="1"
                  maxLength="2"
                  type="number"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Stan cywilny *</label>
                <select name="maritalStatus" required>
                  <option value="Zamężna/żonaty">Zamężna/żonaty</option>
                  <option value="Panna/kawaler">Panna/kawaler</option>
                  <option value="Wdowa/wdowiec">Wdowa/wdowiec</option>
                </select>

                <p>
                  W przypadku zaznaczenia stanu cywilnego -{" "}
                  <i>Zamężna/żonaty</i> wybierz poniżej właściwy status
                  majątkowy
                </p>
              </div>

              <div className="columns large-6 medium-12 form-text">
                <label>Status majątkowy</label>
                <select name="propertyStatus">
                  <option selected value="Nie wybrano">
                    Wybierz
                  </option>
                  <option value="Wspólnota majątkowa">
                    Wspólnota majątkowa
                  </option>
                  <option value="Rozdzielność majątkowa">
                    Rozdzielność majątkowa
                  </option>
                </select>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Miesięczne koszty utrzymania (z&nbsp;pominięciem wydatków
                  związanych z&nbsp;obsługą kredytów) *
                </label>
                <input
                  name="monthlyLivingCosts"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">
                Źródło dochodów Klienta na dzień składania wniosku
                o&nbsp;restrukturyzację
              </h3>
            </legend>
            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Dochód podstawowy *</label>
                <select name="primaryIncomeSource" required>
                  <option value="Umowa o pracę">Umowa o pracę</option>
                  <option value="Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne">
                    Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne
                  </option>
                  <option value="Renta inwalidzka/Renta po zmarłym współmałżonku/Renta resortowa">
                    Renta inwalidzka/Renta po zmarłym współmałżonku/Renta
                    resortowa
                  </option>
                  <option value="Renta wyrównawcza">Renta wyrównawcza</option>
                  <option value="Kontrakt (np. menedżerski, wojskowy)">
                    Kontrakt (np. menedżerski, wojskowy)
                  </option>
                  <option value="Kontrakt – marynarze">
                    Kontrakt – marynarze
                  </option>
                  <option value="Prowadzenie gospodarstwa rolnego">
                    Prowadzenie gospodarstwa rolnego
                  </option>
                  <option value="Wolne zawody (lekarze, prawnicy, etc.)">
                    Wolne zawody (lekarze, prawnicy, etc.)
                  </option>
                  <option value="Umowa najmu lokalu">Umowa najmu lokalu</option>
                  <option value="Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)">
                    Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)
                  </option>
                  <option value="Dochód z tytułu dywidendy i udziału w zysku">
                    Dochód z tytułu dywidendy i udziału w zysku
                  </option>
                  <option value="Radni">Radni</option>
                  <option value="Posłowie">Posłowie</option>
                  <option value="Dochody zagraniczne">
                    Dochody zagraniczne
                  </option>
                  <option value="Zasiłek macierzyński">
                    Zasiłek macierzyński
                  </option>
                  <option value="Brak">Brak</option>
                </select>
              </div>
              <div className="columns large-6 medium-12">
                <label>
                  Średni miesięczny dochód netto z&nbsp;ostatnich 3 miesięcy
                  wynosi: *
                </label>
                <input
                  name="averageNetIncomeLast3Months"
                  maxLength="100"
                  type="text"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Dochód dodatkowy nr&nbsp;1</label>
                <select name="additionalIncomeSource1">
                  <option value="Umowa o pracę">Umowa o&nbsp;pracę</option>
                  <option value="Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne">
                    Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne
                  </option>
                  <option value="Renta inwalidzka/Renta po zmarłym współmałżonku/Renta resortowa">
                    Renta inwalidzka/Renta po zmarłym współmałżonku/Renta
                    resortowa
                  </option>
                  <option value="Renta wyrównawcza">Renta wyrównawcza</option>
                  <option value="Kontrakt (np. menedżerski, wojskowy)">
                    Kontrakt (np. menedżerski, wojskowy)
                  </option>
                  <option value="Kontrakt – marynarze">
                    Kontrakt – marynarze
                  </option>
                  <option value="farProwadzenie gospodarstwa rolnegoing">
                    Prowadzenie gospodarstwa rolnego
                  </option>
                  <option value="Wolne zawody (lekarze, prawnicy, etc.)">
                    Wolne zawody (lekarze, prawnicy, etc.)
                  </option>
                  <option value="Umowa najmu lokalu">Umowa najmu lokalu</option>
                  <option value="Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)">
                    Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)
                  </option>
                  <option value="Dochód z tytułu dywidendy i udziału w zysku w Sp. z o.o. lub akcyjnej">
                    Dochód z tytułu dywidendy i&nbsp;udziału w&nbsp;zysku
                    w&nbsp;Sp. z&nbsp;o.o. lub akcyjnej
                  </option>
                  <option value="Radni">Radni</option>
                  <option value="Posłowie">Posłowie</option>
                  <option value="Dochody zagraniczne">
                    Dochody zagraniczne
                  </option>
                  <option value="Zasiłek macierzyński">
                    Zasiłek macierzyński
                  </option>
                  <option value="Brak">Brak</option>
                </select>
              </div>
              <div className="columns large-6 medium-12">
                <label>
                  Średni miesięczny dochód netto z&nbsp;ostatnich 3 miesięcy
                  wynosi:
                </label>
                <input
                  name="additionalIncome1Amount"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Dochód dodatkowy nr&nbsp;2</label>

                <select name="additionalIncomeSource2">
                  <option selected value="Nie wybrano">
                    Wybierz
                  </option>
                  <option value="Umowa o pracę">Umowa o pracę</option>
                  <option value="Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne">
                    Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne
                  </option>
                  <option value="Renta inwalidzka/Renta po zmarłym współmałżonku/Renta resortowa">
                    Renta inwalidzka/Renta po zmarłym współmałżonku/Renta
                    resortowa
                  </option>
                  <option value="Renta wyrównawcza">Renta wyrównawcza</option>
                  <option value="Kontrakt (np. menedżerski, wojskowy)">
                    Kontrakt (np. menedżerski, wojskowy)
                  </option>
                  <option value="Kontrakt – marynarze">
                    Kontrakt – marynarze
                  </option>
                  <option value="Prowadzenie gospodarstwa rolnego">
                    Prowadzenie gospodarstwa rolnego
                  </option>
                  <option value="Wolne zawody (lekarze wszystkich specjalności, prawnicy, biegli rewidenci, architekci, rzecznicy patentowi, tłumacze przysięgli, doradcy podatkowi, doradcy inwestycyjni, rzeczoznawcy majątkowi, księgowi)">
                    Wolne zawody (lekarze wszystkich specjalności, prawnicy,
                    biegli rewidenci, architekci, rzecznicy patentowi, tłumacze
                    przysięgli, doradcy podatkowi, doradcy inwestycyjni,
                    rzeczoznawcy majątkowi, księgowi)
                  </option>
                  <option value="Umowa najmu lokalu">Umowa najmu lokalu</option>
                  <option value="Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)">
                    Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)
                  </option>
                  <option value="Dochód z tytułu dywidendy i udziału w zysku w Sp. z o.o. lub akcyjnej">
                    Dochód z tytułu dywidendy i&nbsp;udziału w&nbsp;zysku w
                    Sp.&nbsp;z o.o. lub akcyjnej
                  </option>
                  <option value="Radni">Radni</option>
                  <option value="Posłowie">Posłowie</option>
                  <option value="Dochody zagraniczne">
                    Dochody zagraniczne
                  </option>
                  <option value="Świadczenie w ramach programu Rodzina 500 plus">
                    Świadczenie w ramach programu Rodzina 500 plus
                  </option>
                  <option value="Zasiłek macierzyński">
                    Zasiłek macierzyński
                  </option>
                  <option value="Alimenty">Alimenty</option>
                  <option value="Brak">Brak</option>
                </select>
              </div>

              <div className="columns large-6 medium-6">
                <label>
                  Średni miesięczny dochód netto z&nbsp;ostatnich 3 miesięcy
                  wynosi:
                </label>
                <input
                  name="additionalIncome2Amount"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns large-12 medium-12 form-text">
              <label>Dochód z działalności gospodarczej</label>
              <select name="businessIncomeType">
                <option selected value="Nie wybrano">
                  Wybierz
                </option>
                <option value="Pełna księgowość (Bilans oraz Rachunek Zysków i Straty)">
                  Pełna księgowość (Bilans oraz Rachunek Zysków i Straty)
                </option>
                <option value="Książka Przychodów i Rozchodów (KPiR)">
                  Książka Przychodów i Rozchodów (KPiR)
                </option>
                <option value="Ryczałt">Ryczałt</option>
                <option value="Karta podatkowa">Karta podatkowa</option>
                <option value="Dochód z działalności gospodarczej na podstawie pełnej księgowości">
                  Dochód z działalności gospodarczej na podstawie pełnej
                  księgowości
                </option>
              </select>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Nazwa podmiotu</label>
                <input name="entityName" maxLength="100" type="text"></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Branża</label>
                <input
                  name="industry"
                  minLength="1"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Przychód i dochód (brutto) za ostatni rok obrachunkowy wg
                  stanu na dzień:
                </label>
                <input
                  name="lastYearRevenueAndIncome"
                  className="datepicker"
                  maxLength="100"
                  type="date"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Przychód i dochód (brutto) za bieżący okres (narastająco) wg
                  stanu na dzień:
                </label>
                <input
                  name="currentPeriodRevenueAndIncome"
                  className="datepicker"
                  maxLength="100"
                  type="date"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Wysokość przychodu za ostatni rok obrachunkowy:</label>
                <input
                  name="lastYearRevenue"
                  maxLength="100"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Wysokość przychodu za bieżący okres (narastająco):
                </label>
                <input
                  name="currentPeriodRevenue"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Wysokość dochodu brutto za ostatni rok obrachunkowy:
                </label>
                <input
                  name="lastYearGrossIncome"
                  maxLength="100"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Wysokość dochodu brutto za bieżący okres:</label>
                <input
                  name="currentPeriodGrossIncome"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="form-checkboxes">
              <div className="columns large-12">
                <ul className="no-bullet checkbox">
                  <li>
                    <label className="checkbox-bold">
                      <input
                        type="checkbox"
                        name="changeTerms8"
                        value="Zaznaczono checkbox"
                      ></input>
                      <span>Brak dochodu</span>
                    </label>
                    <input type="hidden" name="changeTerms8" value=""></input>
                  </li>
                </ul>
              </div>
            </div>

            <div className="columns">
              <p className="help-text">
                <a
                  className="gold"
                  href="https://www.gnbbank.pl/dokumenty/restrukturyzacja-zestawienie-dokumentow-potwierdzajacych-dochod.pdf"
                  target="_blank"
                >
                  Sprawdź listę dokumentów, niezbędnych do potwierdzenia
                  wskazanego dochodu
                </a>
              </p>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">Posiadany majątek</h3>
            </legend>
            <div className="columns">
              <p className="bold">Nieruchomości</p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="columns large-12 medium-12">
              <label>
                Opis (rodzaj, położenie, powierzchnia, nr Księgi Wieczystej)
              </label>
              <input
                name="realEstateDescription"
                maxLength="100"
                type="text"
              ></input>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Szacunkowa wartość w PLN</label>
                <input
                  name="estimatedValuePLN"
                  maxLength="100"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Obciążenia w PLN</label>
                <input
                  name="liabilitiesPLN"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Udział we własności/inni współwłaściciele</label>
                <input
                  name="ownershipShare"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">Ruchomości</p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="columns large-12 medium-12">
              <label>
                Dokładny opis (cechy pozwalające na jednoznaczną identyfikację
                przedmiotów np. rodzaj, marka, typ, model)
              </label>
              <input
                name="movablePropertyDescription"
                maxLength="100"
                type="text"
              ></input>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Rok produkcji</label>
                <input
                  name="productionYear"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Szacunkowa wartość w PLN</label>
                <input
                  name="estimatedValuePLNMovable"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Obciążenia</label>
                <input
                  name="liabilitiesMovable"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Udział we własności/inni współwłaściciele</label>
                <input
                  name="ownershipShareMovable"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">
                Środki pieniężne i inne produkty inwestycyjne
              </p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Środki pieniężne na rachunkach bankowych i lokatach
                  terminowych (dokładny opis, ilość, wartość nominalna,
                  obciążenia)
                </label>
                <input
                  name="bankAccountsAndDeposits"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Fundusze inwestycyjne (dokładny opis, ilość, wartość
                  nominalna, obciążenia)
                </label>
                <input
                  name="investmentFunds"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Akcje (dokładny opis, ilość, wartość nominalna, obciążenia)
                </label>
                <input
                  name="stocksDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Obligacje (dokładny opis, ilość, wartość nominalna,
                  obciążenia)
                </label>
                <input
                  name="bondsDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Inne (dokładny opis, ilość, wartość nominalna, obciążenia)
                </label>
                <input
                  name="otherInvestmentsDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">Posiadane zobowiązania</h3>
            </legend>
            <div className="columns">
              <p className="bold">
                Zobowiązania z&nbsp;tytułu zaciągniętych kredytów
                i&nbsp;pożyczek
              </p>

              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kredytodawca/ Pożyczkodawca</label>
                <input
                  name="creditor"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Kwota do spłaty (tys. PLN)</label>
                <input
                  name="repaymentAmountPLN"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Waluta zobowiązania</label>
                <input
                  name="debtCurrency"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Miesięczna wysokość raty (z podziałem na kapitał i odsetki)
                </label>
                <input
                  name="monthlyInstallmentDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Zabezpieczenia</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="securities"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Ostateczny termin spłaty</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="finalRepaymentDate"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota zobowiązań przeterminowanych</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="overdueLiabilitiesAmount"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">
                Zobowiązania z&nbsp;tytułu poręczeń i/lub gwarancji
              </p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota poręczenia/gwarancji</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="guaranteeAmount"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Waluta zobowiązania</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="liabilityCurrency"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Dłużnik</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="debtor"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Termin ważności</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="validityTerm"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota zobowiązań przeterminowanych</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="overdueLiabilitiesCost"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">
                Zobowiązania z&nbsp;tytułu zawartych umów leasingowych lub
                faktoringowych
              </p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Tytuł zobowiązania (wierzyciele)</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="debtTitle"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Kwota do spłaty</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="repaymentAmount"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Waluta zobowiązania</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="debtCurrency2"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Miesięczna wysokość raty</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="monthlyRate"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Przedmiot (w przypadku leasingu)</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="leasingSubject"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Termin zakończenia</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="terminationDate"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota zobowiązań przeterminowanych</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="overdueLiabilitiesAmount2"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">Zobowiązania pozostałe</p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Z&nbsp;tytułu podatków oraz innych opłat, do których stosuje
                  się przepisy ustawy z&nbsp;dnia 29 sierpnia 1997 r. Ordynacja
                  Podatkowa (Dz.U.2012.749 wraz z&nbsp;późniejszymi zmianami),
                  w&nbsp;tym przeterminowane
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz obie wartości"
                  type="text"
                  name="taxLiabilities"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Z&nbsp;tytułu zobowiązań wobec ZUS, w&nbsp;tym przeterminowane
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz obie wartości"
                  type="text"
                  name="zusLiabilities"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Orzeczone wyrokiem lub nakazem sądowym, wynikających
                  z&nbsp;decyzji administracyjnych lub ustalonych w&nbsp;ugodzie
                  sądowej, bądź ugodzie bankowej
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="courtOrderedLiabilities"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Będących przedmiotem egzekucji sądowej lub administracyjnej
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="executionSubjectLiabilities"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Z&nbsp;innych tytułów</label>
                <input
                  maxLength="100"
                  placeholder="Wpisz sumę"
                  type="text"
                  name="otherTitlesAmount"
                ></input>
              </div>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">Oświadczenia</h3>
            </legend>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Oświadczam, że nie posiadam / posiadam zaległości wobec ZUS
                  w&nbsp;kwocie:
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz wartość w PLN"
                  type="text"
                  name="zusArrearsDeclaration"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Oświadczam, że nie posiadam / posiadam zaległości wobec US
                  w&nbsp;kwocie:
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz wartość w PLN"
                  type="text"
                  name="taxOfficeArrearsDeclaration"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Oświadczam, że przeciwko mnie nie jest / jest prowadzone
                  postępowanie egzekucyjne: sygn. akt KM , kwota dochodzonej
                  wierzytelności
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz TAK/NIE i uzupełnij sygnaturę oraz kwotę"
                  type="text"
                  name="enforcementProceedingsDeclaration"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Oświadczam, że jestem/ nie jestem podmiotem postępowania
                  upadłościowego. Jeśli tak, proszę o&nbsp;wskazanie tytułu
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="bankruptcyProceedingsDeclaration"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Oświadczam, że jestem świadomy, iż złożenie wniosku
                  o&nbsp;restrukturyzację, nie zwalnia mnie z&nbsp;obowiązku
                  regulowania rat zgodnych z&nbsp;aktualnym harmonogramem
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="restructuringAwareness"
                ></input>
              </div>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">
                Program poprawy sytuacji ekonomicznej
              </h3>
            </legend>
            <div className="columns">
              <p className="help-text">
                Podlega obowiązkowemu wypełnieniu, w&nbsp;przypadku, gdy źródłem
                dochodu jest działalność gospodarcza.
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Nazwa pod jaką prowadzona jest działalność gospodarcza
                </label>
                <input
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  name="businessName"
                ></input>
              </div>
            </div>

            <div className="columns form-text">
              <label>Krótka historia działalności</label>
              <textarea
                maxLength="2000"
                placeholder="Opisz"
                rows="2"
                name="businessHistory"
              ></textarea>
            </div>

            <div className="columns form-text">
              <label>
                Opis bieżących problemów oraz przyczyn powstania zaległości
                w&nbsp;obsłudze zobowiązań
              </label>
              <textarea
                maxLength="2000"
                placeholder="Opisz"
                rows="2"
                name="currentProblems"
              ></textarea>
            </div>

            <div className="columns form-text">
              <label>
                Działania naprawcze i&nbsp;ich wpływ na poprawę sytuacji firmy
              </label>
              <textarea
                maxLength="2000"
                placeholder="Opisz"
                rows="2"
                name="correctiveActions"
              ></textarea>
            </div>

            <div className="columns">
              <p className="help-text">
                <a
                  className="gold"
                  href="https://www.gnbbank.pl/dokumenty/restrukturyzacja-prognoza-finansowa.docx"
                  target="_blank"
                >
                  Prognoza finansowa - pobierz dokument
                </a>
                <br></br>Wydrukuj, wypełnij i&nbsp;załącz w&nbsp;formie skanu
                w&nbsp;poniższym polu
              </p>
            </div>

            <div className="columns large-6 medium-12">
              <label>Załącznik</label>
              <div className="type-file">
                <div className="upload-label"></div>

                <input
                  accept="image/png, image/jpeg, application/msword, application/pdf"
                  className="fileUpload"
                  type="file"
                  placeholder="Wczytaj plik z dysku"
                ></input>
              </div>
              {errors.form ? (
                <p
                  style={{
                    fontSize: "1.5rem",
                    color: "red",
                    marginTop: "-1.5rem",
                  }}
                >
                  {errors.form}
                </p>
              ) : (
                <p style={{ fontSize: "1.5rem", marginTop: "-1.5rem" }}>
                  Akceptowany format pliku to PNG, JPEG, PDF oraz DOC.
                  Maksymalny rozmiar to 5&nbsp;MB.
                </p>
              )}
            </div>
          </div>

          <div className="columns">
            <p style={{ marginBottom: "6rem" }} className="help-text">
              Bank, po otrzymaniu wniosku, zweryfikuje go i&nbsp;może
              skontaktować się z&nbsp;Tobą w&nbsp;celu poinformowania
              o&nbsp;potrzebie dostarczenia dodatkowych dokumentów i/lub
              informacji.
            </p>
          </div>

          <button type="submit" className="btnGold">
            Wyślij wniosek
          </button>
        </form>
      </div>
    </div>
  );
}

export default RestructuringFormBasic;
