import React from "react";
import AlertCard from "../components/AlertCard";
import "../css/AlertCard.css";
import { useScrollReveal } from "../hooks/useScrollReveal";

import AlertNews001 from "../components/AlertsAndNews/AlertNews001";
import AlertNews002 from "../components/AlertsAndNews/AlertNews002";
import AlertNews003 from "../components/AlertsAndNews/AlertNews003";
import AlertNews004 from "../components/AlertsAndNews/AlertNews004";
import AlertNews005 from "../components/AlertsAndNews/AlertNews005";
import AlertNews006 from "../components/AlertsAndNews/AlertNews006";
import AlertNews007 from "../components/AlertsAndNews/AlertNews007";
import AlertNews008 from "../components/AlertsAndNews/AlertNews008";

// const cardContent001 = (
//   <li className="alertCard one">
//     <div className="cardText">
//       <p>
//         Szanowni Państwo,
//         <br></br>
//         <br></br>
//         w&nbsp;ślad za komunikatem, który wyświetla się po zalogowaniu do
//         portalu Krajowego Rejestru Zadłużonych informujemy, że ze względu na
//         duże zainteresowanie zgłaszaniem wierzytelności wobec Getin Noble Bank
//         &nbsp;S.A. w&nbsp;Warszawie występują problemy wydajnościowe. Aktualnie
//         prowadzone są w&nbsp;portalu Krajowego Rejestru Zadłużonych prace mające
//         na celu poprawienie wydajności Systemu. Zachęcamy do śledzenia
//         komunikatów zamieszczanych w&nbsp;portalu Krajowego Rejestru
//         Zadłużonych.
//         <br></br>
//         <br></br>
//         Aktualizacja: syndyk masy upadłości jest w&nbsp;ciągłym kontakcie
//         z&nbsp;administratorami portalu Krajowego Rejestru Zadłużonych, prace
//         mające na celu poprawienie wydajności Systemu są w&nbsp;toku, widoczne
//         efekty usprawnienia portalu mają być zauważalne w&nbsp;dniu
//         17.08.2023&nbsp;&nbsp;r., w&nbsp;godzinach wieczornych; jednocześnie
//         zastrzega się, że System działa wolniej niż zwykle, natomiast wciąż
//         napływają do syndyka masy upadłości kolejne zgłoszenia wierzytelności.
//       </p>
//     </div>
//     <p className="cardDate">Opublikowano 17.08.2023</p>
//   </li>
// );

// const cardContent002 = (
//   <li className="alertCard two">
//     <div className="cardText">
//       <p>
//         Szanowni Państwo,
//         <br></br>
//         <br></br>
//         syndyk masy upadłości Getin Noble Bank&nbsp;S.A. informuje, że do
//         godziny 21:00 dnia 21.08.2023&nbsp;r., tj. na&nbsp;3 godziny przed
//         zakończeniem 30&nbsp;&nbsp;dniowego [instrukcyjnego terminu] na
//         zgłoszenie wierzytelności do akt zgłoszeń wierzytelności wpłynęło ponad
//         30&nbsp;100 dokumentów, co spowodowało założenie przez Rejestr
//         25&nbsp;860 teczek wierzytelności, dziękujemy wierzycielom, jak również
//         pełnomocnikom wierzycieli za dokonanie zgłoszeń wierzytelności,
//         jednocześnie syndyk masy upadłości wyraża przekonanie, że pomimo tego,
//         iż Rejestr działał momentami wolniej niż zazwyczaj to spełnił
//         oczekiwania użytkowników.<br></br>
//         <br></br>
//         Marcin Kubiczek - syndyk masy upadłości<br></br>
//         Kwalifikowany doradca restrukturyzacyjny, licencja nr&nbsp;244
//       </p>
//     </div>
//     <p className="cardDate">Opublikowano 21.08.2023</p>
//   </li>
// );

// const cardContent003 = (
//   <li className="alertCard three">
//     <div className="cardText">
//       <p>
//         Syndyk masy upadłości Getin Noble Bank&nbsp;S.A. w&nbsp;upadłości
//         informuje, że wpłaty zryczałtowanych kosztów postępowania upadłościowego
//         wynikających z&nbsp;dokonania zgłoszenia wierzytelności po terminie,
//         o&nbsp;których mowa w&nbsp;art. 235 ust. 1&nbsp;ustawy Prawo
//         upadłościowe należy dokonywać na rachunek bankowy o&nbsp;numerze: 72
//         8436 0003 0000 0026 7436 0007.
//       </p>
//       <p>
//         <span className="bold underline">
//           Celem usprawnienia procesu podaje się dane do wykorzystania przy
//           wykonywaniu przelewu:
//         </span>
//       </p>
//       <p>
//         <span className="bold">ODBIORCA:</span> MARCIN KUBICZEK SYNDYK MASY
//         UPADŁOŚCI GETIN NOBLE BANK&nbsp;S.A. W&nbsp;UPADŁOŚCI
//       </p>
//       <p>
//         <span className="bold">NUMER KONTA ODBIORCY:</span> 72 8436 0003 0000
//         0026 7436 0007
//       </p>
//       <p>
//         <span className="bold">TYTUŁ:</span> ZRYCZAŁTOWANY KOSZT POSTĘPOWANIA
//         WA1M/GUp-Zw/44/2023 [jeżeli przelewu dokonuje pełnomocnik wierzyciela
//         w&nbsp;tym miejscu należy również wskazać dane wierzyciela]
//       </p>
//       <p>
//         <span className="bold">KWOTA:</span> 1 010,49 PLN
//       </p>
//     </div>
//     <p className="cardDate">Opublikowano 22.08.2023</p>
//   </li>
// );

// const cardContent004 = (
//   <li className="alertCard four">
//     <div className="cardText">
//       <p>
//         Szanowni Państwo,
//         <br></br>
//         <br></br>
//         uprzejmie informujemy, że&nbsp;funkcjonuje dotychczasowy numer rachunku
//         bankowego służący do spłaty rat i&nbsp;innych należności wynikających
//         z&nbsp;umowy o&nbsp;kredyt / pożyczkę denominowaną lub indeksowaną do
//         franka szwajcarskiego (CHF) oraz z&nbsp;umowę o&nbsp;kredyt hipoteczny /
//         pożyczkę zabezpieczoną hipoteką wiążącą denominowaną lub indeksowane do
//         waluty obcej.
//         <br></br>
//         Jednocześnie w&nbsp;nowej Platformie informacyjnej Bankowość Internetowa
//         GNB (gnbbank.pl) zostały udostępnione już nowe rachunki bankowe służące
//         do spłaty rat i&nbsp;innych należności w&nbsp;procesie postępowania
//         upadłościowego Getin Noble Banku SA w&nbsp;upadłości.
//       </p>
//     </div>
//     <p className="cardDate">Opublikowano 03.10.2023</p>
//   </li>
// );

// const cardContent005 = (
//   <li className="alertCard five">
//     <div className="cardText">
//       <p>
//         Syndyk masy upadłości Getin Noble Bank S.A. w&nbsp;upadłości informuje,
//         że wpłaty zryczałtowanych kosztów postępowania upadłościowego
//         wynikających z&nbsp;dokonania zgłoszenia wierzytelności po terminie,
//         o&nbsp;których mowa w&nbsp;art.&nbsp;235 ust.&nbsp;1 ustawy Prawo
//         upadłościowe należy dokonywać na rachunek bankowy o&nbsp;numerze: 72
//         8436 0003 0000 0026 7436 0007.
//       </p>
//       <p>
//         <span className="bold underline">
//           Celem usprawnienia procesu podaje się dane do wykorzystania przy
//           wykonywaniu przelewu:
//         </span>
//       </p>
//       <p>
//         <span className="bold">ODBIORCA:</span> MARCIN KUBICZEK SYNDYK MASY
//         UPADŁOŚCI GETIN NOBLE BANK&nbsp;S.A. W&nbsp;UPADŁOŚCI
//       </p>
//       <p>
//         <span className="bold">NUMER KONTA ODBIORCY:</span> 72 8436 0003 0000
//         0026 7436 0007
//       </p>
//       <p>
//         <span className="bold">TYTUŁ:</span> ZRYCZAŁTOWANY KOSZT POSTĘPOWANIA
//         WA1M/GUp-Zw/44/2023 [jeżeli przelewu dokonuje pełnomocnik wierzyciela
//         w&nbsp;tym miejscu należy również wskazać dane wierzyciela]
//       </p>
//       <p>
//         <span className="bold">KWOTA:</span> 1 119,34 PLN
//       </p>
//     </div>
//     <p className="cardDate">Opublikowano w styczniu 2024 r.</p>
//   </li>
// );

// const cardContent006 = (
//   <li className="alertCard six">
//     <div className="cardText">
//       <p>
//         Szanowni Państwo,
//         <br></br>
//         <br></br>
//         syndyk masy upadłości Getin Noble Bank S.A. w upadłości informuje,
//         że&nbsp;wszelkie informacje o&nbsp;wydanych postanowieniach przez Sąd
//         w&nbsp;przedmiocie zawieszenia płatności rat kredytów powinny być
//         przesyłane na adres e-mail:{" "}
//         <a className="gold" href="mailto:zabezpieczeniagnb@kubiczekm.com">
//           zabezpieczeniagnb@kubiczekm.com
//         </a>{" "}
//         wraz z&nbsp;kopią wydanego orzeczenia.
//       </p>
//     </div>
//     <p className="cardDate">Opublikowano 18.03.2024 r.</p>
//   </li>
// );

function BankruptcyAlerts() {
  useScrollReveal({ selector: "#alerts" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main>
      <section id="alerts">
        <div className="container">
          <header className="sectionHeader">
            <h2>Aktualności</h2>
          </header>
          <ul className="cards">
            <AlertNews008 />
            <AlertNews007 />
            <AlertNews006 />
            <AlertNews005 />
            <AlertNews004 />
            <AlertNews003 />
            <AlertNews002 />
            <AlertNews001 />

            {/* <AlertCard content={cardContent006} />
            <AlertCard content={cardContent005} />
            <AlertCard content={cardContent004} />
            <AlertCard content={cardContent003} />
            <AlertCard content={cardContent002} />
            <AlertCard content={cardContent001} /> */}
          </ul>
          <p className="larger">To już wszystkie aktualności.</p>
        </div>
      </section>
    </main>
  );
}

export default BankruptcyAlerts;
