import React from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const alertNews006 = (
    <li className="alertCard six">
      <div className="cardText">
        <p>
          Szanowni Państwo,
          <br></br>
          <br></br>
          syndyk masy upadłości Getin Noble Bank S.A. w upadłości informuje,
          że&nbsp;wszelkie informacje o&nbsp;wydanych postanowieniach przez Sąd
          w&nbsp;przedmiocie zawieszenia płatności rat kredytów powinny być
          przesyłane na adres e-mail:{" "}
          <a className="gold" href="mailto:zabezpieczeniagnb@kubiczekm.com">
            zabezpieczeniagnb@kubiczekm.com
          </a>{" "}
          wraz z&nbsp;kopią wydanego orzeczenia.
        </p>
      </div>
      <p className="cardDate">Opublikowano 18.03.2024&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews006} />
    </>
  );
}

export default AlertCardBankruptcy;
