import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

const DocumentLink = ({ href, linkText, spanText }) => (
  <div>
    <div className="docPanel">
      <FontAwesomeIcon icon={faFilePdf} />
      <div>
        <a className="gold underline" href={href}>
          {linkText}
        </a>
        <p>
          <span>{spanText}</span>
        </p>
      </div>
    </div>
  </div>
);

export default DocumentLink;
