import React from 'react';

function AlertCard({ content }) {
  return (
    <>
      {content}
    </ >
  );
}

export default AlertCard;
