import React from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const alertNews004 = (
    <li className="alertCard four">
      <div className="cardText">
        <p>
          Szanowni Państwo,
          <br></br>
          <br></br>
          uprzejmie informujemy, że&nbsp;funkcjonuje dotychczasowy numer
          rachunku bankowego służący do spłaty rat i&nbsp;innych należności
          wynikających z&nbsp;umowy o&nbsp;kredyt / pożyczkę denominowaną lub
          indeksowaną do franka szwajcarskiego (CHF) oraz z&nbsp;umowę
          o&nbsp;kredyt hipoteczny / pożyczkę zabezpieczoną hipoteką wiążącą
          denominowaną lub indeksowane do waluty obcej.
          <br></br>
          Jednocześnie w&nbsp;nowej Platformie informacyjnej Bankowość
          Internetowa GNB (gnbbank.pl) zostały udostępnione już nowe rachunki
          bankowe służące do spłaty rat i&nbsp;innych należności w&nbsp;procesie
          postępowania upadłościowego Getin Noble Banku SA w&nbsp;upadłości.
        </p>
      </div>
      <p className="cardDate">Opublikowano 03.10.2023&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews004} />
    </>
  );
}

export default AlertCardBankruptcy;
