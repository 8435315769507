import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/DebtRestructuring.css";
import { useScrollReveal } from "../hooks/useScrollReveal";
import RestructuringFormBasic from "../components/RestructuringFormBasic";
import RestructuringFormExt from "../components/RestructuringFormExt";

function DebtRestructuring() {
  const [formOption, setFormOption] = useState("00");

  const handleFormOptionChange = (event) => {
    setFormOption(event.target.value);
  };

  useScrollReveal({ selector: "#debtRestructuring" });
  useScrollReveal({
    selector: ".whiteBgc, .imageBackground",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="debtRestructuring">
      <section id="debtConclude">
        <div className="container">
          <header className="headerBg">
            <h1 className="heading">Restrukturyzacja zadłużenia</h1>
          </header>
          <div className="imageBackground">
            <div className="textFrame rounded">
              <h3 className="larger">
                Zawnioskuj o&nbsp;restrukturyzację zadłużenia
              </h3>
              <p>
                Restrukturyzacja wierzytelności – to zmiana warunków umowy,
                która ma na celu pomoc klientom znajdującym się w&nbsp;trudnej
                sytuacji finansowej, żeby mogli odzyskać płynność finansową.
              </p>
              <br></br>
              <p>
                Jeśli masz problem z terminową spłatą swojego kredytu, możesz
                zwrócić się do nas z&nbsp;wnioskiem o&nbsp;zastosowanie ulgi
                w&nbsp;spłacie.
              </p>
              <br></br>
              <p>
                Wspólnie możemy znaleźć rozwiązanie i&nbsp;ustalić możliwe
                warunki spłaty, dostosowane do twojej aktualnej sytuacji
                finansowej.
              </p>{" "}
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section id="basicInfo">
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Podstawowe informacje</h2>
            </header>
            <p>
              Bank wprowadza możliwość restrukturyzacji kredytu
              gotówkowego/pożyczki, kredytu samochodowego oraz kredytu
              hipotecznego/pożyczki, dla kredytów z&nbsp;saldem powyżej
              5&nbsp;tys. złotych na dzień złożenia wniosku.
            </p>
            <br></br>
            <p>
              Zawarcie aneksu restrukturyzacyjnego/ ugody i&nbsp;ustalenie jego
              warunków uzależnione jest od pozytywnej decyzji banku.
            </p>
            <br></br>
            <p>
              Jeśli kredyt został udzielony więcej niż jednemu Kredytobiorcy
              wniosek powinien złożyć każdy z&nbsp;nich. Aneks
              restrukturyzacyjny/ Ugoda również powinny być podpisane przez
              każdego z&nbsp;nich.
            </p>
            <br></br>
            <p>
              Zapraszamy do wypełnienia wniosku i&nbsp;przesłania go przez
              dedykowany formularz.
            </p>
          </div>
        </section>

        <section id="chooseDebtFormula">
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                Wypełnij i&nbsp;prześlij wniosek ws. kredytów/pożyczek
                hipotecznych
              </h2>
            </header>

            <h3 className="larger">Wypełnij wniosek</h3>
            <div>
              <label>Wybierz opcję</label>
              <select
                id="debtFormPicker"
                className="rounded"
                onChange={handleFormOptionChange}
              >
                <option value="00">Wybierz z&nbsp;listy</option>
                <option value="01">
                  Wniosek o&nbsp;restrukturyzację - podstawowy
                </option>
                <option value="02">
                  Wniosek o&nbsp;restrukturyzację - uzupełnienie
                </option>
              </select>
            </div>
            <div className="text">
              <p>
                Jeżeli jesteś jedynym Kredytobiorcą lub jako pierwszy
                z&nbsp;wielu zobowiązanych do spłaty składasz ten wniosek,
                wybierz opcję:
                <span className="bold">
                  <br></br>„Wniosek o&nbsp;restrukturyzację - podstawowy”.
                </span>
              </p>
              <p>
                Jeżeli wniosek o&nbsp;restrukturyzację został już złożony przez
                innego z&nbsp;Kredytobiorców, a&nbsp;Ty uzupełniasz dodatkowo
                swoje dane, wybierz opcję:
                <span className="bold">
                  <br></br>„Wniosek o&nbsp;restrukturyzację – uzupełnienie”
                </span>
              </p>
            </div>
            <div>
              {formOption === "01" && <RestructuringFormBasic />}
              {formOption === "02" && <RestructuringFormExt />}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default DebtRestructuring;
