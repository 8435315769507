import React from "react";
import "../css/Rodo.css";
import { useScrollReveal } from "../hooks/useScrollReveal";

function Rodo() {
  useScrollReveal({ selector: "#rodo" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="rodo">
      <section>
        <div className="container">
          <h1 className="heading">RODO w Getin Noble Bank S.A.</h1>
          <div className="whiteBgc">
            <div className="text">
              <p>
                Przykładamy ogromną wagę zarówno do ochrony danych naszych
                Klientów jak i&nbsp;Kontrahentów, dlatego w&nbsp;ramach
                niniejszej strony www przybliżymy najważniejsze definicje
                związane z&nbsp;ochroną danych osobowych, wytłumaczymy czym są
                dane osobowe, w&nbsp;jakich przypadkach możemy je przetwarzać,
                jakie prawa przysługują naszym Klientom w&nbsp;związku
                z&nbsp;RODO oraz kim jest Inspektor Ochrony Danych i&nbsp;jakie
                są jego zadania.
              </p>
            </div>

            <h2>Najwaniejsze informacje</h2>

            <p>
              <span className="bold">DANE OSOBOWE</span> - jest to każda
              informacja, która pozwala zidentyfikować lub umożliwia
              identyfikację osoby fizycznej tj.: imię, nazwisko, PESEL, adres
              zamieszkania, adres e-mail, numer telefonu, NIP, dane
              o&nbsp;lokalizacji etc.
            </p>
            <p>
              <span className="bold">RODO</span> - ogólnoeuropejskie przepisy
              o&nbsp;ochronie danych osobowych, które stosowane są od 25 maja
              2018 roku.
            </p>
            <p>
              <span className="bold">PRZETWARZANIE DANYCH</span> - jakakolwiek
              operacja dokonywana na danych osobowych tj.: ich wprowadzanie do
              systemu, zbieranie, przechowywanie, podawanie ustnie przez
              telefon, przeglądanie, rozpowszechnianie, zmiana, udostępnianie,
              uzupełnianie, usuwanie, wykorzystywanie etc.
            </p>
            <p>
              <span className="bold">ADMINISTRATOR DANYCH</span> - podmiot,
              który decyduje w&nbsp;jakim celu oraz w&nbsp;jaki sposób będą
              przetwarzane dane osobowe – np. administratorem danych osobowych
              klientów Banku jest Getin Noble Bank S.A.
            </p>
            <p>
              <span className="bold">PODMIOT DANYCH</span> – każda osoba, której
              dane osobowe dotyczą.
            </p>
            <p>
              <span className="bold">PODMIOT PRZETWARZAJĄCY DANE</span> -
              podmiot przetwarzający dane osobowe w&nbsp;imieniu administratora
              danych, np. w&nbsp;związku z&nbsp;wykonywaniem umowy pośrednictwa.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <header>
              <h2>Jakie prawa przysługują Klientom?</h2>
            </header>
            <p>Jakie prawa przysługują Klientom?</p>

            <p>
              RODO przewiduje szeroki katalog uprawnień z&nbsp;których podmioty
              danych mogą skorzystać w&nbsp;związku z&nbsp;przetwarzaniem przez
              Getin Noble Bank S.A.&nbsp;ich danych osobowych. Z&nbsp;opisanych
              poniżej praw skorzystać może każdy podmiot danych - klient,
              pracownik, kontrahent etc.
            </p>
            <p>RODO przewiduje następujące prawa podmiotów danych:</p>
            <ol className="numberList">
              <li>
                <span className="bold">Prawo dostępu do danych osobowych</span>{" "}
                - prawo podmiotu danych do uzyskania od Getin Noble Bank S.A.
                informacji czy przetwarza jego dane osobowe. Jeżeli ma to
                miejsce, podmiot danych jest uprawniony do uzyskania do nich
                dostępu oraz informacji dotyczących w&nbsp;szczególności: celów
                przetwarzania danych, kategorii odnośnych danych osobowych,
                informacji o&nbsp;odbiorcach danych lub kategoriach odbiorców.
                Podmiot danych jest również uprawniony do uzyskania kopi danych
                osobowych przetwarzanych przez Getin Noble Bank S.A.
              </li>
              <li>
                <span className="bold">Prawo do sprostowania danych</span>
                &nbsp;- prawo podmiotu danych do żądania niezwłocznego
                sprostowania dotyczących go nieprawidłowych danych osobowych.
              </li>
              <li>
                <span className="bold">
                  Prawo do ograniczenia przetwarzania
                </span>{" "}
                - prawo żądania przez podmiot danych ograniczenia przetwarzania
                jego danych osobowych, gdy np. zakwestionuje on ich prawidłowość
                lub gdy przetwarzanie tych danych jest niezgodne z&nbsp;prawem
                a&nbsp;podmiot danych nie wyraża zgody na ich usunięcie
              </li>
              <li>
                <span className="bold">Prawo do usunięcia danych</span> – prawo
                żądania przez podmiot danych niezwłocznego usunięcia jego danych
                osobowych, w&nbsp;szczególności gdy dane nie są już niezbędne do
                celu dla którego zostały zebrane (np. umowa łącząca strony
                wygasła), wycofano zgodę na ich przetwarzanie lub ich
                przetwarzanie jest niezgodne z&nbsp;prawem.
              </li>
              <li>
                <span className="bold">Prawo do przenoszenia danych</span> –
                jest to uprawnienie podmiotu danych, aby w&nbsp;określonych
                przypadkach, żądać od administratora otrzymania informacji
                (danych osobowych), które mu przekazała na potrzeby zawarcia
                i&nbsp;realizacji umowy lub na podstawie udzielonej zgody (np.
                na przetwarzanie danych w&nbsp;celach profilowania na potrzeby
                marketingu).<br></br>
                <br></br>Dane te muszą zostać wydane przez administratora
                w&nbsp;„ustrukturyzowanym, powszechnie używanym formacie
                nadającym się do odczytu maszynowego” tj. XML, JSON czy CSV.
                <br></br>
                <br></br>Ponadto osoba wykonując prawo do przenoszenia danych ma
                prawo żądania, by dane osobowe zostały przesłane przez
                administratora bezpośrednio innemu administratorowi, o&nbsp;ile
                jest to technicznie możliwe. Ma to na celu umożliwienie osobie,
                której dane dotyczą, ponownego wykorzystania danych osobowych
                zapisanych w&nbsp;formacie udostępnianego pliku.
              </li>
              <li>
                <span className="bold">Prawo sprzeciwu –</span> podmiot danych
                może w&nbsp;dowolnym momencie wnieść sprzeciw wobec
                przetwarzania jej danych.&nbsp;W przypadku wniesienia takiego
                sprzeciwu administrator nie może dalej przetwarzać danych osoby,
                która wniosła sprzeciw, chyba że wykaże on istnienie ważnych,
                prawnie uzasadnionych, podstaw do przetwarzania, nadrzędnych
                wobec interesów, praw i&nbsp;wolności osoby, która wniosła
                sprzeciw.
                <br></br>
                <br></br>W&nbsp;przypadku przetwarzania danych do celów
                marketingu bezpośredniego, osoba, której dane są przetwarzane ma
                prawo wnieść sprzeciw wobec przetwarzania jej danych w&nbsp;tym
                celu. Powyższe dotyczy również sprzeciwu wobec profilowania.
                Administrator jest zobowiązany poinformować osobę o&nbsp;ww.
                przysługującym jej uprawnieniu najpóźniej przy okazji pierwszego
                kontaktu, przy czym wspomniana informacja powinna być
                przedstawiona w&nbsp;sposób wyraźny i&nbsp;łatwy do zrozumienia.
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <header>
              <h2>W jaki sposób Klient może zrealizować powyższe prawa?</h2>
            </header>
            <p>
              Realizacja praw odbywa się&nbsp; na wniosek podmiotu danych,
              a&nbsp;Administrator nie może odmówić przyjęcia tego wniosku.
              Wniosek jest przyjmowany: w&nbsp;dowolnym oddziale Banku,
              w&nbsp;formie korespondencji listownej na aktualny adres siedziby
              Banku ; kontaktując się na adres mailowy Inspektora Ochrony
              Danych:{" "}
              <a href="mailto:administrator.iod@gnbbank.pl">
                administrator.iod@gnbbank.pl
              </a>
              , przekazując stosowną dyspozycję na infolinię Banku; korzystając
              z&nbsp;Bankowości InternetowejElektronicznej/Kanałów Zdalnych
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <header>
              <h2>Inspektor Ochrony Danych (IOD)</h2>
            </header>
            <p>
              Inspektor Ochrony Danych (IOD) – kto to jest i&nbsp;jaka jest jego
              rola?
            </p>
            <p>
              Jest to osoba, która nadzoruje czy dane osobowe
              u&nbsp;Administratora są przetwarzane we &nbsp;właściwy sposób
              oraz odpowiada za to, aby Administrator &nbsp;działał
              &nbsp;zgodnie z&nbsp;obowiązującymi przepisami w&nbsp;zakresie
              ochrony danych osobowych, w&nbsp;tym RODO i&nbsp;odpowiednimi
              przepisami krajowymi.
            </p>
            <p>
              <span className="bold">
                Jak możesz się z&nbsp;IOD skontaktować?
              </span>
              Jest to osoba, która nadzoruje czy dane osobowe
              u&nbsp;Administratora są przetwarzane we &nbsp;właściwy sposób
              oraz odpowiada za to, aby Administrator &nbsp;działał
              &nbsp;zgodnie z&nbsp;obowiązującymi przepisami w&nbsp;zakresie
              ochrony danych osobowych, w&nbsp;tym RODO i&nbsp;odpowiednimi
              przepisami krajowymi.
            </p>
            <p>
              Inspektor Ochrony Danych w&nbsp;Getin Noble Bank S.A. powinien być
              pierwszym i&nbsp;naturalnym kontaktem w&nbsp;sprawie danych
              osobowych. W&nbsp;przypadku wykrycia naruszenia lub okoliczności,
              które wskazują na naruszenie ochrony danych osobowych bądź
              w&nbsp;przypadku zapytań lub wątpliwości dotyczących przetwarzania
              danych osobowych, prosimy o&nbsp;niezwłoczny kontakt z&nbsp;IOD
              pod adresem e-mail administrator.iod@.gnb.pl.
            </p>
            <p>
              Dane kontaktowe Inspektora Ochrony Danych:
              {/* Emilia Kuczma, */} e-mail:{" "}
              <a className="gold" href="mailto:administrator.iod@gnbbank.pl">
                administrator.iod@gnbbank.pl
              </a>
            </p>
            <div className="text">
              <h3>W&nbsp;jakich celach przetwarzamy dane osobowe Klientów?</h3>
              <p>
                Getin Noble Bank S.A. („Bank”) przetwarza dane osobowe Klientów
                w&nbsp;następujących celach:
              </p>
              <ul className="dottedList">
                <li>
                  podjęcia działań zmierzających do zawarcia umowy
                  z&nbsp;Bankiem, w&nbsp;tym profilowania pod kątem badania
                  zdolności kredytowej i&nbsp;analizy ryzyka (art. 6 ust. 1 lit.
                  b RODO);
                </li>
                <li>
                  realizacji umowy zawartej z&nbsp;Bankiem (art. 6 ust. 1 lit. b
                  RODO);
                </li>
                <li>
                  wypełnienia obowiązków prawnych ciążących na Banku
                  wynikających z&nbsp;przepisów szczególnych regulujących
                  działalność Banku (art. 6 ust. 1 lit. c RODO);
                </li>
                <li>
                  profilowania w&nbsp;związku z&nbsp;zapobieganiem praniu
                  pieniędzy oraz przestrzeganiu przepisów dotyczących sankcji
                  i&nbsp;embarg (art. 6 ust. 1 lit. c RODO);
                </li>
                <li>
                  profilowania w&nbsp;związku z&nbsp;tworzeniem modeli
                  statystycznych, w&nbsp;celu poprawy zarządzania ryzykiem (art.
                  6 ust. 1 lit. c RODO);
                </li>
                <li>
                  dochodzenia ewentualnych roszczeń lub obrona przed
                  roszczeniami kierowanymi przeciw Bankowi (art. 6 ust. 1 lit. f
                  RODO);
                </li>
                <li>
                  marketingu (w tym profilowania) produktów &nbsp;i usług
                  oferowanych przez Bank (art. 6 ust. lit. f RODO);
                </li>
                <li>
                  marketingu produktów grupy kapitałowej (art. 6 ust. 1 lit.
                  a&nbsp;RODO);
                </li>
                <li>
                  wewnętrznych celów administracyjnych Banku, w&nbsp;tym analizy
                  portfela kredytowego, statystyki i&nbsp;raportowania
                  wewnętrznego Banku (art. 6 ust. 1 lit. f RODO
                </li>
              </ul>
            </div>

            <div className="text">
              <h3>Kto może być odbiorcą danych osobowych Klientów? </h3>
              <p>
                Bank ma prawo przekazywać Twoje dane osobowe do następujących
                podmiotów / instytucji:
              </p>
              <ul className="dottedList">
                <li>Biuro Informacji Kredytowej S.A.;</li>
                <li>
                  Ministerstwo Finansów, w&nbsp;tym Generalny Inspektor
                  Informacji Finansowej;
                </li>
                <li>Komisja Nadzoru Finansowego;</li>
                <li>
                  instytucje&nbsp; i&nbsp;inne podmioty&nbsp;upoważnione do
                  odbioru danych osobowych na podstawie odpowiednich przepisów
                  prawa.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <header>
              <h2>Jak długo dane osobowe Klientów są przetwarzane?</h2>
            </header>
            <p>
              W&nbsp;zakresie realizacji zawartej umowy z&nbsp;Bankiem, przez
              okres do czasu zakończeniu jej realizacji, a&nbsp;po tym czasie
              przez okres oraz w&nbsp;zakresie wymaganym przez przepisy prawa
              lub dla zabezpieczenia ewentualnych roszczeń,
              a&nbsp;w&nbsp;przypadku wyrażenia dobrowolnej zgody na
              przetwarzanie danych osobowych, do czasu wniesienia sprzeciwu do
              takiego przetwarzania
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <header>
              <h2>
                Jak mogę wnieść skargę na przetwarzanie moich danych osobowych?
              </h2>
            </header>
            <p>
              W&nbsp;związku z&nbsp;przetwarzaniem Twoich danych osobowych przez
              Bank możesz wnieść (np. listownie) skargę do organu nadzoru-
              Prezesa Urzędu Ochrony Danych Osobowych.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <header>
              <h2>Czy mogę cofnąć zgodę na przetwarzanie danych osobowych?</h2>
            </header>
            <p>Czy mogę cofnąć zgodę na przetwarzanie danych osobowych?</p>
            <p>
              W&nbsp;zakresie w&nbsp;jakim przetwarzanie danych osobowych odbywa
              się na podstawie zgody, to przysługuje Tobie prawo do jej
              cofnięcia w&nbsp;dowolnym momencie bez wpływu na zgodność
              z&nbsp;prawem przetwarzania, którego dokonano na podstawie zgody
              przed jej cofnięciem.
            </p>
            <h3>
              Czy moje dane osobowe przetwarzane są w&nbsp;sposób
              zautomatyzowany?{" "}
            </h3>
            <br></br>
            <p>
              Czy moje dane osobowe przetwarzane są w&nbsp;sposób
              zautomatyzowany?
            </p>
            <p>
              Podobnie jak większość banków, również Getin Noble Bank S.A.
              poddaje dane osobowe przetwarzaniu w&nbsp;sposób zautomatyzowany,
              w&nbsp;szczególności poprzez profilowanie, w&nbsp;następujących
              przypadkach:
            </p>
            <ul className="dottedList">
              <li>
                dokonywanie oceny Twojej sytuacji ekonomicznej i&nbsp;zdolności
                do terminowego regulowania zobowiązań, przy czym ocena ta
                podejmowana jest na podstawie danych przedstawionych we wniosku
                oraz informacji uzyskanych w&nbsp;toku dokonywania oceny
                w&nbsp;oparciu o&nbsp;zdefiniowany zestaw reguł
                i&nbsp;algorytmów według wypracowanego przez Bank procesu –
                konsekwencją dokonanej oceny jest: automatyczna zgoda na
                zawarcie umowy, automatyczna odmowa zawarcia umowy lub
                konieczność podjęcia przez Bank indywidualnego rozstrzygnięcia;
              </li>
              <li>
                dokonywanie oceny ryzyka prania pieniędzy oraz finansowania
                terroryzmu, przy czym ocena ta dokonywana jest na podstawie
                danych przedstawionych we wniosku w&nbsp;oparciu o&nbsp;ustalone
                kryteria (behawioralne, geograficzne, ekonomiczne, przedmiotowe)
                – konsekwencją dokonanej oceny jest automatyczne
                zakwalifikowanie do grupy ryzyka; kwalifikacja do grupy
                nieakceptowanego ryzyka może skutkować odmową zawarcia umowy.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <header>
              <h2>Obowiązek Informacyjny</h2>
            </header>
            <div className="text">
              <h3>
                Tożsamość i dane kontaktowe administratora danych osobowych
              </h3>
              <br></br>
              <br></br>

              <p>
                Getin Noble Bank S.A., Rondo Ignacego Daszyńskiego 2 C, 00-843
                Warszawa, <br></br>Telefon: : +48&nbsp;799&nbsp;655 999
              </p>
            </div>
            <div className="text">
              <h3>Dane kontaktowe inspektora ochrony danych:</h3>
              <br></br>
              <br></br>

              <p>
                Inspektor Ochrony Danych:<br></br>e-mail:{" "}
                <a className="gold" href="mailto:administrator.iod@gnbbank.pl">
                  administrator.iod@gnbbank.pl
                </a>
                <br></br>
              </p>
            </div>

            <div className="text">
              <h3>
                Cele przetwarzania danych osobowych oraz podstawa prawna
                przetwarzania
              </h3>
              <ul className="dottedList">
                <li>
                  Podjęcie działań zmierzających do zawarcia umowy
                  z&nbsp;Bankiem, w&nbsp;tym profilowanie pod kątem badania
                  zdolności kredytowej i&nbsp;analizy ryzyka (art. 6 ust. 1 lit.
                  b RODO).
                </li>
                <li>
                  Realizacja umowy zawartej z&nbsp;Bankiem (art. 6 ust. 1 lit. b
                  RODO).
                </li>
                <li>
                  Wypełnienie obowiązków prawnych ciążących na Banku
                  wynikających z&nbsp;przepisów szczególnych regulujących
                  działalność Banku (art. 6 ust. 1 lit. c RODO).
                </li>
                <li>
                  Profilowanie w&nbsp;związku z&nbsp;zapobieganiem praniu
                  pieniędzy oraz przestrzeganiem przepisów dotyczących sankcji
                  i&nbsp;embarg (art. 6 ust. 1 lit. c RODO).
                </li>
                <li>
                  Profilowanie w&nbsp;związku z&nbsp;tworzeniem modeli
                  statystycznych, w&nbsp;celu poprawy zarządzania ryzykiem (art.
                  6 ust. 1 lit. c RODO).
                </li>
                <li>
                  Dochodzenie ewentualnych roszczeń lub obrona przed
                  roszczeniami kierowanymi przeciw Bankowi &nbsp;(art. 6 ust. 1
                  lit. f RODO).
                </li>
                <li>
                  Marketing (w tym profilowanie) produktów i&nbsp;usług
                  oferowanych przez Bank (art. 6 ust. 1 lit. f RODO).
                </li>
                <li>
                  Marketing produktów grupy kapitałowej (art. 6 ust. 1 lit.
                  a&nbsp;RODO).
                </li>
                <li>
                  Wewnętrzne cele administracyjne Banku, w&nbsp;tym analiza
                  portfela kredytowego i&nbsp;statystyk oraz raportowanie
                  wewnętrzne Banku (art. 6 ust. 1 lit. f RODO).
                </li>
              </ul>
            </div>

            <div className="text">
              <h3>
                Prawnie uzasadnione interesy realizowane przez administratora
                lub przez stronę trzecią
              </h3>
              <ul class="dottedList">
                <li>
                  Dochodzenie ewentualnych roszczeń związanych lub obrona przed
                  roszczeniami kierowanymi przeciw Bankowi
                </li>
                <li>
                  Marketing (w tym profilowanie) produktów oferowanych przez
                  Bank.
                </li>
                <li>
                  Wewnętrzne cele administracyjne Banku, w&nbsp;tym analiza
                  portfela kredytowego, statystyki i&nbsp;raportowanie
                  wewnętrzne Banku.
                </li>
              </ul>
            </div>

            <div className="text">
              <h3>
                Informacje o&nbsp;odbiorcach danych osobowych lub
                o&nbsp;kategoriach odbiorców, jeżeli istnieją
              </h3>
              <ul className="dottedList">
                <li>Biuro Informacji Kredytowej S.A.</li>
                <li>
                  Ministerstwo Finansów, w&nbsp;tym Generalny Inspektor
                  Informacji Finansowej.
                </li>
                <li>Komisja Nadzoru Finansowego.</li>
                <li>
                  Instytucje&nbsp; i&nbsp;inne podmioty upoważnione do odbioru
                  danych osobowych na podstawie odpowiednich przepisów prawa.
                </li>
              </ul>
            </div>

            <div className="text">
              <h3>Informacje o&nbsp;zamiarze przekazania danych osobowych</h3>
              <p>
                W&nbsp;przypadku osób podlegających przepisom podatkowym FATCA
                i&nbsp;CRS/EUROFATCA, dane osobowe będą przetwarzane w&nbsp;celu
                poinformowania odpowiednio amerykańskich lub innych
                zagranicznych organów skarbowych bezpośrednio lub za
                pośrednictwem polskich organów podatkowych o&nbsp;szczegółach
                zawieranych umów rachunków bankowych, a&nbsp;także przekazane
                w&nbsp;celu dokonania identyfikacji podatkowej. Listę państw
                (tzw. państwa uczestniczące) do których mogą zostać przekazane
                dane osobowe, o&nbsp;których mowa powyżej, ogłasza Minister
                właściwy do spraw finansów publicznych w&nbsp;drodze
                obwieszczenia w&nbsp;Dzienniku Urzędowym Rzeczypospolitej
                Polskiej „Monitor Polski” w&nbsp;terminie do dnia 15
                października (na podstawie art. 24 ust. 5 ustawy z&nbsp;dnia 10
                lutego 2017 r. o&nbsp;wymianie informacji podatkowych
                z&nbsp;innymi państwami (Dz.U. z&nbsp;2017 r. poz. 648).
              </p>
            </div>

            <div className="text">
              <h3>Okres, przez który dane osobowe będą przetwarzane</h3>
              <ul className="dottedList">
                <li>
                  do czasu zakończenia umowy z&nbsp;Bankiem w&nbsp;zakresie
                  realizacji umowy
                </li>
                <li>
                  po zakończeniu umowy do czasu wymaganego przez przepisy
                  i&nbsp;zakres prawa
                </li>
                <li>
                  do czasu zakończenia dochodzenia ewentualnych roszczeń lub
                  obrony przed roszczeniami kierowanymi przeciw Bankowi
                </li>
                <li>
                  do czasu wycofania zgody w&nbsp;zakresie, w&nbsp;jakim
                  przetwarzanie danych osobowych odbywało się na podstawie zgody
                </li>
              </ul>
            </div>

            <div className="text">
              <h3>Informacje o&nbsp;prawach dotyczących danych osobowych</h3>
              <p>Ma Pani/Pan prawo:</p>
              <ul className="dottedList">
                <li>żądania dostępu do swoich danych osobowych,</li>
                <li>żądania ich sprostowania,</li>
                <li>żądania ich usunięcia,</li>
                <li>żądania ograniczenia ich przetwarzania,</li>
                <li>wniesienia sprzeciwu wobec przetwarzania,</li>
                <li>do przenoszenia danych.</li>
              </ul>
            </div>

            <div className="text">
              <h3>Informacja o&nbsp;prawie do cofnięcia zgody</h3>
              <p>
                W&nbsp;zakresie w&nbsp;jakim przetwarzanie danych osobowych
                odbywa się na podstawie zgody, przysługuje Pani/Panu prawo do
                jej cofnięcia w&nbsp;dowolnym momencie. Cofnięcie zgody nie
                wpływa na zgodność z&nbsp;prawem przetwarzania, którego dokonano
                na podstawie zgody przed jej cofnięciem.
              </p>
            </div>

            <div className="text">
              <h3>
                Informacja o&nbsp;prawie wniesienia skargi do organu nadzorczego
              </h3>
              <p>
                Przysługuje Pani/Panu prawo do wniesienia skargi do organu
                nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych
                w&nbsp;związku z&nbsp;przetwarzaniem Pani/Pana danych osobowych
                przez Bank.
              </p>
            </div>

            <div className="text">
              <h3>Informacja o&nbsp;dobrowolności podania danych</h3>
              <p>
                Podanie danych osobowych jest warunkiem zawarcia umowy
                z&nbsp;Bankiem. Podanie danych osobowych ma charakter
                dobrowolny. Konsekwencją niepodania danych osobowych jest brak
                możliwości zawarcia i&nbsp;realizacji umowy z&nbsp;Bankiem.
              </p>
            </div>

            <div className="text">
              <h3>
                Informacje o&nbsp;zautomatyzowanym podejmowaniu decyzji,
                w&nbsp;tym o&nbsp;profilowaniu
              </h3>
              <p>
                Podane dane osobowe będą przetwarzane w&nbsp;sposób
                zautomatyzowany, w&nbsp;szczególności poprzez profilowanie,
                w&nbsp;następujących przypadkach:
              </p>

              <ul>
                <ul className="dottedList">
                  <li>
                    dokonywanie oceny Pana/Pani sytuacji ekonomicznej
                    i&nbsp;zdolności do terminowego regulowania zobowiązań, przy
                    czym ocena ta podejmowana jest na podstawie danych
                    przedstawionych we wniosku oraz informacji uzyskanych
                    w&nbsp;toku dokonywania oceny w&nbsp;oparciu
                    o&nbsp;zdefiniowany zestaw reguł i&nbsp;algorytmów według
                    wypracowanego przez Bank procesu – konsekwencją dokonanej
                    oceny jest: automatyczna zgoda na zawarcie umowy,
                    automatyczna odmowa zawarcia umowy lub konieczność podjęcia
                    przez Bank indywidualnego rozstrzygnięcia,
                  </li>
                  <li>
                    dokonywanie oceny ryzyka prania pieniędzy oraz finansowania
                    terroryzmu, przy czym ocena ta dokonywana jest na podstawie
                    danych przedstawionych we wniosku w&nbsp;oparciu
                    o&nbsp;ustalone kryteria (behawioralne, geograficzne,
                    ekonomiczne, przedmiotowe) – konsekwencją dokonanej oceny
                    jest automatyczne zakwalifikowanie do grupy ryzyka;
                    kwalifikacja do grupy nieakceptowanego ryzyka może skutkować
                    odmową zawarcia umowy.
                  </li>
                  <li>
                    dokonywanie oceny stanu aktywów Klienta w&nbsp;Banku oraz
                    nabytych za&nbsp;pośrednictwem Banku (aktywów
                    inwestycyjnych), przy czym ocena ta&nbsp;podejmowana jest na
                    podstawie danych pozyskanych w&nbsp;ramach wykonania umów
                    Banku z&nbsp;Klientem, jak również stanu aktywów Klienta
                    w&nbsp;instytucjach inwestycyjnych pozyskanych przez Bank od
                    tych instytucji, w&nbsp;oparciu o&nbsp;zdefiniowany zestaw
                    reguł i&nbsp;algorytmów według wypracowanego przez Bank
                    procesu - konsekwencją dokonanej oceny jest: po pierwsze
                    automatyczna decyzja o&nbsp;dostępnej dla Klienta ofercie
                    produktowej i&nbsp;warunkach zawierania umowy, po drugie
                    &nbsp;automatyczna decyzja odnośnie wysokości opłaty za
                    prowadzenie ROR w&nbsp;danym miesiącu (zgodnie z&nbsp;Tabelą
                    Opłat i&nbsp;Prowizji) lub konieczność podjęcia przez Bank
                    indywidualnego rozstrzygnięcia.
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Rodo;
