import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../css/RestructuringForm.css";

function RestructuringFormExt() {
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  function handleCheckboxChange(e) {
    const hiddenInput = document.getElementById("changeTermsHiddenInput");
    hiddenInput.value = e.target.checked ? "Zaznaczono checkbox" : "";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(formRef.current);

    try {
      const response = await fetch(
        "https://gnbbank.pl/send-restructuring-ext-email",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setErrors({
          form:
            errorData.error ||
            "Wystąpił nieznany błąd podczas wysyłania formularza. Spróbuj później.",
        });
      } else {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors({
        form: "Wystąpił nieznany błąd podczas wysyłania formularza. Spróbuj później.",
      });
    }
    const formValues = {};
    formData.forEach((value, key) => {
      formValues[key] = value;
    });
  };

  if (isSubmitted) {
    return (
      <main className="submittedMsg">
        <div className="container">
          <div className="text">
            <p>Dziękujemy za przesłaną wiadomość.</p>
            <p>
              Odpowiedzi mailowej udzielimy na adres zarejestrowany do kontaktu
              z&nbsp;Bankiem.
            </p>
          </div>
          <Link className="btnGold" to="/">
            Powrót do strony głównej
          </Link>
        </div>
      </main>
    );
  }

  return (
    <div className="mainForm">
      <div id="restructuringForm">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <legend>
            <h3 className="additionalMargin">
              Wniosek o&nbsp;restrukturyzację zadłużenia / zawarcie ugody
            </h3>
          </legend>
          <div className="row">
            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Numer umowy *</label>
                <input
                  name="contractNumber"
                  maxLength="100"
                  placeholder="Wpisz pełny numer umowy kredytu"
                  type="text"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12 form-text">
                <label>Rola Klienta w&nbsp;umowie kredytowej *</label>
                <select name="clientRole" required>
                  <option value="Współkredytobiorca">Współkredytobiorca</option>
                  <option value="Poręczyciel">Poręczyciel</option>
                  <option value="Dłużnik rzeczowy">Dłużnik rzeczowy</option>
                  <option value="Przystępujący do długu">
                    Przystępujący do długu
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <legend>
              <h3>Dane klienta</h3>
            </legend>
            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Nazwisko *</label>
                <input
                  name="lastName"
                  minlength="2"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Imię *</label>
                <input
                  name="firstName"
                  minlength="2"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Pesel *</label>
                <input
                  name="pesel"
                  className="pesel-field"
                  minlength="11"
                  maxLength="11"
                  placeholder="Wpisz"
                  type="number"
                  required
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Seria i&nbsp;numer dowodu osobistego *</label>
                <input
                  name="idCardNumber"
                  minlength="8"
                  maxLength="10"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Adres zameldowania *</label>
                <input
                  name="registrationAddress"
                  maxLength="100"
                  type="text"
                  required
                ></input>
              </div>
              <div className="columns large-6 medium-12">
                <label>Adres korespondencyjny *</label>
                <input
                  name="mailingAddress"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>

                <p>
                  W przypadku wskazania adresu korespondencyjnego innego niż
                  obecnie obowiązuje w&nbsp;Banku oraz chęci zgłoszenia tej
                  zmiany na stałe, należy udać się do placówki bankowej
                  i&nbsp;dokonać odpowiednich zmian.
                </p>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Telefon kontaktowy *</label>
                <input
                  name="contactPhone"
                  minlength="9"
                  maxLength="9"
                  placeholder="Wpisz"
                  type="number"
                  required
                ></input>
              </div>
              <div className="columns large-6 medium-12">
                <label>Adres email</label>
                <input
                  name="emailAddress"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="email"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Ilość osób w&nbsp;gospodarstwie domowym *</label>
                <input
                  name="householdSize"
                  minlength="1"
                  maxLength="2"
                  placeholder="Wpisz"
                  type="number"
                  required
                ></input>
              </div>
              <div className="columns large-6 medium-12">
                <label>
                  Ilość osób w&nbsp;gospodarstwie domowym na utrzymaniu
                  kredytobiorcy *
                </label>
                <input
                  name="dependentsNumber"
                  minlength="1"
                  maxLength="2"
                  type="number"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Stan cywilny *</label>
                <select name="maritalStatus" required>
                  <option value="Zamężna/żonaty">Zamężna/żonaty</option>
                  <option value="Panna/kawaler">Panna/kawaler</option>
                  <option value="Wdowa/wdowiec">Wdowa/wdowiec</option>
                </select>
                <p>
                  W przypadku zaznaczenia stanu cywilnego -{" "}
                  <i>Zamężna/żonaty</i> wybierz poniżej właściwy status
                  majątkowy
                </p>
              </div>
              <div className="columns large-6 medium-12 form-text">
                <label>Status majątkowy</label>
                <select name="propertyStatus">
                  <option selected value="Nie wybrano">
                    Wybierz
                  </option>
                  <option value="Wspólnota majątkowa">
                    Wspólnota majątkowa
                  </option>
                  <option value="Rozdzielność majątkowa">
                    Rozdzielność majątkowa
                  </option>
                </select>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Miesięczne koszty utrzymania (z pominięciem wydatków
                  związanych z&nbsp;obsługą kredytów) *
                </label>
                <input
                  name="monthlyMaintenanceCost"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                  required
                ></input>
              </div>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">
                Źródło dochodów klienta na dzień składania wniosku
                o&nbsp;restrukturyzację
              </h3>
            </legend>

            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Dochód podstawowy *</label>
                <select name="primaryIncomeSource" required>
                  <option value="Umowa o pracę">Umowa o&nbsp;pracę</option>
                  <option value="Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne">
                    Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne
                  </option>
                  <option value="Renta inwalidzka/Renta po zmarłym współmałżonku/Renta resortowa">
                    Renta inwalidzka/Renta po zmarłym współmałżonku/Renta
                    resortowa
                  </option>
                  <option value="Renta wyrównawcza">Renta wyrównawcza</option>
                  <option value="Kontrakt (np. menedżerski, wojskowy)">
                    Kontrakt (np. menedżerski, wojskowy)
                  </option>
                  <option value="Kontrakt – marynarze">
                    Kontrakt – marynarze
                  </option>
                  <option value="Prowadzenie gospodarstwa rolnego">
                    Prowadzenie gospodarstwa rolnego
                  </option>
                  <option value="Wolne zawody (lekarze wszystkich specjalności, prawnicy, biegli rewidenci, architekci, rzecznicy patentowi, tłumacze przysięgli, doradcy podatkowi, doradcy inwestycyjni, rzeczoznawcy majątkowi, księgowi)">
                    Wolne zawody (lekarze wszystkich specjalności, prawnicy,
                    biegli rewidenci, architekci, rzecznicy patentowi, tłumacze
                    przysięgli, doradcy podatkowi, doradcy inwestycyjni,
                    rzeczoznawcy majątkowi, księgowi)
                  </option>
                  <option value="Umowa najmu lokalu">Umowa najmu lokalu</option>
                  <option value="Umowa cywilno-prawna (umowa zlecenie, umowa o&nbsp;dzieło)">
                    Umowa cywilno-prawna (umowa zlecenie, umowa o&nbsp;dzieło)
                  </option>
                  <option value="Dochód z tytułu dywidendy i udziału w zysku w Sp. z o.o. lub akcyjnej">
                    Dochód z&nbsp;tytułu dywidendy i&nbsp;udziału w&nbsp;zysku
                    w&nbsp;Sp. z&nbsp;o.o. lub akcyjnej
                  </option>
                  <option value="Radni">Radni</option>
                  <option value="Posłowie">Posłowie</option>
                  <option value="Dochody zagraniczne">
                    Dochody zagraniczne
                  </option>
                  <option value="Zasiłek macierzyński">
                    Zasiłek macierzyński
                  </option>
                  <option value="Brak">Brak</option>
                </select>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Średni miesięczny dochód netto z&nbsp;ostatnich 3 miesięcy
                  wynosi: *
                </label>
                <input
                  name="averageMonthlyIncome"
                  maxLength="100"
                  type="text"
                  required
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Dochód dodatkowy nr 1</label>
                <select name="additionalIncomeSource1">
                  <option selected value="Nie wybrano">
                    Wybierz
                  </option>
                  <option value="Umowa o pracę">Umowa o&nbsp;pracę</option>
                  <option value="Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne">
                    Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne
                  </option>
                  <option value="Renta inwalidzka/Renta po zmarłym współmałżonku/Renta resortowa">
                    Renta inwalidzka/Renta po zmarłym współmałżonku/Renta
                    resortowa
                  </option>
                  <option value="Renta wyrównawcza">Renta wyrównawcza</option>
                  <option value="Kontrakt (np. menedżerski, wojskowy)">
                    Kontrakt (np. menedżerski, wojskowy)
                  </option>
                  <option value="Kontrakt – marynarze">
                    Kontrakt – marynarze
                  </option>
                  <option value="Prowadzenie gospodarstwa rolnego">
                    Prowadzenie gospodarstwa rolnego
                  </option>
                  <option value="Wolne zawody (lekarze wszystkich specjalności, prawnicy, biegli rewidenci, architekci, rzecznicy patentowi, tłumacze przysięgli, doradcy podatkowi, doradcy inwestycyjni, rzeczoznawcy majątkowi, księgowi)">
                    Wolne zawody (lekarze wszystkich specjalności, prawnicy,
                    biegli rewidenci, architekci, rzecznicy patentowi, tłumacze
                    przysięgli, doradcy podatkowi, doradcy inwestycyjni,
                    rzeczoznawcy majątkowi, księgowi)
                  </option>
                  <option value="Umowa najmu lokalu">Umowa najmu lokalu</option>
                  <option value="Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)">
                    Umowa cywilno-prawna (umowa zlecenie, umowa o&nbsp;dzieło)
                  </option>
                  <option value="Dochód z tytułu dywidendy i udziału w zysku w Sp. z o.o. lub akcyjnej">
                    Dochód z&nbsp;tytułu dywidendy i&nbsp;udziału w&nbsp;zysku
                    w&nbsp;Sp. z&nbsp;o.o. lub akcyjnej
                  </option>
                  <option value="Radni">Radni</option>
                  <option value="Posłowie">Posłowie</option>
                  <option value="Dochody zagraniczne">
                    Dochody zagraniczne
                  </option>
                  <option value="Świadczenie w ramach programu Rodzina 500 plus">
                    Świadczenie w&nbsp;ramach programu Rodzina 500 plus
                  </option>
                  <option value="Zasiłek macierzyński">
                    Zasiłek macierzyński
                  </option>
                  <option value="Alimenty">Alimenty</option>
                  <option value="Brak">Brak</option>
                </select>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Średni miesięczny dochód netto z&nbsp;ostatnich 3 miesięcy
                  wynosi:
                </label>
                <input
                  name="averageMonthlyIncomeAdditional1"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12 form-text">
                <label>Dochód dodatkowy nr 2</label>
                <select name="additionalIncomeSource2">
                  <option selected value="Nie wybrano">
                    Wybierz
                  </option>
                  <option value="Umowa o pracę">Umowa o&nbsp;pracę</option>
                  <option value="Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne">
                    Emerytura/Zasiłek przedemerytalny/Świadczenie emerytalne
                  </option>
                  <option value="Renta inwalidzka/Renta po zmarłym współmałżonku/Renta resortowa">
                    Renta inwalidzka/Renta po zmarłym współmałżonku/Renta
                    resortowa
                  </option>
                  <option value="Renta wyrównawcza">Renta wyrównawcza</option>
                  <option value="Kontrakt (np. menedżerski, wojskowy)">
                    Kontrakt (np. menedżerski, wojskowy)
                  </option>
                  <option value="Kontrakt – marynarze">
                    Kontrakt – marynarze
                  </option>
                  <option value="Prowadzenie gospodarstwa rolnego">
                    Prowadzenie gospodarstwa rolnego
                  </option>
                  <option value="Wolne zawody (lekarze wszystkich specjalności, prawnicy, biegli rewidenci, architekci, rzecznicy patentowi, tłumacze przysięgli, doradcy podatkowi, doradcy inwestycyjni, rzeczoznawcy majątkowi, księgowi)">
                    Wolne zawody (lekarze wszystkich specjalności, prawnicy,
                    biegli rewidenci, architekci, rzecznicy patentowi, tłumacze
                    przysięgli, doradcy podatkowi, doradcy inwestycyjni,
                    rzeczoznawcy majątkowi, księgowi)
                  </option>
                  <option value="Umowa najmu lokalu">Umowa najmu lokalu</option>
                  <option value="Umowa cywilno-prawna (umowa zlecenie, umowa o dzieło)">
                    Umowa cywilno-prawna (umowa zlecenie, umowa o&nbsp;dzieło)
                  </option>
                  <option value="Dochód z tytułu dywidendy i udziału w zysku w Sp. z o.o. lub akcyjnej">
                    Dochód z&nbsp;tytułu dywidendy i&nbsp;udziału w&nbsp;zysku
                    w&nbsp;Sp. z&nbsp;o.o. lub akcyjnej
                  </option>
                  <option value="Radni">Radni</option>
                  <option value="Posłowie">Posłowie</option>
                  <option value="Dochody zagraniczne">
                    Dochody zagraniczne
                  </option>
                  <option value="Świadczenie w ramach programu Rodzina 500 plus">
                    Świadczenie w&nbsp;ramach programu Rodzina 500 plus
                  </option>
                  <option value="Zasiłek macierzyński">
                    Zasiłek macierzyński
                  </option>
                  <option value="Alimenty">Alimenty</option>
                  <option value="Brak">Brak</option>
                </select>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Średni miesięczny dochód netto z&nbsp;ostatnich 3 miesięcy
                  wynosi:
                </label>
                <input
                  name="averageMonthlyIncomeAdditional2"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns large-12 medium-12 form-text">
              <label>Dochód z&nbsp;działalności gospodarczej</label>
              <select name="businessIncomeType">
                <option selected value="Nie wybrano">
                  Wybierz
                </option>
                <option value="Pełna księgowość (Bilans oraz Rachunek Zysków i Strat)">
                  Pełna księgowość (Bilans oraz Rachunek Zysków i&nbsp;Strat)
                </option>
                <option value="Książka Przychodów i&nbsp;Rozchodów (KPiR)">
                  Książka Przychodów i&nbsp;Rozchodów (KPiR)
                </option>
                <option value="Ryczałt">Ryczałt</option>
                <option value="Karta podatkowa">Karta podatkowa</option>
                <option value="Dochód z działalności gospodarczej na podstawie pełnej księgowości">
                  Dochód z&nbsp;działalności gospodarczej na podstawie pełnej
                  księgowości
                </option>
              </select>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Nazwa podmiotu</label>
                <input name="entityName" maxLength="100" type="text"></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Branża</label>
                <input
                  name="businessSector"
                  minlength="1"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Przychód i&nbsp;dochód (brutto) za ostatni rok obrachunkowy wg
                  stanu na dzień:
                </label>
                <input
                  name="lastYearIncomeDate"
                  className="datepicker hasDatepicker"
                  maxLength="100"
                  type="date"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Przychód i&nbsp;dochód (brutto) za bieżący okres (narastająco)
                  wg stanu na dzień:
                </label>
                <input
                  name="currentPeriodIncomeDate"
                  className="datepicker hasDatepicker"
                  maxLength="100"
                  type="date"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Wysokość przychodu za ostatni rok obrachunkowy:</label>
                <input
                  name="lastYearRevenueAmount"
                  maxLength="100"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Wysokość przychodu za bieżący okres (narastająco):
                </label>
                <input
                  name="currentPeriodRevenueAmount"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Wysokość dochodu brutto za ostatni rok obrachunkowy:
                </label>
                <input
                  name="lastYearGrossIncomeAmount"
                  maxLength="100"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Wysokość dochodu brutto za bieżący okres:</label>
                <input
                  name="currentPeriodGrossIncomeAmount"
                  maxLength="100"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="form-checkboxes">
              <div className="columns large-12">
                <ul type="checkbox" className="no-bullet checkbox">
                  <li>
                    <label className="checkbox-bold">
                      <input
                        name="noIncome"
                        type="checkbox"
                        value="Zaznaczono checkbox"
                        onChange={handleCheckboxChange}
                      ></input>
                      <span>Brak dochodu</span>
                    </label>
                    <input type="hidden" name="noIncome" value=""></input>
                  </li>
                </ul>
              </div>
            </div>

            <div className="columns">
              <p className="help-text">
                <a
                  className="gold"
                  href="https://www.getinbank.pl/dokumenty/restrukturyzacja-zestawienie-dokumentow-potwierdzajacych-dochod.pdf"
                  target="_blank"
                >
                  Sprawdź listę dokumentów, niezbędnych do potwierdzenia
                  wskazanego dochodu
                </a>
              </p>
            </div>

            <div className="row ">
              <legend>
                <h3 className="additionalMargin">Posiadany majątek</h3>
              </legend>
              <div className="columns">
                <p className="bold">Nieruchomości</p>
                <p className="help-text">
                  Przy ilości większej niż 1 wskaż kolejno wszystkie według
                  schematu "1. ... ;2. ...;3. ...; itd."
                </p>
              </div>

              <div className="columns large-12 medium-12">
                <label>
                  Opis (rodzaj, położenie, powierzchnia, nr Księgi Wieczystej)
                </label>
                <input
                  name="propertyDescription"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Szacunkowa wartość w&nbsp;PLN</label>
                <input
                  name="estimatedValuePLN"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Obciążenia w&nbsp;PLN</label>
                <input
                  name="liabilitiesPLN"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Udział we własności/inni współwłaściciele</label>
                <input
                  name="ownershipShare"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">Ruchomości</p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="columns large-12 medium-12">
              <label>
                Dokładny opis (cechy pozwalające na jednoznaczną identyfikację
                przedmiotów np. rodzaj, marka, typ, model)
              </label>
              <input
                name="detailedDescription"
                maxLength="100"
                placeholder="Wpisz"
                type="text"
              ></input>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Rok produkcji</label>
                <input
                  name="yearOfProduction"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Szacunkowa wartość w&nbsp;PLN</label>
                <input
                  name="movableEstimatedValuePLN"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Obciążenia</label>
                <input
                  name="movableLiabilities"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Udział we własności/inni współwłaściciele</label>
                <input
                  name="movableOwnershipShare"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">
                Środki pieniężne i&nbsp;inne produkty inwestycyjne
              </p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Środki pieniężne na rachunkach bankowych i&nbsp;lokatach
                  terminowych (dokładny opis, ilość, wartość nominalna,
                  obciążenia)
                </label>
                <input
                  name="bankAccountsDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Fundusze inwestycyjne (dokładny opis, ilość, wartość
                  nominalna, obciążenia)
                </label>
                <input
                  name="investmentFundsDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Akcje (dokładny opis, ilość, wartość nominalna, obciążenia)
                </label>
                <input
                  name="stocksDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Obligacje (dokładny opis, ilość, wartość nominalna,
                  obciążenia)
                </label>
                <input
                  name="bondsDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Inne (dokładny opis, ilość, wartość nominalna, obciążenia)
                </label>
                <input
                  name="otherInvestmentsDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">Posiadane zobowiązania</h3>
            </legend>
            <div className="columns">
              <p className="bold">
                Zobowiązania z&nbsp;tytułu zaciągniętych kredytów
                i&nbsp;pożyczek
              </p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kredytodawca/ Pożyczkodawca</label>
                <input
                  name="creditorDetails"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Kwota do spłaty (tys. PLN)</label>
                <input
                  name="outstandingLoanAmount"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Waluta zobowiązania</label>
                <input
                  name="loanCurrency"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Miesięczna wysokość raty (z podziałem na kapitał
                  i&nbsp;odsetki)
                </label>
                <input
                  name="monthlyRepaymentAmount"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Zabezpieczenia</label>
                <input
                  name="collaterals"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Ostateczny termin spłaty</label>
                <input
                  name="finalRepaymentDate"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota zobowiązań przeterminowanych</label>
                <input
                  name="overdueLiabilitiesAmount"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">
                Zobowiązania z&nbsp;tytułu poręczeń i/lub gwarancji
              </p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota poręczenia/gwarancji</label>
                <input
                  name="guaranteeAmount"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Waluta zobowiązania</label>
                <input
                  name="liabilityCurrency"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Dłużnik</label>
                <input
                  name="debtor"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Termin ważności</label>
                <input
                  name="validityTerm"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota zobowiązań przeterminowanych</label>
                <input
                  name="overdueLiabilitiesAmount2"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">
                Zobowiązania z&nbsp;tytułu zawartych umów leasingowych lub
                faktoringowych
              </p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Tytuł zobowiązania (wierzyciele)</label>
                <input
                  name="obligationTitle"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Kwota do spłaty</label>
                <input
                  name="repaymentAmount2"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Waluta zobowiązania</label>
                <input
                  name="obligationCurrency"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Miesięczna wysokość raty</label>
                <input
                  name="monthlyInstallmentAmount"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Przedmiot (w przypadku leasingu)</label>
                <input
                  name="leasingSubject"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>Termin zakończenia</label>
                <input
                  name="terminationDate"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Kwota zobowiązań przeterminowanych</label>
                <input
                  name="overdueAmount"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="columns">
              <p className="bold">Zobowiązania pozostałe</p>
              <p className="help-text">
                Przy ilości większej niż 1 wskaż kolejno wszystkie według
                schematu "1. ... ;2. ...;3. ...; itd."
              </p>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Z&nbsp;tytułu podatków oraz innych opłat, do których stosuje
                  się przepisy ustawy z&nbsp;dnia 29 sierpnia 1997 r. Ordynacja
                  Podatkowa (Dz.U.2012.749 wraz z&nbsp;późniejszymi zmianami),
                  w&nbsp;tym przeterminowane
                </label>
                <input
                  name="taxLiabilities"
                  maxLength="100"
                  placeholder="Wpisz obie wartości"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Z&nbsp;tytułu zobowiązań wobec ZUS, w&nbsp;tym przeterminowane
                </label>
                <input
                  name="socialSecurityLiabilities"
                  maxLength="100"
                  placeholder="Wpisz obie wartości"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>
                  Orzeczone wyrokiem lub nakazem sądowym, wynikających
                  z&nbsp;decyzji administracyjnych lub ustalonych w&nbsp;ugodzie
                  sądowej, bądź ugodzie bankowej
                </label>
                <input
                  name="courtOrderedLiabilities"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>

              <div className="columns large-6 medium-12">
                <label>
                  Będących przedmiotem egzekucji sądowej lub administracyjnej
                </label>
                <input
                  name="judicialExecutionLiabilities"
                  maxLength="100"
                  placeholder="Wpisz"
                  type="text"
                ></input>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns large-6 medium-12">
                <label>Z&nbsp;innych tytułów</label>
                <input
                  name="otherLiabilities"
                  maxLength="100"
                  placeholder="Wpisz sumę"
                  type="text"
                ></input>
              </div>
            </div>
          </div>

          <div className="row ">
            <legend>
              <h3 className="additionalMargin">Oświadczenia</h3>
            </legend>
            <div className="wrapper"></div>
            <div className="columns large-6 medium-12">
              <label>
                Oświadczam, że nie posiadam / posiadam zaległości wobec ZUS
                w&nbsp;kwocie:
              </label>
              <input
                name="socialSecurityArrearsDeclaration"
                maxLength="100"
                placeholder="Wpisz wartość w PLN"
                type="text"
              ></input>
            </div>

            <div className="columns large-6 medium-12">
              <label>
                Oświadczam, że nie posiadam / posiadam zaległości wobec US
                w&nbsp;kwocie:
              </label>
              <input
                name="taxArrearsDeclaration"
                maxLength="100"
                placeholder="Wpisz wartość w PLN"
                type="text"
              ></input>
            </div>
          </div>

          <div className="wrapper">
            <div className="columns large-6 medium-12">
              <label>
                Oświadczam, że przeciwko mnie nie jest / jest prowadzone
                postępowanie egzekucyjne: sygn. akt KM , kwota dochodzonej
                wierzytelności
              </label>
              <input
                name="executionProceedingDeclaration"
                maxLength="100"
                placeholder="Wpisz TAK/NIE i uzupełnij sygnaturę oraz kwotę"
                type="text"
              ></input>
            </div>

            <div className="columns large-6 medium-12">
              <label>
                Oświadczam, że jestem/ nie jestem podmiotem postępowania
                upadłościowego. Jeśli tak, proszę o&nbsp;wskazanie tytułu
              </label>
              <input
                name="bankruptcyProceedingDeclaration"
                maxLength="100"
                placeholder="Wpisz"
                type="text"
              ></input>
            </div>
          </div>

          <div className="wrapper">
            <div className="columns large-6 medium-12">
              <label>
                Oświadczam, że jestem świadomy, iż złożenie wniosku
                o&nbsp;restrukturyzację, nie zwalnia mnie z&nbsp;obowiązku
                regulowania rat zgodnych z&nbsp;aktualnym harmonogramem
              </label>
              <input
                name="restructuringAwarenessDeclaration"
                maxLength="100"
                placeholder="Wpisz"
                type="text"
              ></input>
            </div>
          </div>

          <div className="row ">
            <div className="columns">
              <p style={{ marginBottom: "6rem" }} className="help-text">
                Bank, po otrzymaniu wniosku, zweryfikuje go i&nbsp;może
                skontaktować się z&nbsp;Tobą w&nbsp;celu poinformowania
                o&nbsp;potrzebie dostarczenia dodatkowych dokumentów i/lub
                informacji.
              </p>
            </div>
          </div>

          <button type="submit" className="btnGold">
            Wyślij wniosek
          </button>
          <p
            style={{
              fontSize: "1.5rem",
              color: "red",
              marginTop: "1.5rem",
            }}
          >
            {errors.form}
          </p>
        </form>
      </div>
    </div>
  );
}

export default RestructuringFormExt;
