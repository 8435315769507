import React from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const alertNews003 = (
    <li className="alertCard three">
      <div className="cardText">
        <p>
          Syndyk masy upadłości Getin Noble Bank&nbsp;S.A. w&nbsp;upadłości
          informuje, że wpłaty zryczałtowanych kosztów postępowania
          upadłościowego wynikających z&nbsp;dokonania zgłoszenia wierzytelności
          po terminie, o&nbsp;których mowa w&nbsp;art. 235 ust. 1&nbsp;ustawy
          Prawo upadłościowe należy dokonywać na rachunek bankowy
          o&nbsp;numerze: 72 8436 0003 0000 0026 7436 0007.
        </p>
        <p>
          <span className="bold underline">
            Celem usprawnienia procesu podaje się dane do wykorzystania przy
            wykonywaniu przelewu:
          </span>
        </p>
        <p>
          <span className="bold">ODBIORCA:</span> MARCIN KUBICZEK SYNDYK MASY
          UPADŁOŚCI GETIN NOBLE BANK&nbsp;S.A. W&nbsp;UPADŁOŚCI
        </p>
        <p>
          <span className="bold">NUMER KONTA ODBIORCY:</span> 72 8436 0003 0000
          0026 7436 0007
        </p>
        <p>
          <span className="bold">TYTUŁ:</span> ZRYCZAŁTOWANY KOSZT POSTĘPOWANIA
          WA1M/GUp-Zw/44/2023 [jeżeli przelewu dokonuje pełnomocnik wierzyciela
          w&nbsp;tym miejscu należy również wskazać dane wierzyciela]
        </p>
        <p>
          <span className="bold">KWOTA:</span> 1 010,49 PLN
        </p>
      </div>
      <p className="cardDate">Opublikowano 22.08.2023&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews003} />
    </>
  );
}

export default AlertCardBankruptcy;
