import React, { useState } from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const alertNews008 = (
    <li className="alertCard eight">
      <div className="cardText">
        <h3>Syndyk Getin Noble Banku sprzedaje Noble Securities</h3>
        <br></br>
        <p className="lead">
          <span className="bold">
            Syndyk masy upadłości Getin Noble Bank&nbsp;S.A. wyłonił nabywcę
            domu maklerskiego Noble Securities&nbsp;S.A. Pieniądze ze sprzedaży
            trafią do wierzycieli upadłego banku. Nowym właścicielem Noble
            Securities zostanie Skarbiec Holding&nbsp;S.A. Zgodę na transakcję
            musi wyrazić jeszcze m.in. Sędzia-Komisarz nadzorujący upadłość GNB,
            UOKiK oraz Komisja Nadzoru Finansowego.
          </span>
        </p>

        {!isExpanded && (
          <a
            className="readMore"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleToggle();
            }}
          >
            Czytaj więcej
          </a>
        )}
        {isExpanded && (
          <>
            <br></br>
            <p>
              Proces wyłaniania nabywcy Noble Securities rozpoczął się
              w&nbsp;lutym tego roku. Syndyk masy upadłości Getin Noble
              Bank&nbsp;S.A. Marcin Kubiczek zapowiadał sfinalizowanie sprzedaży
              domu maklerskiego w&nbsp;IV&nbsp;kwartale tego roku. Noble
              Securities cieszył się dużym zainteresowaniem rynku, jednak
              ostatecznie syndyk zdecydował o&nbsp;wybraniu oferty złożonej
              przez Skarbiec Holding. Kluczowym kryterium była najkorzystniejsza
              cena oraz czas zamknięcia transakcji.
            </p>
            <br></br>
            <p>
              <span className="bold">Noble Securities Dom Maklerski</span>
            </p>
            <br></br>
            <p>
              Noble Securities&nbsp;S.A. działa na polskim rynku kapitałowym od
              1994&nbsp;r. W&nbsp;2008&nbsp;r. stał się członkiem grupy Getin
              Holding&nbsp;S.A., a&nbsp;rok później zmienił nazwę spółki
              z&nbsp;Dom Maklerski IKB-Polonia&nbsp;S.A. na Noble
              Securities&nbsp;S.A.
            </p>
            <p>
              Podstawowa działalność spółki jest związana z&nbsp;rynkiem
              papierów wartościowych i&nbsp;towarowej giełdy energii, w&nbsp;tym
              m.in. obrotem akcjami i&nbsp;obligacjami na warszawskiej giełdzie.
              W&nbsp;ramach działalności maklerskiej spółka oferuje również
              rachunki IKE i&nbsp;IKZE, doradztwo inwestycyjne oraz przygotowuje
              analizy i&nbsp;rekomendacje. Oferta Noble Securities skierowana
              jest do klientów indywidualnych, małych i&nbsp;średnich
              przedsiębiorców oraz dużych korporacji, oczekujących sprawnej
              i&nbsp;kompleksowej obsługi. Firma działa również na Towarowej
              Giełdzie Energii oraz na rynku Catalyst (obligacje korporacyjne).
              Prowadzi ponadto usługi rejestru akcjonariuszy dla spółek
              niepublicznych.
            </p>
            <a
              className="readMore"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleToggle();
              }}
            >
              Zwiń
            </a>
          </>
        )}
      </div>
      <p className="cardDate">Opublikowano 29.10.2024&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews008} />
    </>
  );
}

export default AlertCardBankruptcy;
