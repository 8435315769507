import React from "react";
import ScrollReveal from "scrollreveal";

export const useScrollReveal = (options = {}) => {
  React.useEffect(() => {
    const defaultOptions = {
      duration: 700,
    };
    const revealOptions = {
      ...defaultOptions,
      ...options,
    };

    const elements = document.querySelectorAll(revealOptions.selector);

    elements.forEach((element) => {
      ScrollReveal().reveal(element, revealOptions);
    });

    // Cleanup
    return () => {
      ScrollReveal().destroy();
    };
  }, [options]);
};
