import React from "react";
import "../css/Help.css";
import { Link } from "react-router-dom";
import { useScrollReveal } from "../hooks/useScrollReveal";

function Help() {
  useScrollReveal({ selector: "#help" });
  useScrollReveal({
    selector: ".container",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="help">
      <section>
        <div className="container">
          <header>
            <h1 className="heading">Pomoc</h1>
          </header>
          <div className="wrapper serviceCards">
            <div className="card rounded shadow">
              <h3>Pytania i&nbsp;odpowiedzi do aneksów BMR</h3>
              <Link
                to="/informacje-o-wskaznikach-referencyjnych/pytania-i-odpowiedzi-do-aneksow-bmr"
                className="gold"
              >
                Więcej
              </Link>
            </div>
            <div className="card rounded shadow">
              <h3>Szukasz dokumentów?</h3>
              <p>
                W&nbsp;tym miejscu znajdziesz Regulaminy, Tabele Opłat
                i&nbsp;Prowizji i&nbsp;inne pomocne dokumenty.
              </p>{" "}
              <Link to="/centrum-dokumentow">
                <button className="btnOutline">Sprawdź</button>{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Help;
