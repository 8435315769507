import React from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const alertNews002 = (
    <li className="alertCard two">
      <div className="cardText">
        <p>
          Szanowni Państwo,
          <br></br>
          <br></br>
          syndyk masy upadłości Getin Noble Bank&nbsp;S.A. informuje, że do
          godziny 21:00 dnia 21.08.2023&nbsp;r., tj. na&nbsp;3 godziny przed
          zakończeniem 30&nbsp;&nbsp;dniowego [instrukcyjnego terminu] na
          zgłoszenie wierzytelności do akt zgłoszeń wierzytelności wpłynęło
          ponad 30&nbsp;100 dokumentów, co spowodowało założenie przez Rejestr
          25&nbsp;860 teczek wierzytelności, dziękujemy wierzycielom, jak
          również pełnomocnikom wierzycieli za dokonanie zgłoszeń
          wierzytelności, jednocześnie syndyk masy upadłości wyraża przekonanie,
          że pomimo tego, iż Rejestr działał momentami wolniej niż zazwyczaj to
          spełnił oczekiwania użytkowników.<br></br>
          <br></br>
          Marcin Kubiczek - syndyk masy upadłości<br></br>
          Kwalifikowany doradca restrukturyzacyjny, licencja nr&nbsp;244
        </p>
      </div>
      <p className="cardDate">Opublikowano 21.08.2023&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews002} />
    </>
  );
}

export default AlertCardBankruptcy;
