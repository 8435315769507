import React from "react";
import { useScrollReveal } from "../hooks/useScrollReveal";
import ScrollReveal from "scrollreveal";

function HomeAlert() {
  useScrollReveal("#HomeAlert");

  React.useEffect(() => {
    const reveal = {
      duration: 800,
      origin: "bottom",
      distance: "60px",
    };
    ScrollReveal().reveal(".whiteBgc", {
      ...reveal,
    });
  }, []);

  return (
    <main id="HomeAlert">
      <section>
        <div className="container">
          <header className="sectionHeader">
            <h1 className="heading">
              Komunikat dla klientów Getin Noble Bank S.A. w&nbsp;upadłości
            </h1>
          </header>
          <div className="whiteBgc">
            <div className="text">
              <h3>Dzień dobry,</h3>
              <p>
                osiem największych banków komercyjnych działających w Polsce
                wraz z&nbsp;Bankowym Funduszem Gwarancyjnym stworzyło VeloBank
                S.A. Przejął on na mocy decyzji Bankowego Funduszu Gwarancyjnego
                działalność Getin Noble Bank S.A. z&nbsp;wyłączeniem m.in.
                kredytów hipotecznych indeksowanych lub denominowanych do walut
                obcych (CHF, EUR, USD, JPY).
              </p>
            </div>

            <h2>
              Obsługa Twoich produktów Getin Noble Bank S.A. w&nbsp;upadłości
            </h2>
            <div className="text">
              <h3>Co się zmienia od 11.09.2023&nbsp;r.:</h3>
              <ul className="dottedList">
                <li>
                  Informacje o umowie kredytu / pożyczki GNB w upadłości
                  dostępne są w Platformie Informacyjnej pod linkiem{" "}
                  <a className="gold" href="https://secure.gnbbank.pl/">
                    https://secure.gnbbank.pl/
                  </a>
                </li>
                <li>
                  Dane do logowania – dostępne są po zalogowaniu w
                  dotychczasowej bankowości{" "}
                  <a className="gold" href="https://secure.velobank.pl">
                    https://secure.velobank.pl
                  </a>
                </li>
                <li className="bold">
                  Informacja o spłatach, które zostały zarejestrowane przed 11
                  września 2023 r. jest załączona w bankowości elektronicznej
                  VeloBanku w formie pdf
                </li>
              </ul>
            </div>
            {/* <div className="text">
              <h3>Co się nie zmienia:</h3>
              <ul className="dottedList">
                <li>numery rachunków bankowych</li>
                <li>
                  adresy placówek – VeloBank będzie realizował obsługę Twoich
                  produktów Getin Noble Bank SA w upadłości
                </li>
                <li>zlecone transakcje, które realizujemy na bieżąco</li>
                <li>
                  nr infolinii - +48 799 655 999 (opłata za połączenie wg taryfy
                  operatora)
                </li>
              </ul>
            </div> */}

            <div className="text">
              <h3>Jako klient nic nie musisz robić</h3>
              <p>Wszystko zrobimy za Ciebie.</p>
              <br></br>
              <p>
                W przypadku pytań zapraszamy do mejlowego lub telefonicznego
                kontaktu.
              </p>
            </div>
            <div className="text">
              <p>Pozdrawiamy,</p>
              <p>Getin Noble Bank S.A. w upadłości</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default HomeAlert;
